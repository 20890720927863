import UserEditForm from './Form';
import { Col, Row, Alert } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { useForm } from "react-hook-form";
import useFetch from "../../framework/hooks/useFetch";

const UserAdd = () => {

    const heading = "Add new user";
    const { register, handleSubmit, setValue, getValues} = useForm();    

    const API_URL = process.env.REACT_APP_API_URL;
    const { data, isPending, isError } = useFetch(API_URL + '/v1/user/data/addnew', 'GET', true);

    setValue("FName", "");
    setValue("SName", "");
    setValue("Email", "");
    setValue("secEmail", "");
    setValue("oName1", "");
    setValue("oName2", "");
    setValue("dblp_url", "");

    setValue("uRole", 2);
    setValue("UType", 0);
    setValue("UTypeName", 'Front-end');
    setValue("pwd", "");
    setValue("confirm_pwd", "");

    setValue("dblpId", "");
    setValue("allTypes", data.allTypes);
    setValue("pwdSet", data.pwdSet);
    
    const showSave = true;
    const recStatus = 1;
    const lockStatus = 0;
    
    return ( 
        <>
            <ModuleBreadcrumb links={[{ label:"Users", linkto: Routes.Users.path } , { label: heading } ]}  />
            <h4>{heading}</h4>  
            {isPending && <div className="alert alert-info" role="alert">Fetching data. Please wait..</div>}                    
            {isError && <Alert variant="danger">{isError}</Alert>} 
            {data.allTypes &&       
            <Row>
                <Col xs={12}>
                    <UserEditForm  setValue={setValue} getValues={getValues} register={register} handleSubmit={handleSubmit}  showSave={showSave} recStatus={recStatus} lockStatus={lockStatus}/>
                </Col>
            </Row>   
            }    
        
        </> 
    );
}
 
export default UserAdd;