import React,{useState} from "react";
import { Col, Row, Form, Alert} from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import ModuleEditScreenButtons  from "../../project/components/ModuleEditScreenButtons";
import { useParams, useHistory  } from "react-router-dom";
import Select from 'react-select'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const InstitutionEditForm = (props) => {
    
    const { id } = useParams();
    const register = props.register;
    const handleSubmit = props.handleSubmit;
    const setValue = props.setValue;
    const errors = props.errors;
    const getValues = props.getValues;
    
    const [successMessage,setSuccessMessage] = useState('');
    const [errMessage,setErrorMessage] = useState('');
    const [startSave,setStartSave] = useState(false);
    const [showSave,SetShowSave] = useState(props.showSave);
    const [linkedToInstitute,SetLinkToInst] = useState(0);
    const [isLoadingLinkToOptions, setIsLoadingLinkToOptions] = useState(true);
    
    const [status, setStatus] = useState(props.recStatus);
    
    const API_URL = process.env.REACT_APP_API_URL;
    const history = useHistory();

    const ownerInfo = { 
        'createdBy' :  getValues('createdBy'),
        'createdAt' : getValues('createdAt'),
        'updatedBy' :  getValues('updatedBy'), 
        'updatedAt' : getValues('updatedAt') 
    }; 


    const selectInstitutionOptions = [];
    fetch(API_URL + '/v1/institution/data/list/InstitutionDrplist', {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem("token"),
        }
    }).then(response => response.json())
    .then(jsondata => {

        //console.log("response",jsondata);
            Object.keys(jsondata).forEach(key => {
                selectInstitutionOptions.push({
                    value: key,
                    label: jsondata[key]
                });
            });
            setIsLoadingLinkToOptions(false);
        }).catch((err) => {
            console.log("Dropdown fetching error: " + err);
    });


    const onSubmit = postedData => {
        
        setStartSave(true);
        
        let formData = new FormData();
        if(id !== undefined){
            formData.append("GID", id);
        }
        formData.append("IName", postedData.IName);
        formData.append("Status", status);
        formData.append("LinkTo", linkedToInstitute);

        fetch(API_URL + '/v1/institution/data/save', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
            body: formData
        })
        .then(response => response.json())
        .then(jsondata => {
            //console.log("success");
            //console.log(jsondata);
            if ((jsondata.GID) && (jsondata.message === 'UPDATED')){
                setSuccessMessage("Record updated successfully.");
                setTimeout(function(){                    
                    history.push(Routes.Institution.path);
                },1000); 
            }else if ((jsondata.GID) && (jsondata.message === 'CREATED')){
                SetShowSave(false);
                setSuccessMessage("Record created successfully.");
                setTimeout(function(){                    
                    history.push(Routes.Institution.editpath.replace(':id',jsondata.GID));
                },1000);                
            }else if (jsondata.errors){
                setErrorMessage(jsondata.message + "\r\n" + JSON.stringify(jsondata.errors));                                      
            }                   
            setStartSave(false); 
        }).catch((err) => {
            console.log("Login error: " + err);
            setErrorMessage("Record could not be saved.");
            setStartSave(false); 
        });
        

               
    }
    return (

            <>    
            {successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}            
            {errMessage && <Alert variant="danger">{errMessage}</Alert>}      
            {Number(props.recStatus)===3 && <Alert variant="danger">This institution is in unverified state. If you think this name already 
            exists in our system you have below two options:<br/>Option 1. Select an existing institution from "Link to an existing institution" dropdown and click "Save". Once you link the unverified name to a verified one, the unverified entry gets deleted and any user-institution relations will get updated with the verified entry name.<br/>Option 2. Select "Active" status and click on "Save" to add this institution to the approved list.</Alert>            
            }
            <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-5">
                <Col sm={10} >
                    <Form.Group id="IName">
                        <Form.Label className="required">Institution name</Form.Label>
                        <p className="mb-0"><i>Please list all possible names by which this institution might be referred to in a comma-separated list. E.g., “Advanced Micro Devices,AMD”</i></p>
                        <Form.Control name="IName"  type="text" placeholder=" " {...register('IName',{ required: true})}  className={errors?.IName?.type === "required" && "is-invalid"} />  
                        {errors?.IName?.type === "required" && <p className="is-invalid" >Please enter institution name.</p>}   
                    </Form.Group>
                </Col>
            </Row>
            
            <Row >
                {Number(props.recStatus)===3 && 
                    <Col sm={10} className="mb-5" >
                        <Form.Group id="LinkToExisting">
                            <Form.Label>Link to an existing institution</Form.Label>     
                            <p className="mb-0 text-danger"><i>Once you link the unverified name to a verified one, the unverified entry gets deleted and any user-institution relations will get updated with the verified entry name.</i></p> 
                            <Select 
                                name="linkIoInstitutionId" 
                                options={selectInstitutionOptions}                                
                                isClearable={true}       
                                isLoading={isLoadingLinkToOptions}                      
                                onChange={e => {  
                                    console.log(e);                                   
                                    if (e){
                                        SetLinkToInst(e.value);
                                        setStatus(1);
                                    }else{
                                        // if you have cleared the selection and the inital state was unverified we will switch back to unverified state
                                        SetLinkToInst(0);
                                        if (props.recStatus === 3){
                                            setStatus(3);
                                        }                                        
                                    }                                      
                                    }
                                }     
                            />                                                
                            
                        </Form.Group>
                    </Col>
                }   
            </Row>   
            
            <Row>
				<Col>
                    <Form.Group>
						<Form.Label className="form-check-label required"  >
							Status
						</Form.Label>
						<div className="form-check mt-0">
							<input className="form-check-input" type="radio" name="status"  id="gridRadios1" value="1"
                            checked={Number(status)===1?true:false}
                            onChange={(e) => {setStatus(e.target.value);setValue("status",1);}}
                            required    
							></input>
							<Form.Label className="form-check-label"   >
								Active
							</Form.Label>
						</div>
						<div className="form-check">
						   <input className="form-check-input" type="radio" name="status" id="gridRadios2" value="0" required 
                           checked={Number(status)===0?true:false}
                           onChange={(e) => {setStatus(e.target.value);setValue("status", 0)}}
						   ></input>
						    <Form.Label className="form-check-label" >
							    Inactive
						    </Form.Label>
						</div>
                        <div className="form-check">
						   <input className="form-check-input" type="radio" name="status" id="gridRadios3" value="3" required 
                           checked={Number(status)===3?true:false}
                           onChange={(e) => {setStatus(e.target.value);setValue("status", 0)}}
						   ></input>
						    <Form.Label className="form-check-label" >
							    Unverified
						    </Form.Label>
						</div>
                    </Form.Group>
                </Col>
			</Row>

            <Row className="mt-1" >
                    <Col className="callout callout-danger text-danger">
                    <FontAwesomeIcon icon={faInfoCircle} className="animate-left-3 me-3 ms-2 text-danger" />When institution status is changed from "Unverified" to "Active", all the pending employment relations linked to this institution will be changed from status "Pending" to "Validated".
                    </Col>
                </Row>

            {
                (id !== undefined) ?
                    (Number(props.recStatus)===3)?
                        <ModuleEditScreenButtons cancelPath={Routes.Institution.path} showSave={showSave} ownerInfo={ownerInfo} /> 
                    :
                        <ModuleEditScreenButtons cancelPath={Routes.Institution.path} addnewPath={Routes.Institution.addpath} showSave={showSave} startSave={startSave} ownerInfo={ownerInfo} /> 
                : 
                    <ModuleEditScreenButtons cancelPath={Routes.Institution.path} showSave={props.showSave} startSave={startSave} /> 
            }
            </Form>
            </>
    );
};

export default InstitutionEditForm;