import { Col, Row, Card, Button } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus,faTimes,faSave,faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import ActivityInfo  from "./ActivityInfo";
import ConfirmDeleteModal from '../../project/components/ConfirmDeleteModal';
import React, { useState } from "react";

const ModuleEditScreenButtons = (props) => {

    const history       = useHistory();
    const startSave     = props.startSave;
    const id            = props.id;
    const delRecTile    = props.delRecTile;
    const delApiPath    = props.delApiPath;
    let [delRecId, setDelRecId] = useState(0);

    let [modalShow, setModalShow] = useState(false);

    const deleteRec = (id) => {
        setDelRecId(id);
        setModalShow(true);       
    }

    const handleModelClose = () => {
        setModalShow(false);
    }

    const handleOnDeleteSubmitted = () => {
        const API_URL = process.env.REACT_APP_API_URL;
        // console.log("handleOnDeleteSubmitted", delApiPath , delRecId);
        fetch(API_URL + delApiPath + delRecId, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
        }).then(response => response.json())
        .then(jsondata => {      
            if (jsondata.GID > 0){
                handleModelClose(false);                  
                window.location.reload(); 
                history.goBack(-2);  
            }  else{
                return false;
            }   
             
        }).catch((err) => {
            console.log("Login error: " + err);
        });

        return false;
    }

    return (
        <> 
            <ConfirmDeleteModal modalShow={modalShow} handleModelClose={() => { setModalShow(false);}} moduleName="relation" delRecTitle={delRecTile} handleOnDeleteSubmitted={handleOnDeleteSubmitted} />
           <Card.Footer>
            <Row>
                <Col>
                    {props.ownerInfo && 
                    <ActivityInfo ownerInfo={props.ownerInfo} moduleOtherInfo={props.moduleOtherInfo} />
                    }                    
                </Col>
            </Row>
            <Row>   
                <Col>
                    <div className="mt-3 d-flex justify-content-end">
                        {props.showSave && <Button variant="primary" type="submit"  className="animate-hover" ><FontAwesomeIcon icon={faSave} className="animate-left-3 me-3 ms-2" />{startSave ? "Please wait..." : "Save"}</Button>}                        
                        {props.cancelPath &&                             
                            <Button as={Link} className="animate-hover btn btn-sm btn-secondary" to={props.cancelPath}>
                                <FontAwesomeIcon icon={faTimes} className="animate-left-3 me-3 ms-2" />
                                Cancel
                            </Button>
                        }
                       
                        {props.addnewPath && 
                            <Button as={Link} className="animate-hover btn btn-sm btn-addnew" to={props.addnewPath}>
                                <FontAwesomeIcon icon={faPlus} className="animate-left-3 me-3 ms-2" />
                                Add new
                            </Button>
                        }

                        {props.showDeleteBtn && id && (id > 0) && 
                             <Button className="animate-hover btn btn-delete btn-delete-small-rounded"  onClick={ () => deleteRec(id)}  >
                             <FontAwesomeIcon icon={faTrashAlt} className="animate-left-3 me-3 ms-2" /> {(props.delBtnLabel) ? props.delBtnLabel : `Delete`}
                             </Button>
                        }

                    </div>
                </Col>                    
            </Row>
            </Card.Footer>            
        </>
    );
}

export default ModuleEditScreenButtons;
