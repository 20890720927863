import React,{useState} from "react";
import { Col, Row, Form, Alert} from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import ModuleEditScreenButtons  from "../../project/components/ModuleEditScreenButtons";
import { useParams, useHistory  } from "react-router-dom";

const RelationTypeForm = (props) => {
    
    const { id } = useParams();
    const register = props.register;
    const handleSubmit = props.handleSubmit;
    const setValue = props.setValue;
    const getValues = props.getValues;

    const ownerInfo = { 
        'createdBy' :  getValues('createdBy'),
        'createdAt' : getValues('createdAt'),
        'updatedBy' :  getValues('updatedBy'), 
        'updatedAt' : getValues('updatedAt') 
    }; 

    //const errors = props.errors;
    
    const [successMessage,setSuccessMessage] = useState('');
    const [errMessage,setErrorMessage] = useState('');
    const [startSave,setStartSave] = useState(false);
    const [showSave,SetShowSave] = useState(props.showSave);

    const [status, setStatus] = useState(props.recStatus);

    const API_URL = process.env.REACT_APP_API_URL;
    const history = useHistory();

    //console.log('ShowStartDate: ',getValues('ShowStartDate'));
    // When you want to combine with your onChange, onBlur, you can achieve by the following:
    const ShowStartDate = register('ShowStartDate', { required: false });
    //setShowStartDateFields(getValues('ShowStartDate'));
    //console.log('ShowStartDate after: ',getValues('ShowStartDate'));
    const ShowEndDate = register('ShowEndDate', { required: false });
    const [showStartDateFields,setShowStartDateFields] = useState(getValues('ShowStartDate'));
    const [showEndDateFields,setShowEndDateFields] = useState(getValues('ShowEndDate'));

    const onSubmit = postedData => {
       
        console.log(postedData);
       
        let formData = new FormData();
        if(id !== undefined){
            formData.append("GID", id);
        }
        formData.append("RType", postedData.RType);
        formData.append("Desc", postedData.Desc);
        formData.append("Status", ((postedData.Status)? postedData.Status : status));
        formData.append("ShowEndDate", ((postedData.ShowEndDate) ? 1 : 0));
        formData.append("EDFormat", ((postedData.ShowEndDate)? postedData.EDFormat : 0));
        formData.append("EDLabel", ((postedData.ShowEndDate)? postedData.EDLabel : '' ));
        formData.append("EDMandatory", ((postedData.EDMandatory) ? ((postedData.ShowEndDate)? 1 : 0) : 0));        
        formData.append("ShowStartDate", ((postedData.ShowStartDate) ? 1 : 0));
        formData.append("SDFormat", ((postedData.ShowStartDate)? postedData.SDFormat : 0) );
        formData.append("SDLabel", ((postedData.ShowStartDate)? postedData.SDLabel : '') );
        formData.append("SDMandatory", ((postedData.SDMandatory) ? ((postedData.ShowStartDate)? 1 : 0) : 0));
        
        fetch(API_URL + '/v1/relationtype/data/save', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
            body: formData
        })
        .then(response => response.json())
        .then(jsondata => {
            console.log("success");
            console.log(jsondata);
            if ((jsondata.GID) && (jsondata.message === 'UPDATED')){
                setSuccessMessage("Record updated successfully.");
                history.push(Routes.RelationType.path);  
            }else if ((jsondata.GID) && (jsondata.message === 'CREATED')){
                SetShowSave(false);
                setSuccessMessage("Record created successfully.");
                setTimeout(function(){
                    history.push( Routes.RelationType.editpath.replace(":id",jsondata.GID));
                },1000);                
            }else if (jsondata.errors){
                setErrorMessage(jsondata.message + "\r\n" + JSON.stringify(jsondata.errors));                       
            }    
            setStartSave(false);                
        }).catch((err) => {
            setStartSave(false); 
            setErrorMessage("Record could not be saved.");
            console.log("Login error: " + err);
        });
    }

    return (
        <>
            {successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}            
            {errMessage && <Alert variant="danger">{errMessage}</Alert>}      
            <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>            
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor="RType" className="col-sm-12 col-form-label required" >Relation name</Form.Label>
                        <div className="col-sm-12">
                        <Form.Control type="text" name="RType" id="RType" {...register("RType")} required   />
                        </div>                        
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor="inputSName" className="col-sm-12 col-form-label required">Description</Form.Label>
                        <div className="col-sm-12">
                            <textarea className="form-control" name="Desc" id="Desc" {...register("Desc")}  style={{ height: '100px' }} required ></textarea>
                        </div>    
                                          
                    </Form.Group>
                </Col>
            </Row>

            <Row className="mt-5">   
                <Col  md={4} sm={12} className="mb-0">
                    <Form.Group>    
                        <fieldset className="row mb-0" >
                            
                            <div className="col">
                                <Form.Label className="col-form-label pt-0">Show start date</Form.Label>
                                
                                <input className="form-check-input" type="checkbox" name="ShowStartDate" 
                                    id="ShowStartDate"  
                                    onChange={(e) => {
                                        ShowStartDate.onChange(e); // method from hook form register
                                        //handleChange(e); // your method                                            
                                        if (e.target.checked){
                                            setShowStartDateFields(1);
                                            setValue("ShowStartDate", 1);
                                        }else{
                                            setShowStartDateFields(0);
                                            setValue("ShowStartDate", 0);
                                        }
                                    }}
                                    onBlur={ShowStartDate.onBlur}
                                    ref={ShowStartDate.ref} 
                                    style={{'marginLeft':'10px'}}

                                ></input>
                                
                            </div>
                        </fieldset >	
                    </Form.Group>
                </Col>
            </Row>

            {Number(showStartDateFields)===1 && 
                <Row className="mt-1">   
                    <Col  md={4} sm={12} className="mb-3">
                        <Form.Group>
                            <Form.Label  htmlFor="name-start-dt">Start date label</Form.Label>                  
                            <input type="text" className="form-control" name="SDLabel" data-form-field="Type"  id="SDLabel" {...register("SDLabel")} ></input>
                        </Form.Group>
                    </Col>
                    <Col  md={4} sm={12} className="mb-3">
                        <Form.Group>
                            <Form.Label htmlFor="name-start-dt-label">Start date format</Form.Label>
                            <Form.Select className="form-select" name="SDFormat" aria-label="start date format" {...register("SDFormat")} >
                                <option value="1" >Year</option>
                                <option value="2" >Year and Month</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col  md={4} sm={12} className="mb-3">
                        <Form.Group>
                            <Form.Label htmlFor="name-start-dt-label"></Form.Label>
                            <Form.Group>    
                                <fieldset className="row mb-3" >
                                    <div className="Col">
                                        <Form.Label className="col-form-label pt-0">Is start date mandatory?</Form.Label>
                                        <input className="form-check-input" type="checkbox" name="SDMandatory" id="SDMandatory" {...register('SDMandatory')} style={{'marginLeft':'10px'}} ></input>
                                    </div>
                                </fieldset >	
                            </Form.Group>
                        </Form.Group>
                    </Col>
                </Row>
            }

            <Row className="mt-5">   
                <Col  md={4} sm={12} className="mb-0">
                    <Form.Group>    
                        <fieldset className="row mb-0" >                            
                            <div className="col">
                            <Form.Label className="col-form-label pt-0">Show end date</Form.Label>
                                <input className="form-check-input" type="checkbox" name="ShowEndDate" 
                                    id="ShowEndDate"  
                                    onChange={(e) => {
                                        ShowEndDate.onChange(e); // method from hook form register
                                        //handleChange(e); // your method                                            
                                        if (e.target.checked){
                                            setShowEndDateFields(1);
                                            setValue("ShowEndDate", 1);
                                        }else{
                                            setShowEndDateFields(0);
                                            setValue("ShowEndDate", 0);
                                        }
                                    }}
                                    onBlur={ShowEndDate.onBlur}
                                    ref={ShowEndDate.ref} 
                                    style={{'marginLeft':'10px'}}
                                ></input>                                
                            </div>
                        </fieldset >	
                    </Form.Group>
                </Col>
            </Row>
            
            {Number(showEndDateFields)===1 && 
                <Row className="mt-1">   
                    <Col  md={4} sm={12} className="mb-0">
                        <Form.Group>
                            <Form.Label  htmlFor="name-end-dt">End date label</Form.Label>                  
                            <input type="text" className="form-control" name="EDLabel"  id="EDLabel" {...register("EDLabel")} ></input>
                        </Form.Group>
                    </Col>
                    <Col  md={4} sm={12} className="mb-0">
                        <Form.Group>
                            <Form.Label htmlFor="name-end-dt-label">End date format</Form.Label>
                            <Form.Select className="form-select" name="EDFormat" id="EDFormat" aria-label="end date format" {...register("EDFormat")} >                                
                                <option value="1" >Year</option>
                                <option value="2" >Year and Month</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col  md={4} sm={12} className="mb-3">
                        <Form.Group>
                            <Form.Label></Form.Label>
                            <Form.Group>    
                                <fieldset className="row mb-3" >
                                    <div className="Col">
                                        <Form.Label className="col-form-label pt-0">Is end date mandatory?</Form.Label>
                                        <input className="form-check-input" type="checkbox" name="EDMandatory" id="EDMandatory" {...register('EDMandatory')} style={{'marginLeft':'10px'}} ></input>
                                    </div>
                                </fieldset >
                            </Form.Group>
                        </Form.Group>
                    </Col>
                </Row>
            }

            <Row className="mt-3">
				<Col>
                    <Form.Group>
						<Form.Label className="form-check-label required"  >
							Status
						</Form.Label>
						<div className="form-check mt-0">
							<input className="form-check-input" type="radio" name="Status"  id="gridRadios1" value="1"
                            checked={Number(status)===1?true:false}
                            onChange={(e) => {setStatus(e.target.value);setValue("Status",1);}}
                            required    
							></input>
							<Form.Label className="form-check-label"   >
								Active
							</Form.Label>
						</div>
						<div className="form-check">
						   <input className="form-check-input" type="radio" name="Status" id="gridRadios2" value="0" required 
                           checked={Number(status)===0?true:false}
                           onChange={(e) => {setStatus(e.target.value);setValue("Status", 0)}}
						   ></input>
						    <Form.Label className="form-check-label" >
							    Inactive
						    </Form.Label>
						</div>
                    </Form.Group>
                </Col>
			</Row>
            
            {
                (id !== undefined) ?
                    <ModuleEditScreenButtons cancelPath={Routes.RelationType.path} addnewPath={Routes.RelationType.addpath} showSave={showSave} startSave={startSave} ownerInfo={ownerInfo}  /> 
                : 
                    <ModuleEditScreenButtons cancelPath={Routes.RelationType.path} showSave={showSave} startSave={startSave}  /> 
            }                
            </Form>
        
            
        </>
    );
}
export default RelationTypeForm;
