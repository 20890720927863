import { Col, Row, Button } from '@themesberg/react-bootstrap';
import { Link,useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../../routes";
import useFetch from "../../framework/hooks/useFetch";
import { useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { StatusInGrid } from "../../framework/utilities/utilities";
import ConfirmDeleteModal from '../../project/components/ConfirmDeleteModal';

const RelationList = () => {

    const history = useHistory();
    let [modalShow, setModalShow] = useState(false);
    let [delRecId, setDelRecId] = useState(0);
    let [delRecTitle, setDelRecTitle] = useState('');
    const [gridApi, setGridApi] = useState(null);


    // getting the user listing
    const API_URL = process.env.REACT_APP_API_URL;
    let obj = useFetch(API_URL + '/v1/relationtype/data/list?Page_Limit=10000&Page=1', 'POST');
    const rowData = obj.data.data;
    const gridRef = useRef(null);
    const rowHeight = 54;

    const deleteBtn=(params)=>{        
        setDelRecId(params.value);
        setModalShow(true);
        setDelRecTitle(params.data.RType);
    }

    
    
    const editBtn=(params)=>{
        //console.log(params);
        history.push(`${Routes.RelationType.editpath.replace(':id',params.value)}`);
    }

    const columns =[
        {headerName:"Relation Type",field:"RType",sortable:true,filter:true,flex: 1},
        {headerName:"Status",field:"Status",sortable:true,filter:true,flex: 2, minWidth:100,maxWidth:110,cellRenderer: params => {
                return StatusInGrid({value:params.value});                    
            } 
        }, 
        {headerName:"",field:"GID",flex: 2,minWidth:50,maxWidth:50,
        cellRendererFramework:(params)=><div>   
            <span onClick={()=>editBtn(params)} title="Edit" className="grid-action-btns text-success link"><FontAwesomeIcon icon={faEdit} className="me-2" /></span>                     
        </div>},
        {headerName:"",field:"GID",flex: 2,minWidth:50,maxWidth:50,
        cellRendererFramework:(params)=><div>  
             <span onClick={()=> { deleteBtn(params);}}title="Delete" className="grid-action-btns text-danger link"><FontAwesomeIcon icon={faTrashAlt} className="me-2" /></span>                 
        </div>}
    ];

    const heading = "Relation types";
 

    const handleModelClose = () => {
        setModalShow(false);
         // calling the hook here
        // useForceUpdate();
    }

    const handleOnDeleteSubmitted = () => {
        
        fetch(API_URL + '/v1/relationtype/data/delete/'+ delRecId, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
        }).then(response => response.json())
        .then(jsondata => {           
            handleModelClose(false);      
            const selectedData = gridApi.getSelectedRows();
            //const res = gridApi.applyTransaction({ remove: selectedData });
            gridApi.applyTransaction({ remove: selectedData });
            //printGridResult(res);     
        }).catch((err) => {
            console.log("Login error: " + err);
        });

        
        
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
        //setGridColumnApi(params.columnApi);
    };

    
    return ( 
        <>
        <ConfirmDeleteModal modalShow={modalShow} handleModelClose={() => { setModalShow(false);}} moduleName="relation type" delRecTitle={delRecTitle} handleOnDeleteSubmitted={handleOnDeleteSubmitted} />
        <ModuleBreadcrumb links={[{ label: heading } ]}  />
        <h4>{heading}</h4>
        <Row >
            <Col xs={12}>
                <Button as={Link} variant="primary" className="animate-hover" to={Routes.RelationType.addpath}>
                    <FontAwesomeIcon icon={faPlus} className="animate-left-3 me-3 ms-2" />
                    Add new relation type
                </Button>  
            </Col>
        </Row>
        <Row className="mt-3">
            <Col xs={12}>
                
                <div className="relation-type-page">
                    {obj.isError && <div className="alert alert-danger" role="alert">{obj.isError}</div>}
                    {obj.isPending && <div>Loading...</div>}
                    {obj.data &&
                        <>
                            <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
                                <AgGridReact
                                    className="user-table align-items-center"
                                    ref={gridRef}
                                    rowData={rowData}
                                    rowHeight={rowHeight}
                                    columnDefs={columns}
                                    pagination={true}
                                    onGridReady={onGridReady}
                                    rowSelection={'single'}

                                    >
                                </AgGridReact>
                            </div >
                        </>
                    }
                </div>

            </Col>
        </Row>      
        
        </> 
  );
}
 
export default RelationList;
