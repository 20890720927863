import { Alert } from '@themesberg/react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt ,faCheck, faTimes, faLock } from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../../routes";
import useFetch from "../../framework/hooks/useFetch";
import { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { StatusInGrid } from "../../framework/utilities/utilities";
import ConfirmDeleteModal from '../../project/components/ConfirmDeleteModal';
 
const SearchGrid = (props) => {
    const history = useHistory();
    

    //console.log(props);
    let formData = new FormData();
    

    
    
    const API_URL = process.env.REACT_APP_API_URL;
    //let obj = useFetch(API_URL + '/v1/user/data/list?Page_Limit=&Page=1', 'POST',true, formData);
    //let rowData = obj.data.data;
    //console.log("rowData", rowData);

    const [searchStarted, setSearchStarted] = useState(props.searchButtonClicked);
    let [modalShow, setModalShow] = useState(false);
    let [delRecId, setDelRecId] = useState(0);
    let [delRecTitle, setDelRecTitle] = useState('');
    const [gridApi, setGridApi] = useState(null);
    const gridRef = useRef(null);

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    // get the default list of users
   
    let obj = useFetch(API_URL + '/v1/user/data/list?test=1&Page_Limit=&Page=1&Limit=10', 'POST',true, formData);
    const rowData = obj.data.data;
    //console.log(rowData);

    //let rowData = ""
    useEffect(()=>{
        let formData = new FormData();
        const API_URL = process.env.REACT_APP_API_URL;
        // the firstname needs to have atleast two letters
        if (props.searchFormData[0].FName.length >= 2){
            formData.append("FName",props.searchFormData[0].FName);     
        }else{
            formData.append("FName","");     
        }
        if (props.searchFormData[0].SName.length >= 2){
            formData.append("SName",props.searchFormData[0].SName);     
        }else{
            formData.append("SName","");     
        }
        if (props.searchFormData[0].Email.length >= 2){
            formData.append("Email",props.searchFormData[0].Email);     
        }else{
            formData.append("Email","");     
        }
        formData.append("ShowOnlyRealUsers", props.searchFormData[0].ShowOnlyRealUsers);
        if (!props.searchButtonClicked) return false;
        
        setSearchStarted(true);   
        fetch(API_URL + '/v1/user/data/list?test=2&Page_Limit=&Page=1&GetFullList=1', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
            body: formData
        })
        .then(response => response.json())
        .then(jsondata => {
           // console.log("success... assigning rows");
            //console.log("success");
            //console.log(jsondata);
           // rowData = jsondata.data;     
    
            gridApi.setRowData(jsondata.data); // optional
            setSearchStarted(false);
            //console.log("done");
           
        }).catch((err) => {
            
            console.log("Login error: " + err);
        });
    },[props.searchList, props.searchButtonClicked ,props.searchFormData, gridApi]); 
    

    const deleteBtn=(params)=>{
        console.log(params);
        setDelRecId(params.value);
        setModalShow(true);
        setDelRecTitle(params.data.FName+' '+params.data.SName+' ('+params.data.Email+')');

    }
    const handleModelClose = () => {
        setModalShow(false);
    }

    const handleOnDeleteSubmitted = () => {
        
        fetch(API_URL + '/v1/user/data/delete/'+ delRecId, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
        }).then(response => response.json())
        .then(jsondata => {           
            handleModelClose(false);      
            const selectedData = gridApi.getSelectedRows();
            gridApi.applyTransaction({ remove: selectedData }); 
        }).catch((err) => {
            console.log("Login error: " + err);
        });

    }
    const editBtn=(params)=>{
        //console.log(params);
        history.push(`${Routes.Users.editpath.replace(':id',params.value)}`);
    }

    const columns =[
        {headerName:"First name",field:"FName",sortable:true,filter:true,flex: 1},
        {headerName:"Last name",field:"SName",sortable:true,filter:true,flex: 1},    
        {headerName:"Type",field:"UTypeName",sortable:true,filter:true,flex: 1},    
        {headerName:"Email",field:"Email",sortable:true,filter:true,flex: 1},    
        {headerName:"Created on (d/m/y at H:m:s)",field:"CreatedOn",sortable:true,filter:true,flex: 1}, 
        {headerName:'',field:"pwdSet",flex: 2,minWidth:50,maxWidth:50,
        headerComponentParams: { template: '<i class="fa fa-key" title="Password set"></i>' },
        cellRendererFramework:(params)=><div>   
            {(params.value === 'Y') ? 
                <FontAwesomeIcon icon={faCheck} className="animate-left-3 me-3 ms-2 text-success" />
                :
                <FontAwesomeIcon icon={faTimes} className="animate-left-3 me-3 ms-2 text-danger" />
            }
            
        </div>},
        {headerName:'',field:"inviteSent",flex: 2,minWidth:50,maxWidth:50,
        headerComponentParams: { template: '<i class="fa fa-envelope" title="Invite sent" ></i>' },
        cellRendererFramework:(params)=><div>   
            {(params.value !== '') ? 
                <span title={`Invite sent ${params.value}`}><FontAwesomeIcon icon={faCheck} className="animate-left-3 me-3 ms-2 text-success"    /></span>
                :
                <FontAwesomeIcon icon={faTimes} className="animate-left-3 me-3 ms-2 text-danger" />
            }
            
        </div>},
        {headerName:'',field:"locked",flex: 2,minWidth:50,maxWidth:50,
        headerComponentParams: { template: '<i class="fa fa-lock" title="Account locked"></i>' },
        cellRendererFramework:(params)=><div>   
            {(params.value === 'Y') &&
                <FontAwesomeIcon icon={faLock} className="animate-left-3 me-3 ms-2 text-danger" />                
            }
            
        </div>},
        {headerName:"Status",field:"Status",sortable:true,filter:true,flex: 2, minWidth:100,maxWidth:110,cellRenderer: params => {
            return StatusInGrid({value:params.value});                    
            } 
        },
        {headerName:"",field:"GID",flex: 2,minWidth:50,maxWidth:50,
        cellRendererFramework:(params)=><div>   
            <span onClick={()=>editBtn(params)} title="Edit" className="grid-action-btns text-success link"><FontAwesomeIcon icon={faEdit} className="me-2" /></span>                     
        </div>},
        {headerName:"",field:"GID",flex: 2,minWidth:50,maxWidth:50,
        cellRendererFramework:(params)=><div>                
            <span onClick={()=> {deleteBtn(params);}} title="Delete" className="grid-action-btns text-danger link"><FontAwesomeIcon icon={faTrashAlt} className="me-2" /></span> 
        </div>        
        }
    ];

   

    return ( 
        <>  
            
            {/*JSON.stringify(props)*/} 
            {searchStarted && <Alert variant="info">Fetching users... please wait...</Alert>}
           
            <ConfirmDeleteModal modalShow={modalShow} handleModelClose={() => { setModalShow(false);}} moduleName="user" delRecTitle={delRecTitle} handleOnDeleteSubmitted={handleOnDeleteSubmitted} />

            <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
            
            {obj.isError && <div className="alert alert-danger" role="alert">{obj.isError}</div>}
            {obj.isPending && <div>Loading...</div>}
            { (obj.data ) && <AgGridReact
                className="user-table align-items-center"
                ref={gridRef}
                rowData={rowData}
                rowHeight={54}
                columnDefs={columns}
                pagination={true}
                onGridReady={onGridReady}
                rowSelection={'single'}
                >
            </AgGridReact>}</div>
        </>
    );
}
 
export default SearchGrid;

