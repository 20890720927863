import React from "react";
import { Card, Accordion, Row, Col,Form } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const ModifyCommentList = (props) => {

    const rowData = props.rowData;
    const obj = props.obj;
    
    //console.log("rowData",rowData);

    
    const updateCommentState = (gId, currStatus, selStatus) => {
        
        if ((gId > 0) && (currStatus !== selStatus)) {
            let formData = new FormData();
            formData.append("GID", gId);
            formData.append("Status", selStatus);
            const API_URL = process.env.REACT_APP_API_URL;
            fetch(API_URL + '/v1/relation_modify_request_update_status', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("token"),
                },
                body: formData
            })
            .then(response => response.json())
            .then(jsondata => {
                if (jsondata.message === "UPDATED"){
                    window.location.reload();
                }
                console.log("success");
                
            }).catch((err) => {
                console.log("Login error: " + err);                       
            }); 
            
            
        }        
    }

    return (
        <>
            {obj.isError && <div className="alert alert-danger" role="alert">{obj.isError}</div>}
            {obj.isPending && <div>Loading...</div>}
            {obj.data && rowData &&
                <Accordion defaultActiveKey="comments-0" className="mb-5">
                    <Accordion.Header>Comments</Accordion.Header>
                    <Accordion.Item eventKey="comments-0">
                        <Accordion.Body>
                            {(rowData && (rowData.length > 0)) && <><p>All starred items are those marked for admin by users.</p></> }
                            {rowData.map((comment, index) =>{ 
                                return (
                                    <React.Fragment key={`comment-${index}`}>
                                        <Card className="mb-3"><Card.Body >
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="user d-flex flex-row align-items-center" style={{ paddingRight: "10px" }}>  
                                                    <span>
                                                    { (comment['ContactAdmin'] === 'Y') && <FontAwesomeIcon icon={faStar} className="me-3 ms-2" />}
                                                        <small className="font-weight-bold text-primary p-1">{comment['submittedBy']}</small> 
                                                        <small className="font-weight-bold">
                                                            {comment['Comments'].split('\n').map((item, key) => { return <span key={key}>{item}<br/></span> })}
                                                        </small>
                                                    </span>
                                                </div> 
                                                <small>{comment['createdAt']}</small>                                                
                                            </div>
                                            { (comment['ContactAdmin'] === 'Y') && 
                                            <Row>
                                                <Col>
                                                    <Form.Check
                                                        inline
                                                        label="Hide"
                                                        name={`group-status-${index}`}
                                                        type="radio"
                                                        value="4" 
                                                        checked={Number(comment['Status'])===4?true:false}                                               
                                                        id={`inline-radio-1-${index}`}
                                                        onChange={(e) => { 
                                                            updateCommentState(comment['GID'],Number(comment['Status']),4);
                                                        }}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="Pending"
                                                        name={`group-status-${index}`}
                                                        type="radio"
                                                        value="3"
                                                        checked={Number(comment['Status'])===3?true:false}    
                                                        id={`inline-radio-2-${index}`}
                                                        onChange={(e) => {                                                            
                                                            updateCommentState(comment['GID'],Number(comment['Status']),3);
                                                        }}
                                                    />
                                                    </Col></Row>}
                                            </Card.Body>  
                                        </Card>
                                    </React.Fragment>
                                    
                                )
                            })}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                
            }
        </>
    )
}

export default ModifyCommentList;