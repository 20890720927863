import React from "react";
import ConferenceForm from './Form';
import { Col, Row } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import { useForm } from "react-hook-form";
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';

const  ConferenceAdd= () => {
    const heading = "Add new conference";
    const {  handleSubmit, setValue , register, getValues, formState: { errors }} = useForm();
    setValue("CName", '');
    setValue("Status", 0);

    const showSave = true;
    const recStatus = 1;

    return (  
        <>
            <ModuleBreadcrumb links={[{ label:"Conferences", linkto: Routes.Conferences.path} , { label: heading }]}  />
            <h4>{heading}</h4>
            <Row className="mt-4">
                <Col >
                <ConferenceForm  setValue={setValue} getValues={getValues} register={register} handleSubmit={handleSubmit} errors={errors} showSave={showSave} recStatus={recStatus} />
                </Col>
            </Row> 
        </> 

    );

}
 
export default ConferenceAdd;