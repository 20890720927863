

import React from "react";
import { Modal, Button } from '@themesberg/react-bootstrap';
import { faTrash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ConfirmDeleteModal = (props) => {
    return (
    <Modal
        show={props.modalShow}            
        onHide={props.handleModelClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Deletion confirmation
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>            
            <p>
                Do you wish to delete {props.moduleName} "{props.delRecTitle}"? {(props.extraMessage !== '') ? props.extraMessage : ''}
            </p>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={props.handleModelClose}  className="animate-hover btn btn-sm btn-secondary" >
                <FontAwesomeIcon icon={faTimes} className="animate-left-3 me-3 ms-2" />
                Cancel
            </Button> 
            <Button   onClick={props.handleOnDeleteSubmitted}  className="animate-hover btn btn-sm btn-delete" >
                <FontAwesomeIcon icon={faTrash} className="animate-left-3 me-3 ms-2" />
                Delete
            </Button>
        </Modal.Footer> 
    </Modal>
    );
}

export default ConfirmDeleteModal;