

import React from "react";
import { Modal, Button } from '@themesberg/react-bootstrap';
import { faThumbsUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ConfirmModal = (props) => {
    return (
    <Modal
        show={props.confirmModalShow}            
        onHide={props.handleConfirmModelClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Confirmation
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>            
            <p>
                {props.message}
            </p>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={props.handleConfirmModelClose}  className="animate-hover btn btn-sm btn-secondary" >
                <FontAwesomeIcon icon={faTimes} className="animate-left-3 me-3 ms-2" />
                Cancel
            </Button> 
            <Button   onClick={props.handleOnOKSubmitted}  className="animate-hover btn btn-sm btn-primary" >
                <FontAwesomeIcon icon={faThumbsUp} className="animate-left-3 me-3 ms-2" />
                OK
            </Button>
        </Modal.Footer> 
    </Modal>
    );
}

export default ConfirmModal;