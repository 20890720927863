import EmailEditForm from './Form';
import { Col, Row  } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';

const EmailTempEdit = () => {
    const heading = "Edit email template";
    return (  
        <>
            <ModuleBreadcrumb links={[{ label:"Email templates", linkto: Routes.EmailTemp.path } , { label: heading } ]}  />
            <h4>{heading}</h4>
            <Row>
                <Col xs={12}>
                    <EmailEditForm />
                </Col>
            </Row>      
        
        </> 

    );
}
 
export default EmailTempEdit;