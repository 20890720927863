export function validateForm(postedData){

    let errors = {};
    let formIsValid = true;
    
    if ((!postedData.UId) || (postedData.UId === '')){  
        errors["UId"] = "Please select the main author/user";
        formIsValid = false;  
    }

    if (![3, 6, 7,  8].includes( Number(postedData.relationType) )){
        if (!postedData.rUserId){ 
            errors["rUserId"] = "Please select the related author/user";
            formIsValid = false;  
        }
    
        if (postedData.UId === postedData.rUserId){
            errors["UIdrUserId"] = "Main user and related cannot be same";
            formIsValid = false; 
        }
    }
    
    
    if (Number(postedData.relationType) === 0){
        errors["relationType"] = "Please select the relation type.";
        formIsValid = false;
    }
    /*if ((Number(postedData.relationType) === 3) && (Number(postedData.publicationId) === 0)){
        errors["publicationId"] = "Please select the publication.";
        formIsValid = false;
    }*/
    
    if ((Number(postedData.relationType) === 3) && ( (postedData.publicationName === '') || (postedData.publicationName === undefined)) ){
        errors["publicationId"] = "Please enter the publication title.";
        formIsValid = false;
    }

    if ( ([6, 7].includes(Number(postedData.relationType))) && ( (postedData.publicationName === '') || (postedData.publicationName === undefined))){
        errors["publicationId"] = "Please enter the award name.";
        formIsValid = false;
    }


    if ((Number(postedData.relationType === 8)) && (postedData.rInstitutionId === 0)){
        errors["rInstitutionId"] = "Please select the institution.";
        formIsValid = false;
    }
    

    if ((postedData.startDateMand === 1) && ( (postedData.dateStartYear === "Invalid date") || (postedData.dateStartYear === "") || (postedData.dateStartYear === "null")  || (postedData.dateStartYear === null)  ) ){
        errors["startDate"] = "Please select the "+ postedData.startDateLabel.toLowerCase();
        formIsValid = false;
    }
    
    if ((postedData.endDateMand === 1) && ( (postedData.dateEndYear === "Invalid date") || (postedData.dateEndYear === "") || (postedData.dateEndYear === "null") || (postedData.dateEndYear === null) )){
        errors["endDate"] = "Please select the "+ postedData.endDateLabel.toLowerCase();
        formIsValid = false;
    }

    if ((postedData.GID !== '') && (postedData.GID !== undefined)) {
        if ((!postedData.modificationsComment) || (postedData.modificationsComment === "")){
            errors["endDate"] = "Please enter comment stating what updates are made. This will be notified to all users in this relation.";
            formIsValid = false;
        }
    }

    if (!formIsValid){
        return (
            <>
                <strong>Please check below fields:</strong><ul>
                {
                    Object.keys(errors).map(key => {
                        return(<li key={`error-${key}`}>{errors[key]}</li>)
                    })
                }
                </ul>
            </>
        ) 
    }else{
        return false;
    }
}

export function statusPill(status){
    return (
        <>
        {(status === 1) && <span className="badge rounded-pill bg-success">Validated</span>}
        {(status === 0) && <span className="badge rounded-pill bg-danger">Inactive</span>}
        {(status === 2) && <span className="badge rounded-pill bg-danger">Set for deletion</span>}
        {(status === 3) && <span className="badge rounded-pill bg-danger">Pending</span>}
        {(status === 4) && <span className="badge rounded-pill bg-danger">Disputed</span>}
        
        </>
    )
}


