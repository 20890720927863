import React,{useState,useEffect} from "react";
//import Datetime from "react-datetime";
import DatePicker from "react-datepicker";
import { Col, Row, Form,  Alert, Button, Spinner } from '@themesberg/react-bootstrap';
import moment from "moment-timezone";
import { Routes } from "../../routes";
import ModuleEditScreenButtons  from "../../project/components/ModuleEditScreenButtons";
import Select from 'react-select';
import { useParams, useHistory  } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import CreatableSelect from 'react-select/creatable';
import { validateForm } from "./relations";
import ModifyCommentList from '../relationNewRequests/ModifyCommentList';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPlus, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { scrollToTop, validEmail } from "../../framework/utilities/utilities";
import DynamicCoauthor from './DynamicCoauthor';
import {  statusPill } from "./relations";
import useFetch from "../../framework/hooks/useFetch";

const RelationEditForm = (props) => {
    
    // On save of form - control comes to this function.
    // postedData - data posted by the form
    let saveUrl = '/v1/relation/data/save'; 
    const onSubmit = postedData => {
           
       
        if ( (showStartYear === 1) && ((startDate === null) || (postedData.dateStartYear === "Invalid date") || (postedData.dateStartYear === ""))){
            postedData.dateStartYear = null; 
            postedData.dateStartMonth = null;
        }else if ((showStartYear === 1) && (startDateFormat === 2)){
            postedData.dateStartYear  = (postedData.dateStartYear !== moment(startDate).format("YYYY/MM")) ?  moment(startDate).format("YYYY/MM") : postedData.dateStartYear;
            let str = postedData.dateStartYear.toString();  
            if (str.includes('/')){
                let dSY = postedData.dateStartYear.split('/');
                postedData.dateStartMonth =  (dSY[1] ? dSY[1] : 0);
                postedData.dateStartYear =  ((dSY[0]) ? dSY[0] : 0);                
            }else{
                postedData.dateStartMonth =  null;   
            }
        }else if (showStartYear === 1) {
            postedData.dateStartYear  = (postedData.dateStartYear !== moment(startDate).format("YYYY")) ?  moment(startDate).format("YYYY") : postedData.dateStartYear;
            postedData.dateStartMonth =  null;   
        }else{
            // if not shown then set it to null
            postedData.dateStartYear = null;
            postedData.dateStartMonth = null; 
        }

        if ((showEndYear === 1) && ((endDate === null) || (postedData.dateEndYear === "Invalid date") || (postedData.dateEndYear === ""))) {
            postedData.dateEndYear = null; 
            postedData.dateEndMonth = null; 
        }else if ( (showEndYear === 1) && (endDateFormat === 2)){
            postedData.dateEndYear  = (postedData.dateEndYear !== moment(endDate).format("YYYY/MM")) ?  moment(endDate).format("YYYY/MM") : postedData.dateEndYear;
            
            let str = postedData.dateEndYear.toString();  
            if (str.includes('/')){
                let dSY = postedData.dateEndYear.split('/');
                postedData.dateEndMonth =  (dSY[1] ? dSY[1] : 0);
                postedData.dateEndYear =  ((dSY[0]) ? dSY[0] : 0);                
            }else{
                postedData.dateEndMonth =  null;   
            }
        }else if (showEndYear === 1){
            postedData.dateEndYear  = (postedData.dateEndYear !== moment(endDate).format("YYYY")) ?  moment(endDate).format("YYYY") : postedData.dateEndYear;
            postedData.dateEndMonth =  null;   
        }else{
            postedData.dateEndYear  = null;
            postedData.dateEndMonth =  null;   
        }

        

        postedData.modificationsComment = modificationsComment;
        postedData.publicationName  = (postedData.publicationName !== publicationName) ?  publicationName : postedData.publicationName;
        postedData.source  = (postedData.source !== sourceVal) ?  sourceVal : postedData.source;
        postedData.notes  = (postedData.notes !== notes) ?  notes : postedData.notes;
        postedData.Status  = (postedData.Status !== status) ?  status : postedData.Status;
        postedData.paperType = (postedData.paperType !== paperTypeId) ?  paperTypeId : postedData.paperType;
        postedData.communityPaperId = (postedData.communityPaperId !== communityPaperId) ? communityPaperId : postedData.communityPaperId;
        
        setStartSave(true);
        setErrorMessage("");

        const errMesgs = validateForm(postedData);
        
        if (errMesgs) {
            setErrorMessage(errMesgs);
            setStartSave(false);
            scrollToTop();
            return false;
        }

        

        let formData = new FormData();
        if(id !== undefined){
            formData.append("GID", id);
        }
        formData.append("UId", postedData.UId);
        formData.append("relationType", postedData.relationType);
        formData.append("rUserId", postedData.rUserId);

        if (coAutherRelationTypes.includes(postedData.relationType)){
        //if ( (postedData.relationType === 3) || (postedData.relationType === 6) || (postedData.relationType === 7)) 
            saveUrl  = '/v1/relation/data/coauthor_save';
            formData.append("coAuthorDetails", JSON.stringify(inputList));
        }

        if (postedData.relationType === 8) {
            saveUrl  = '/v1/relation/data/pending_employment_save';
            formData.append("rInstitutionId", postedData.rInstitutionId);
            formData.append("rInstitutionName", postedData.rInstitutionName);

            formData.append("relationTypeName", postedData.relationTypeName);
            formData.append("relationType", postedData.relationType);
            
            formData.append("authorName", postedData.authorName);

        }

        if (postedData.publicationName){
            formData.append("publicationId", postedData.publicationId);
            formData.append("publicationName", postedData.publicationName);
        }
        formData.append("oldPublicationName", postedData.oldPublicationName);
        formData.append("modificationsComment", modificationsComment);

        formData.append("dateStartYear", (postedData.dateStartYear) ? (postedData.dateStartYear) : null);
        formData.append("dateStartMonth", (postedData.dateStartMonth) ? (postedData.dateStartMonth) : null);
        formData.append("dateEndYear", (postedData.dateEndYear) ? (postedData.dateEndYear) : null);
        formData.append("dateEndMonth", (postedData.dateEndMonth) ? (postedData.dateEndMonth) : null);


        formData.append("source", postedData.source);
        formData.append("notes", ((postedData.notes)? postedData.notes : ''));
        formData.append("Status", postedData.Status);
        formData.append("communityPaperId",postedData.communityPaperId);

        // If its a co-author and paper type is not select we set it the default
        //if ( (Number(postedData.relationType) === 3) && (Number(postedData.paperType) === 0)) {
        if (coAutherRelationTypes.includes(postedData.relationType)) {
            if ((postedData.relationType === 6) || (postedData.relationType === 7)){
                formData.append("paperType", 0);
            }else{
                formData.append("paperType", ((Number(postedData.paperType) === 0) ? 1 : postedData.paperType));
            }            
        }else{
            formData.append("paperType", postedData.paperType);
        }
        
        fetch(API_URL + saveUrl, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
            body: formData
        })
        .then(response => response.json())
        .then(jsondata => {
            
            if ((jsondata.GID) && (jsondata.message === 'UPDATED')){
                setSuccessMessage("Record updated successfully.");
                setTimeout(function(){                    
                    window.location.reload();
                },1000); 
            }else if ((jsondata.GID) && (jsondata.message === 'CREATED')){
                setSuccessMessage("Record created successfully.");
                setTimeout(function(){
                    history.push(Routes.Relations.editpath.replace(':id',jsondata.GID));
                },1000);                
            }else if ((jsondata.GID) && (jsondata.message === 'CREATED_PENDING')){
                setSuccessMessage("Record updated successfully.");
                setTimeout(function(){
                    history.push(Routes.Relations.pendingRelationEdit.replace(':id',jsondata.GID));
                    setSuccessMessage("");
                },1000);                
            }else if ((jsondata.GID) && (jsondata.message === 'UPDATED_PENDING')){
                setSuccessMessage("Record updated successfully.");
                setTimeout(function(){
                    window.location.reload();
                    setSuccessMessage("");
                },1000);                
            }else if (jsondata.errors){
                setErrorMessage(jsondata.message + "\r\n" + JSON.stringify(jsondata.errors)); 
                scrollToTop();                      
            }    
            setStartSave(false);               
        }).catch((err) => {
            console.log("Login error: " + err);
            setErrorMessage("Record could be saved at the moment. Please try again.");       
            setStartSave(false);               
        });

    }


    const coAutherRelationTypes = [3,6,7];
    const { id } = useParams(); // id passed in url
    const API_URL = process.env.REACT_APP_API_URL;
    let commentsObj  = useFetch(API_URL + '/v1/relation_modify_request_comments_list?relationId='+ id, 'GET');
    const commentsRowData = commentsObj.data.data;
    const userActionRowData = commentsObj.data.user_action_data;  
   
    //const register = props.register; // set by useForm
    const handleSubmit = props.handleSubmit; // set by useForm
    const setValue = props.setValue; // set by useForm
    const getValues = props.getValues; // set by useForm
    //const errors = props.errors; // set by useForm
    let paperType = getValues("paperType"); // stores the paper type of this relation
    const paperTypes = getValues('paperTypes'); // stores the static values of paper types returned by model
    const getRelationTypeData = getValues('relationTypes');   // stored relation types posted by model
    const [typesData, setTypesData] = useState([]); // with the help of getRelationTypeData, stores the relation types data, needed to show hide fields
    
    const [relationTypeId, setRelationTypeId] = useState(getValues('relationType')); // used by the relation type dropdown default label
    const [relationTypeName, setRelationTypeName] = useState(''); // used by the relation type dropdown default label
   //const [paperTypeName, setPaperTypeName] = useState(); // used by the paper type dropdown default label
    const [authorName, setAuthorName] = useState(getValues('authorName')); // used by the author name dropdown default label
    const [relAuthorName, setRelAuthorName] = useState(getValues('relAuthorName')); // used by the related author name dropdown default label
    //const [paperOptions, setPaperOptions] = useState(); // stores all options needed to populate paper type dropdown
    const [relationTypeOptions, setRelationTypeOptions] = useState(); // stores all options needed to populate relation type dropdown
    
    // some default values set for the fields
    
    let startDt, endDt;
    if (getValues('dateStartYear')){
       if ((getValues('dateStartMonth') === null) || (getValues('dateStartMonth') === 'null')){
            startDt = new Date(getValues('dateStartYear'),0,1,0,0,0); 
       }else{
            startDt = new Date(getValues('dateStartYear'),getValues('dateStartMonth')-1,1,0,0,0); 
       }
       
    }else{
        startDt = null;
    }
    if (getValues('dateEndYear')){        
        if ( (getValues('dateEndMonth') === null) || (getValues('dateEndMonth') === 'null')) {
            endDt = new Date(getValues('dateEndYear'),0,1,0,0,0);
        }else{
            endDt = new Date(getValues('dateEndYear'),getValues('dateEndMonth')-1,1,0,0,0);
        }
        
    }else{
        endDt = null;
    }


    const [startDate, setStartDate] = useState(startDt); 
    const [endDate, setEndDate] = useState(endDt);


    const [status, setStatus] = useState(props.recStatus);
    const [showStartYear,setShowStartYear] = useState(0);
    const [showEndYear,setShowEndYear] = useState(0);
    const [startDateLabel,setStartDateLabel] = useState('Start date');
    const [endDateLabel,setEndDateLabel] = useState('End date');
    const [startDateFormat,setStartDateFormat] = useState();
    const [endDateFormat,setEndDateFormat] = useState();
    const [startDateMand,setStartDateMand] = useState();    
    const [endDateMand,setEndDateMand] = useState();    
    const [showPublicationDrp,setShowPublicationDrp] = useState(getValues('publicationId'));    
    const [showPublicationType,setShowPublicationType] = useState( ( (getValues('paperType') > 0) ? 1 : 0 ) );    
    const [charsLeft, setCharsLeft] = useState(0);
    //const [commentErrMessage, setCommentErrMessage] = useState('');
    //const [commentSuccessMessage, setCommentSuccessMessage] = useState('');
    const [coAuthLabelName, setcoAuthName] = useState(getValues('coAuthLabel'));
    const [saveAuthorFlag, setSaveAuthorFlag] = useState(false);
    const [pubFieldLabel, setPubFieldLabel] = useState("Publication title");
    const [coauthorAddFieldLabelPlural, setCoauthorAddFieldLabelPlural] = useState("Co-authors");
    const [coauthorAddFieldLabelSingu, setCoauthorAddFieldLabelPSingu] = useState("Co-author");
    const [rInstitutionName, setRelInstitutionName] = useState(getValues('rInstitutionName'));
    const [modificationsComment, setModificationsComment] = useState('');
    const [sourceVal, setSourceVal] = useState(getValues('source'));
    const [notes, setNotes] = useState(getValues('notes'));
    const [paperTypeId, setPaperTypeId] = useState(getValues('paperType'));
    const [communityPaperId, setCommunityPaperId] = useState(getValues('communityPaperId'));
    
    const ownerInfo = { 
        'createdBy' :  getValues('createdBy'),
        'createdAt' : getValues('createdAt'),
        'updatedBy' :  getValues('updatedBy'), 
        'updatedAt' : getValues('updatedAt') 
    }; 

    // below states are needed for the form in general
    const [successMessage,setSuccessMessage] = useState('');
    const [errMessage,setErrorMessage] = useState('');
    const [startSave,setStartSave] = useState(false);

   // const API_URL = process.env.REACT_APP_API_URL;
    const history = useHistory();

    
   const [publicationName, setPublicationName] = useState(getValues('publicationName'));
   // const [publicationDrpList, setPublicationDrpList] = useState();

    const [inputList, setInputList] = useState([       
                
    ]);

    useEffect(() => {
        let coauthorData = props.coauthorData;
        
        // If any errors are thorws, it used to reset the co-author list hence below is done to use the current state values
        if ((inputList) && (coauthorData) && (inputList.length > 0) ){
            coauthorData = inputList;
        }

        if (coauthorData)
            setInputList(coauthorData);
    }, [props,inputList, setInputList]);

   // useEffect(() => {
        
        //const jsondata_publications = getValues('allPublications');
        //setPublicationDrpList(jsondata_publications);
        /*if (jsondata_publications){
            Object.keys(jsondata_publications).forEach(key => {
                tmpOptions.push({
                    value: key,
                    label: jsondata_publications[key]
                });            
            });
            setPublicationDrpList(tmpOptions);
            
        }*/
    //},[getValues]);
   
    
    const addCoAuthor = () => {

        if(!getValues('coAuthId'))
        {
            setErrorMessage("Please select co-author");
            scrollToTop();
            return false;
        }

        if((getValues('coAuthId') === getValues('coAuthLabel')) && !validEmail(getValues('coAuthLabel')))
        {
            setErrorMessage("Please enter email of co author");
            scrollToTop();
            return false;
        }

        setSaveAuthorFlag(true);
        //setShowCoauthorList(false);
        setTimeout(function(){
            setSaveAuthorFlag(false);
            //setShowCoauthorList(true);
        },1000);

        
        setInputList([...inputList, { relationId:"",relationParentId:id, coauthor_id: getValues('coAuthId'), coauthor_name: getValues('coAuthLabel'), status:3, alow_modify: 1 }  ]); 

        setValue("coAuthId", '');
        setValue("coAuthLabel", '');
        setcoAuthName('');
    }
    // based on the relation type we will be modifiying the field labels/ display state etc.
    const showHideFields = (type) => {        
        let flag = (Number(typesData[type][0].show_start_date) === 0)? 0 : 1; 
        setShowStartYear(flag);
        flag = (Number(typesData[type][0].show_end_date) === 0)? 0 : 1; 
        //console.log(type, Number(typesData[type][0].show_end_date),flag);
        setShowEndYear(flag);
        setStartDateLabel(typesData[type][0].show_start_date_label);
        setEndDateLabel(typesData[type][0].show_end_date_label);
        setStartDateFormat(typesData[type][0].start_date_format);
        setEndDateFormat(typesData[type][0].end_date_format);
        setStartDateMand(typesData[type][0].start_date_mandatory);
        setEndDateMand(typesData[type][0].end_date_mandatory);
        setValue("startDateMand",typesData[type][0].start_date_mandatory);
        setValue("endDateMand",typesData[type][0].end_date_mandatory);
        setValue("startDateLabel",typesData[type][0].show_start_date_label );
        setValue("endDateLabel",typesData[type][0].show_end_date_label );

        if (coAutherRelationTypes.includes(Number(type))){        
            // for co-authors we need show the publication dropdown
            setShowPublicationDrp(1); 
            if ((type===6) || (type===7)){
                setShowPublicationType(0);  
                setPubFieldLabel('Award name');
                setCoauthorAddFieldLabelPlural('Co-awardees');
                setCoauthorAddFieldLabelPSingu('Co-awardee');   
            }else{
                setShowPublicationType(1);    
                setPubFieldLabel('Publication title');
                setCoauthorAddFieldLabelPlural('Co-authors');
                setCoauthorAddFieldLabelPSingu('Co-author');      
            }
            
        }else{
            setShowPublicationDrp(0);
            setShowPublicationType(0);
        }

    }

    

    // Initially on edit screen we want to trigger the showhide of fields based on retailer type.
    useEffect(()=>{ 
        if (typesData.length > 0){            
            showHideFields(relationTypeId)
        }       
    });
    
    
    // below code will get executed only after the component is mounted. It runs once.
    useEffect(()=>{

        // we re-built the relation type array here with the keys as the type ID from db.
        const selectRelationTypeOptions = [];
        let relationTypeData = [];
        Object.keys(getRelationTypeData).forEach(key=> {            
            relationTypeData[getRelationTypeData[key].id] = [];
            relationTypeData[getRelationTypeData[key].id].push(getRelationTypeData[key]);

            // needed for the dropdown
            if (Number(getValues("relationType")) === Number(getRelationTypeData[key].id)){
                setRelationTypeName(getRelationTypeData[key].type_name);
            }
            selectRelationTypeOptions.push({
                value: getRelationTypeData[key].id,
                label: getRelationTypeData[key].type_name
            });
            

        });
        setRelationTypeOptions(selectRelationTypeOptions); // needed for the dropdown
        setTypesData(relationTypeData);
        
        // Re-building the options for paper types
        const selectPaperTypeOptions = [];
        Object.keys(paperTypes).forEach(key=> {   
            /*if (Number(key) === Number(paperType)){
                setPaperTypeName(paperTypes[key]);
            }*/
            selectPaperTypeOptions.push({
                value: key,
                label: paperTypes[key]
            });
            
        });        
        //setPaperOptions(selectPaperTypeOptions);


        /*// Re-building the options for relation types
        const selectRelationTypeOptions = [];
        fetch(API_URL + '/v1/relationtype/data/list/relationTypeDrp', {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            }
        }).then(response => response.json())
        .then(jsondata => {
                Object.keys(jsondata).forEach(key => {
                    if (Number(getValues("relationType")) === Number(key)){
                        setRelationTypeName(jsondata[key]);
                    }
                    selectRelationTypeOptions.push({
                        value: key,
                        label: jsondata[key]
                    });
                });
    
                setRelationTypeOptions(selectRelationTypeOptions);            
                setIsLoadingRelationTypeOptions(false);    
            }).catch((err) => {
                console.log("Login error: " + err);
        });*/

        

        
    },[API_URL, getRelationTypeData, getValues, paperType, paperTypes]);
    
/*
    const SaveComment = (postedData) => {
       
        setCommentErrMessage("");
        setCommentSuccessMessage("");

        if  ((!postedData.modificationsComment) || (postedData.modificationsComment === "")){
            setCommentErrMessage("Please enter your comment.");
            return false;
        }

        if ((id !== undefined) && (id !== 0)){
            let formData = new FormData();
            formData.append("GID", postedData.GID); 
            formData.append("relationTypeId", postedData.relationType);                      
            formData.append("relationTypeName", postedData.relationTypes[postedData.relationType]['type_name']);                      
            formData.append("rUserName", postedData.relAuthorName);
            formData.append("modificationsComment", postedData.modificationsComment);
            formData.append("emailUserIds", [postedData.UId, postedData.rUserId]);
            fetch(API_URL + '/v1/relation_save_admin_comment', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("token"),
                },
                body: formData
            })
            .then(response => response.json())
            .then(jsondata => {
               // console.log("success");
               // console.log(jsondata);
                if (jsondata.message === 'CREATED'){
                    //scrollToTop();
                    setCommentSuccessMessage("Comment saved successfully."); 
                    setTimeout(function(){
                       window.location.reload();
                    },2000);  
                }else if (jsondata.errors){
                    setCommentErrMessage(jsondata.message + "\r\n" + JSON.stringify(jsondata.errors));     
                                
                }    
                       
            }).catch((err) => {
                console.log("Login error: " + err);
                setCommentErrMessage("Record could not be saved at the moment. Please try again."); 
            });
            //updateRelation('/v1/web/relation_reject_relation',postedData);
        } else{
            console.log("No id found");
            return false;
        } 

        
    }*/

    return (
        <>

            {successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}            
            {errMessage && <Alert variant="danger">{errMessage}</Alert>}      
            { (getValues('DBLPImportedEntry') === 1) &&
                <Row className="mt-1" >
                    <Col className="callout callout-warning">
                    <FontAwesomeIcon icon={faInfoCircle} className="animate-left-3 me-3 ms-2" />This relation was automatically created from DBLP.
                    </Col>
                </Row>
            }
            <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>            
            <Row >
            <Col md={4} className="mb-3">
                <Form.Group id="role">
                    <Form.Label className="required">Relation type</Form.Label>
                        <div className="col-sm-10"> 
                            <Select name="relationType" options={relationTypeOptions}  value={{value: getValues("relationType"), label:  relationTypeName }} 
                            isDisabled= {((id === undefined) || (id === 0)) ? false : true}
                            onChange={event => {
                                    setRelationTypeName(event.label);
                                    setRelationTypeId(event.value);
                                    setValue("relationType",event.value);
                                    showHideFields(event.value);
                                    }}                               
                            />
                        </div>                        
                    </Form.Group>                    
                </Col>
                { (!coAutherRelationTypes.includes(getValues("relationType"))) && <Col md={4} className="mb-3">
                    <Form.Group>
                        
                        <Form.Label className="required">Author/User</Form.Label>
                        <Select name="UId" options={getValues('usersDrp')}  
                            value={{value: getValues("UId"), label:  authorName}}
                            onChange={event => {
                                setAuthorName(event.label);
                                setValue("UId",event.value);
                                setValue("authorName",event.label);
                            }}
                        />

                    </Form.Group>
                </Col>
                }

                {(Number(relationTypeId) === 8) &&
                    <Col md={4} className="mb-3">
                        <Form.Group>
                            <Form.Label className="required">Institution</Form.Label>
                                <CreatableSelect 
                                    name="InstId" 
                                    options={getValues('institutionDrp')}  
                                    key={"InstitutionSelect"}                                 
                                    value={{value: getValues('rInstitutionId'), label: rInstitutionName}}
                                    isClearable
                                    closeOnScroll={true}
                                    isDisabled={false}
                                    onChange={e => {                                                                      
                                            if (e === null){
                                                setValue("rInstitutionId",0);
                                                setValue("rInstitutionName",''); 
                                                setRelInstitutionName('');
                                            }else if (e.value === e.label){                                           
                                                setValue("rInstitutionId",e.value);
                                                setValue("rInstitutionName",e.value); 
                                                setRelInstitutionName(e.label);                                           
                                            }else{
                                                setValue("rInstitutionId",e.value);
                                                setValue("rInstitutionName",e.label); 
                                                setRelInstitutionName(e.label);
                                            }                       
                                        }
                                    }                                                        
                                />
                            
                            
                        </Form.Group>
                    </Col>
                }


                {  (![3,6,7,8].includes(Number(getValues("relationType")))) && <Col md={4} className="mb-3">                    
                    <Form.Group>
                        <Form.Label className="required">Related author/user</Form.Label>
                        <div className="col-sm-14">
                        <Select name="rUserId" options={getValues('usersDrp')}  
                            value={{value: getValues("rUserId"), label:  relAuthorName}}
                            onChange={event => {
                                setRelAuthorName(event.label);
                                setValue("rUserId",event.value);
                                setValue("relAuthorName",event.label);
                            }}
                        />
                        
                        </div>
                    </Form.Group>
                </Col>
                }
                
            </Row>
            <Row>
                { (showPublicationType > 0) &&
                    <Col md={4} className="mb-3">
                        <Form.Group id="paperType">
                            <Form.Label className="required">Paper/Publication type</Form.Label>
                            <div className="col-sm-10"> 
                                <Form.Check                                            
                                    label="Not a research paper"
                                    inline
                                    name="paperTypeReadOnlyGroup"
                                    type={`checkbox`}
                                    value="2"
                                    checked={(2 === paperTypeId) ? true : false}
                                    id={`paperTypeReadOnlyGroup-inline-radio-1`}
                                    key={`paperTypeReadOnlyGroup-inline-radio-1`}
                                    onChange={event => {
                                        if (event.target.checked){
                                            setPaperTypeId(2);
                                            setValue("paperType",2);
                                        }else{
                                            setPaperTypeId(1);
                                            setValue("paperType",1);
                                        }
                                        
                                    }}
                                    disabled ={ ( ((id !== undefined) || (id !== 0)) && (getValues('relUserIsLoggedInUser') === 1) ) ||   ( ((id !== undefined) || (id !== 0)) && (getValues('sourceUserIsLoggedInUser') === 1) && (props.action === "view")) ? true : false}
                                    />

                                <Form.Check                                            
                                    label="Community paper"
                                    inline
                                    name="paperTypeReadOnlyGroup"
                                    type={`checkbox`}
                                    value="1"
                                    checked={(1 === communityPaperId) ? true : false}
                                    id={`paperTypeReadOnlyGroup-inline-radio-2`}
                                    key={`paperTypeReadOnlyGroup-inline-radio-2`}
                                    disabled ={ ( ((id !== undefined) || (id !== 0)) && (getValues('relUserIsLoggedInUser') === 1) ) ||   ( ((id !== undefined) || (id !== 0)) && (getValues('sourceUserIsLoggedInUser') === 1) && (props.action === "view")) ? true : false}
                                    onChange={event => {
                                        if (event.target.checked){
                                            setCommunityPaperId(1);
                                            setValue("communityPaperId",1);
                                        }else{
                                            setCommunityPaperId(0);
                                            setValue("communityPaperId",0);
                                        }
                                        
                                    }}
                                    />

                                {/*<Select name="paperType" options={paperOptions}  value={{value: getValues("paperType"), label:  paperTypeName }}  
                                onChange={event => {
                                    setPaperTypeName(event.label);
                                    setValue("paperType",event.value);
                                    }}
                                    
                                />    */}
                            </div>
                        </Form.Group>
                    </Col>
                }
                
                {(showStartYear === 1) &&
                <Col md={4} className="mb-3">
                    <Form.Group id="start date">
                        {startDateMand ? <Form.Label className="required">{startDateLabel}</Form.Label> : <Form.Label>{startDateLabel}</Form.Label>}                        
                        <div className="col-sm-10">
                        {
                            (startDateFormat === 2) ?
                                <DatePicker
                                    className="form-control"
                                    name="dateStartYear"
                                    key={"startDate"}
                                    selected={startDate}
                                    isClearable
                                    closeOnScroll={true}
                                    showMonthYearPicker
                                    dateFormat="MM/yyyy"    
                                    autoComplete='off'                                
                                    onChange={(date) => {
                                        setErrorMessage('');
                                        if (!date && startDateMand) setErrorMessage(startDateLabel + " is a required field.");                                       
                                        setStartDate(date);
                                        setValue("dateStartYear",moment(date).format("YYYY/MM"));
                                    }}               
                                />
                            :
                                <DatePicker
                                    className="form-control"
                                    name="dateStartYear"
                                    key={"startDate"}
                                    selected={startDate}
                                    isClearable
                                    closeOnScroll={true}
                                    showYearPicker
                                    dateFormat="yyyy"
                                    yearItemNumber={10}
                                    autoComplete='off'
                                    onChange={(date) => {
                                        setErrorMessage('');
                                        if (!date && startDateMand) setErrorMessage(startDateLabel + " is a required field.");
                                        setStartDate(date);
                                        setValue("dateStartYear",moment(date).format("YYYY"));
                                        setValue("dateStartMonth",null);
                                    }}               
                                />
                        }
                        
                        </div>
                       
                    </Form.Group>
                </Col>
                }

                {(showEndYear === 1) &&
                    <Col md={4} className="mb-3">
                        <Form.Group id="start date">
                        {endDateMand ? <Form.Label className="required">{endDateLabel}</Form.Label> : <Form.Label>{endDateLabel}</Form.Label>}
                            <div className="col-sm-10"> 
                            {
                                (endDateFormat === 2)?
                                    <DatePicker
                                        className="form-control"
                                        name="dateEndYear"
                                        key={"endDate"}
                                        selected={endDate}
                                        isClearable
                                        closeOnScroll={true}
                                        showMonthYearPicker
                                        dateFormat="MM/yyyy"
                                        yearItemNumber={10}
                                        autoComplete='off'
                                        onChange={(date) => {
                                            setErrorMessage('');
                                            if (!date && endDateMand) setErrorMessage(endDateLabel + " is a required field.");
                                            setEndDate(date);
                                            setValue("dateEndYear",moment(date).format("YYYY/MM"));
                                        }}               
                                    />
                                :
                                    <DatePicker
                                        className="form-control"
                                        name="dateEndYear"
                                        key={"endDate"}
                                        selected={endDate}
                                        isClearable
                                        closeOnScroll={true}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        yearItemNumber={10}
                                        autoComplete='off'
                                        onChange={(date) => {
                                            setErrorMessage('');
                                            if (!date && endDateMand) setErrorMessage(endDateLabel + " is a required field.");
                                            setEndDate(date);
                                            setValue("dateEndYear",moment(date).format("YYYY"));
                                            setValue("dateEndMonth",null);
                                        }}               
                                    />
                            }
                            
                            </div>
                        </Form.Group>
                    </Col>
                }
                
            </Row>
            
            <Row>
                { (showPublicationDrp > 0) &&
                    <Col md={4} className="mb-3">
                    <Form.Group>
                        <Form.Label className="required">{pubFieldLabel}</Form.Label>
                        <div className="col-sm-14">
                            <Form.Control type="text"  value={publicationName}  onChange={e => {
                                                setPublicationName(e.target.value);
                                            }}                                    
                                            />

                            { ((id === undefined) || (id === 0)) ?
                                <>{/*<CreatableSelect 
                                    name="publicationId" 
                                    options={publicationDrpList}  
                                    key={"publicationSelect"}                                 
                                    value={{value: getValues('publicationId'), label: publicationName}}
                                    isClearable
                                    closeOnScroll={true}
                                    onChange={e => {
                                        if (e === null){
                                            setValue('publicationId','');
                                            setValue('publicationName','');
                                            setPublicationName('');  
                                        }else{
                                            setValue('publicationId',e.value);
                                            setValue('publicationName',e.label);
                                            setPublicationName(e.label);  
                                        }
                                                                            
                                        }
                                    }                                                        
                                />*/}                                
                                    
                                </>
                            :
                            <></>
                            }                            
                        </div>
                        
                    </Form.Group>
                    </Col> 
                }
               

                
            </Row>
            <Row>
                <Col className="mb-3">
                    <Form.Group>
                        <Form.Label className="required">Source</Form.Label>
                        <div className="col-sm-14">
                            <Form.Control type="text" className="form-control" id="source" value={sourceVal} onChange={e => {
                                                setSourceVal(e.target.value);
                                            }} 
                                            placeholder="(e.g., DBLP entry, self-declared, entry by another user (with name or id of that user).)" required />
                        </div>
                    </Form.Group>
                </Col>                         
            </Row>

            { (showPublicationDrp > 0) && (id !== undefined) && (id !== 0) && (getValues('publicationDBLPUrl') !== '') && 
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label htmlFor="inputSName" className="col-sm-12 col-form-label">Publication DBLP URL</Form.Label>
                            <div className="col-sm-12">
                                {getValues('publicationDBLPUrl')}
                                <a href={getValues('publicationDBLPUrl')} target="_blank"  rel="noreferrer" >
                                    <FontAwesomeIcon icon={faExternalLinkAlt} className="animate-left-3 me-3 ms-2" />
                                </a>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
            }
        
            { ((id === undefined) || (id === 0)) &&
                <Row className="mb-3" >
                    <Col >
                    <Form.Group>
                        <Form.Label htmlFor="inputSName" className="col-sm-2 col-form-label">Notes</Form.Label>
                        <div className="col-sm-14">
                            <textarea className="form-control" id="notes" value={notes} onChange={e => {
                                                    setNotes(e.target.value);
                                                }}   />
                        </div>
                    </Form.Group>
                    </Col>
                </Row>
            }

            <Row className="mt-4">
				<Col>
                    <Form.Group>
						<Form.Label className="form-check-label required"  >
							Status
						</Form.Label>
						<div className="form-check mt-0">
							<input className="form-check-input" type="radio" name="Status"  id="gridRadios1" value="1"
                            checked={Number(status)===1?true:false}
                            onChange={(e) => {setStatus(e.target.value);setValue("Status",1);}}
                            required    
							></input>
							<Form.Label className="form-check-label"   >
                                Validated
							</Form.Label>
						</div>
						<div className="form-check">
						   <input className="form-check-input" type="radio" name="Status" id="gridRadios2" value="3" required 
                           checked={Number(status)===3?true:false}
                           onChange={(e) => {setStatus(e.target.value);setValue("Status", 3)}}
						   ></input>
						    <Form.Label className="form-check-label" >
                                Pending
						    </Form.Label>
						</div>
                        <div className="form-check">
						   <input className="form-check-input" type="radio" name="Status" id="gridRadios2" value="4" required 
                           checked={Number(status)===4?true:false}
                           onChange={(e) => {setStatus(e.target.value);setValue("Status", 4)}}
						   ></input>
						    <Form.Label className="form-check-label" >
                                Disputed
						    </Form.Label>
						</div>
                    </Form.Group>
                </Col>
			</Row>
            
            { (coAutherRelationTypes.includes(getValues("relationType"))) &&
                <>
                    <h4 className="mt-3">{ ((id === undefined) || (id === 0)) ? `Adding ${coauthorAddFieldLabelPlural}` : `${coauthorAddFieldLabelPlural}`  }</h4>
                    <Row className="mt-3 pt-1"  >
                        <Col className="callout callout-info">You can assign any number of {coauthorAddFieldLabelPlural}.</Col>
                    </Row>
                    <Row className="mt-1" >
                        <Col className="callout callout-danger">
                            <FontAwesomeIcon icon={faInfoCircle} className="animate-left-3 me-3 ms-2" /> If the person you want to assign is not present in the dropdown below, you can type the email address of the person and click on <strong><em>Create "email"</em></strong> in the dropdown. For example: test@company.com” <br/><br/>
                            If you know the user details such as firstname, lastname and email, you can create the user in Users module with all these details and then select it here.
                        </Col>
                    </Row>
                    

                    
                    <Row className="mt-3 pt-4" >
                        <Col>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                <th scope="col">#</th>
                                <td width="50%" >{coauthorAddFieldLabelSingu}</td>
                                <td align="center" >Relation status</td>
                                <td align="center"  >Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                { (coAutherRelationTypes.includes(getValues("relationType"))) &&
                                    <>
                                    <tr>
                                        <th>1</th>
                                        <td>
                                            <Select name="UId" options={getValues('usersDrp')}  
                                                value={{value: getValues("UId"), label:  authorName}}
                                                onChange={event => {
                                                    setAuthorName(event.label);
                                                    setValue("UId",event.value);
                                                    setValue("authorName",event.label);
                                                }}
                                            /> <Form.Label className="required" >(Main {coauthorAddFieldLabelSingu})</Form.Label>
                                        </td>
                                        <td valign="middle" align="center">{ (id === undefined) ? '' : 
                                            <>{
                                                ( (status !== 1)  && userActionRowData && userActionRowData[getValues("UId")] && (userActionRowData[getValues("UId")] === "ACCEPTED") ) ? 
                                                    <span className="badge rounded-pill bg-success">Accepted</span>                                             
                                                : 
                                                    <>
                                                        { ( (status !== 1)  && userActionRowData && userActionRowData[getValues("UId")] && (userActionRowData[getValues("UId")] === "REJECTED") ) ? 
                                                            <span className="badge rounded-pill bg-danger">Rejected</span>
                                                        
                                                        : 
                                                            <>{ (status === 1) ? statusPill(status) : <span className="badge rounded-pill bg-danger">Pending</span> }</>
                                                        }                                                        
                                                    </>
                                                }</>
                                        }</td>
                                        <td></td>
                                    </tr>

                                    
                                    </>
                                    
                                }
                                {inputList && (inputList.length > 0) &&
                                <DynamicCoauthor getValues={getValues} inputList={inputList} setInputList={setInputList} coauthorData={props.coauthorData} userActionRowData={userActionRowData} />}

                                <tr><td colSpan="4">
                                    <Row className="mb-5 mt-2">
                                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Select {coauthorAddFieldLabelSingu}</label>
                                        <div className="col-sm-6">
                                        <CreatableSelect 
                                                name="userId" 
                                                options={getValues('usersDrp')}  
                                                key={"userIdSelect"}                                 
                                                value={{value: '', label: coAuthLabelName }}
                                                isClearable
                                                closeOnScroll={true}
                                                onChange={e => {

                                                        if (e === null){
                                                            setValue("coAuthId",0);
                                                            setValue("coAuthLabel",''); 
                                                            setcoAuthName('');
                                                        }else if (e.value === e.label){                                           
                                                            setValue("coAuthId",e.value);
                                                            setValue("coAuthLabel",e.value); 
                                                            setcoAuthName(e.label);                                           
                                                        }else{
                                                            setValue("coAuthId",e.value);
                                                            setValue("coAuthLabel",e.label); 
                                                            setcoAuthName(e.label);
                                                        }       
                                                    }
                                                }                                                        
                                            />
                                        </div>
                                        <div className="col-sm-4 text-align-left">
                                            <Button className="animate-hover btn btn-sm btn-addnew" onClick={()=>addCoAuthor()}  >
                                                <FontAwesomeIcon icon={faPlus} className="animate-left-3 me-3 ms-2" />
                                                Add {coauthorAddFieldLabelSingu}
                                            </Button>
                                        </div>
                                    </Row>
                                    {saveAuthorFlag &&
                                            <Alert variant="info" className="mt-4"><Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            style={{marginRight:10}}
                                            />Please wait while we add the new {coauthorAddFieldLabelSingu}...</Alert>
                                        }

                                </td></tr>

                            </tbody>
                        </table>  
                        {/*<p>{JSON.stringify(inputList)}</p>*/}
                        </Col>
                    </Row>
                    
                </>
            }

            {(id !== undefined) &&
                <>
                    <Row className="mb-4">
                        <Col className="col" >
                            <Form.Group>
                                <Form.Label htmlFor="modificationsComment" className="col col-form-label required">Comments <small>(Max: 1000 characters)</small></Form.Label>
                                <textarea className="form-control" id="modificationsComment" maxLength="1000" rows="5" onKeyUp={(e)=>{
                                    if (e.target.value !== ''){
                                        setCharsLeft(1000 - Number(e.target.value.length));
                                        setModificationsComment(e.target.value);
                                    }else{
                                        setCharsLeft(0);
                                        setModificationsComment("");                                        
                                    }
                                }}  />
                                <small>Characters left: {charsLeft}</small>
                            </Form.Group>
                        </Col>
                    </Row>
                </>
            }

            {
                (id !== undefined) ?
                    <ModuleEditScreenButtons cancelPath={Routes.Relations.path} showSave={props.showSave} startSave={startSave} ownerInfo={ownerInfo} showDeleteBtn={true} delBtnLabel={`Delete entire relation`} id={id} delRecTile={relationTypeName} delApiPath={((relationTypeId === 8) ? '/v1/relation_pending/data/delete/' : '/v1/relation/data/delete/')} /> 
                : 
                    <ModuleEditScreenButtons cancelPath={Routes.Relations.path} showSave={props.showSave} startSave={startSave}  /> 
            }   
            
            {/*
            {(id !== undefined) &&
            <>
                <p className="mt-5">Add your comments in the box below. This comment will be visible to all users in this relation.</p>
                {commentSuccessMessage && !commentErrMessage && <Alert variant="success">{commentSuccessMessage}</Alert>}
                {commentErrMessage && <Alert variant="danger">{commentErrMessage}</Alert>}
                <Row className="mb-2">
                    <Col className="col" >
                        <Form.Group>
                            <Form.Label htmlFor="modificationsComment" className="col col-form-label required">Comments <small>(Max: 1000 characters)</small></Form.Label>
                            <textarea className="form-control" id="modificationsComment" maxLength="1000" rows="5" onKeyUp={(e)=>{
                                if (e.target.value !== ''){
                                    setCharsLeft(1000 - Number(e.target.value.length));
                                    setValue("modificationsComment",e.target.value);
                                }else{
                                    setCharsLeft(0);
                                    setValue("modificationsComment","");
                                }
                            }}  />
                            <small>Characters left: {charsLeft}</small>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-5 text-right">
                    <Col className="col" >
                    <Button variant="primary" type="submit"  className="animate-hover" onClick={handleSubmit(SaveComment)} ><FontAwesomeIcon icon={faPaperPlane} className="animate-left-3 me-3 ms-2" />Submit comment</Button>
                    </Col>
                </Row>
            </>
            }
        */}
            
            </Form>
            {((id !== undefined) && (id !== 0)) && commentsObj && <ModifyCommentList relationId={id} rowData={commentsRowData} obj={commentsObj} />}
            
        </>
    );
}
export default RelationEditForm;
