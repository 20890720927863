import UserRoleForm from './Form';
import { Col, Row,Alert } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import useFetch from "../../framework/hooks/useFetch";

const UserRoleEdit = () => {
    const heading = "Edit user role";
    
    const API_URL = process.env.REACT_APP_API_URL;
    const { id } = useParams();
    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm();

    /* Fetching data and setting the values to the form elements */
    const { data, isPending, isError } = useFetch(API_URL + '/v1/userroles/data/view/' + id, 'GET', true);

    setValue("GID", data.GID);
    setValue("RName", data.RName);
    setValue("Status", data.Status);
    setValue("createdBy", data.createdBy);
    setValue("createdAt", data.createdAt);
    setValue("updatedBy", data.updatedBy);
    setValue("updatedAt", data.updatedAt);


    const showSave = ((data.GID)? true : false );

    return ( 
        <>
            <ModuleBreadcrumb links={[{ label:"User roles", linkto: Routes.UserRole.path } , { label: heading } ]}  />
            <h4>{heading}</h4>          
            <Row>
                <Col xs={12}>
                    {isPending && <div className="alert alert-info" role="alert">Fetching data. Please wait..</div>}                    
                    {isError && <Alert variant="danger">{isError}</Alert>} 
                    {data.GID && <UserRoleForm setValue={setValue} getValues={getValues} register={register} handleSubmit={handleSubmit} errors={errors} recordStatus={data.Status} showSave={showSave} recStatus={data.Status} />}
                </Col>
            </Row>       
        
        </> 
    );
}
 
export default UserRoleEdit;

