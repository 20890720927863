import { Col, Row, Button, Alert } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../../routes";
import useFetch from "../../framework/hooks/useFetch";
import { useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { useHistory } from 'react-router-dom';

const InviteSendList = () => {
    
    const [errMessage,setErrorMessage] = useState('');
    const [successMessage,setSuccessMessage] = useState('');
    const [sendingStarted,setSendingStarted] = useState(false);
    
    // getting the user listing
    const API_URL = process.env.REACT_APP_API_URL;
    let obj = useFetch(API_URL + '/v1/user/get_list_invitemail', 'GET');
    const rowData = obj.data.data;
    const gridRef = useRef(null);
    const rowHeight = 54;

    const history = useHistory();

    const editBtn=(params)=>{
        //console.log(params);
        history.push(`${Routes.Users.editpath.replace(':id',params.value)}`);
    }


    const columns =[
        {headerName:"First name",field:"FName",sortable:true,filter:true,flex: 1},
        {headerName:"Last name",field:"SName",sortable:true,filter:true,flex: 1},    
        {headerName:"Email",field:"Email",sortable:true,filter:true,flex: 1},    
        {headerName:"Created on (d/m/y at H:m:s)",field:"CreatedOn",sortable:true,filter:true,flex: 1},
        {headerName:"",field:"GID",flex: 2,minWidth:50,maxWidth:50,
        cellRendererFramework:(params)=><div>   
            <span onClick={()=>editBtn(params)} title="Edit" className="grid-action-btns text-success link"><FontAwesomeIcon icon={faEdit} className="me-2" /></span>                     
        </div>},
    ];


   
    const onSendButtonClicked = () => {
        setErrorMessage('');
        setSuccessMessage('');
        setSendingStarted(true);

        fetch(API_URL + '/v1/user/send_invitemail_job', { 
            method: 'POST', 
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),                    
            },             
        })
        .then(response => response.json())
        .then(jsondata => { 
            if (jsondata.message !== ''){                    
                setSuccessMessage(jsondata.message);
            }else{
                setErrorMessage("Some issue sending mails. Please refresh.");
            }     
            setSendingStarted(false);       
        }).catch((err) => {                
            console.log("Login error: " + err);
            setErrorMessage("Some issue sending mails. Please refresh.");
            setSendingStarted(false);
        });
    }

    const heading = "Sending invite to users";

    return ( 
        <>        
         
            <ModuleBreadcrumb links={[{ label:"Users", linkto: Routes.Users.path } , { label: heading }]}  />
            <h4>{heading}</h4>
            {errMessage && <Alert variant="danger">{errMessage}</Alert>}
            {successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}      
            {sendingStarted && !errMessage && <Alert variant="warning">Mail sending started... please wait..</Alert>}      
            <Row className="mt-3">
                <Col xs={12}>
                    <div className="users-page mt-4">
                        {obj.isError && <div className="alert alert-danger" role="alert">{obj.isError}</div>}
                        {obj.isPending && <div>Loading...</div>}
                        {(obj.data && rowData && (rowData.length > 0)) ?
                            <>
                                <p>Click on the button below to send invite mail to all the users listed below:<br/>
                                    <small>[NOTE: If you wish to send invite to individual user please click on the edit icon to go to the user edit screen. There is a send invite invite button on this screen.]</small>
                                </p>
                                <Button type="button" className="animate-hover btn btn-sm btn-addnew" onClick={onSendButtonClicked}  >
                                    <FontAwesomeIcon icon={faPaperPlane} className="animate-left-3 me-3 ms-2" /> Send invites now
                                </Button>
                                <div className="ag-theme-alpine mt-4" style={{ height: 400, width: '100%' }}>
                                    <AgGridReact
                                        className="user-table align-items-center"
                                        ref={gridRef}
                                        rowData={rowData}
                                        rowHeight={rowHeight}
                                        columnDefs={columns}
                                        pagination={true}
                                        rowSelection={'single'}
                                        >
                                    </AgGridReact>
                                </div >
                            </>
                            :
                            <><Alert variant="danger">No users to send invite to.</Alert></>
                        }
                    </div>

                </Col>
            </Row>
        </> 
    );
}
 
export default InviteSendList;

