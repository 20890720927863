import React, { useState } from "react";
import { Col, Row, Form } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import ModuleEditScreenButtons  from "../../project/components/ModuleEditScreenButtons";

const EmailEditForm = () => {
    const [name, setName] = useState(''); 
    const [from, setFrom] = useState(''); 
    const [subject, setSubject] = useState('');
    const [text, setText] = useState('');
    
    const [status, setStatus] = useState(0);
   
return ( 
        <>
            <Form>

            <Row className="mt-3 mb-4">
                <Col>
                    Status
                    <Form.Group>
                        <Form.Check
                                inline
                                label="Active"
                                name="status"
                                type="radio"
                                id={`inline-radio-2`}
                                value="1"      
                                checked={status==="1"}
                                onChange={(e) => setStatus(e.target.value)}
                                
                                
                            />
                        <Form.Check
                                inline
                                label="Inactive"
                                name="status"
                                type="radio"
                                value="0"
                                id={`inline-radio-2`}
                                checked={status==="0"}
                                onChange={(e) => setStatus(e.target.value)}
                                
                            />
                    </Form.Group>
                    <h2 style={{color:"green"}}>{status}</h2>
                </Col>
            </Row>

            <Row>
                <Col md={4} sm={12}>
                    <Form.Group>
                    <Form.Label htmlFor="inputFName" className="col-sm-2 col-form-label">Name</Form.Label>
                    <div className="col-sm-14">
                        <Form.Control type="text" className="form-control" id="inputFName" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    </Form.Group>
                </Col>
                <Col md={4} sm={12}>
                    <Form.Group>
                        <Form.Label htmlFor="inputFName" className="col-sm-2 col-form-label">From</Form.Label>
                        <div className="col-sm-14">
                            <Form.Control type="text" className="form-control" id="inputFName" value={from} onChange={(e) => setFrom(e.target.value)} />
                        </div>
                    </Form.Group>
                </Col>
                <Col md={4} sm={12}>
                    <Form.Group>
                    <Form.Label htmlFor="inputFName" className="col-sm-2 col-form-label">Subject</Form.Label>
                    <div className="col-sm-14">
                        <Form.Control type="text" className="form-control" id="inputFName" value={subject} onChange={(e) => setSubject(e.target.value)} />
                    </div>
                    </Form.Group>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col>
                <Form.Group>
                    <Form.Label htmlFor="inputSName" className="col-sm-2 col-form-label">Text</Form.Label>
                    <div className="col-sm-14">
                        <textarea className="form-control" id="exampleFormControlTextarea1" value={text} onChange={(e) => setText(e.target.value)} style={{height:'300px'}} />'
                    </div>
                </Form.Group>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col>
                    Status
                    <Form.Group>
                    <div className="form-check">
                        <input id="credit" name="paymentMethod" type="radio" className="form-check-input" required="" />
                        <Form.Label className="form-check-label" htmlFor="credit">Active</Form.Label>
                    </div>
                    <div className="form-check">
                        <input id="debit" name="paymentMethod" type="radio" className="form-check-input" required="" />
                        <Form.Label className="form-check-label" htmlFor="debit">Inactive</Form.Label>
                    </div>
                    </Form.Group>
                </Col>
            </Row>            
            <ModuleEditScreenButtons cancelPath={Routes.EmailTemp.path} /> 
            </Form>

        </>

        );
}

export default EmailEditForm;