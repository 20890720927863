import RelationTypeForm from './Form';
import { Col, Row,Alert  } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import useFetch from "../../framework/hooks/useFetch";
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';

const RelationTypeEdit = () => {

    const API_URL = process.env.REACT_APP_API_URL;
    const { id } = useParams();
    const { register, handleSubmit, setValue, getValues ,formState: { errors } } = useForm();

    /* Fetching data and setting the values to the form elements */
    const { data, isPending, isError } = useFetch(API_URL + '/v1/relationtype/data/view/' + id, 'GET', true);
   
    // set the values fetched from database
    setValue("GID", data.GID);
    setValue("RType", data.RType);
    setValue("Desc", data.Desc);    
    setValue("ShowStartDate", data.ShowStartDate);
    setValue("SDLabel", data.SDLabel);
    setValue("SDFormat", data.SDFormat);
    setValue("SDMandatory", data.SDMandatory);
    setValue("ShowEndDate", data.ShowEndDate);    
    setValue("EDLabel", data.EDLabel);    
    setValue("EDFormat", data.EDFormat);
    setValue("EDMandatory", data.EDMandatory);    
    setValue("Status", data.Status);
    setValue("createdBy", data.createdBy);
    setValue("createdAt", data.createdAt);
    setValue("updatedBy", data.updatedBy);
    setValue("updatedAt", data.updatedAt);

    const heading = "Edit relation type";

    const showSave = ((data.GID)? true : false );

    return ( 
            <>
                <ModuleBreadcrumb links={[{ label:"Relation types", linkto: Routes.RelationType.path } , { label: heading } ]}  />
                <h4>{heading}</h4> 
                <Row>
                     <Col xs={12}>
                     {isPending && <div className="alert alert-info" role="alert">Fetching data. Please wait..</div>}                    
                     {isError && <Alert variant="danger">{isError}</Alert>} 
                     {data.GID && <RelationTypeForm id={data.GID} setValue={setValue} getValues={getValues} register={register} handleSubmit={handleSubmit} errors={errors} recordStatus={data.Status} showSave={showSave} recStatus={data.Status} />}
                     </Col>
                </Row>       
        
            </> 
    );
}
 
export default RelationTypeEdit;
