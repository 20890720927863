import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Card, Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';

import { TransactionsTable } from "../framework/components/Tables";

import { useRef } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useHistory } from "react-router-dom";

import useFetch from "../framework/hooks/useFetch";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';


const Transactions = () => {
    const history = useHistory();

    const API_URL = process.env.REACT_APP_API_URL;
    //const { data, isPending, isError } = useFetch(API_URL + '/v1/user/data/list?Page_Limit=3&Page=1', 'POST');
    let obj = useFetch(API_URL + '/v1/user/data/list?Page_Limit=10&Page=1', 'POST');

    console.log(obj.data.total_records);

    const rowData = obj.data.data;
    const gridRef = useRef(null);

    const OnButtonClick = e => {
        const selectedNodes = gridRef.current.api.getSelectedNodes()
        const selectedData = selectedNodes.map(node => node.data)
        const selectedDataStringPresentation = selectedData.map(node => `${node.GID} ${node.FName}`).join(', ')
        alert(`Selected nodes: ${selectedDataStringPresentation}`)

        const path = selectedData.map(node => `${node.GID}`);
        console.log(path.length);
        if (path.length > 0) {
            history.push("/User/" + path)
        }

        //obj = useFetch(API_URL + '/v1/user/data/list?Page_Limit=3&Page=2', 'POST');
    }

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item>Volt</Breadcrumb.Item>
                        <Breadcrumb.Item active>Transactions</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Transactions</h4>
                    <p className="mb-0">Your web analytics dashboard template.</p>
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <ButtonGroup>
                        <Button variant="outline-primary" size="sm">Share</Button>
                        <Button variant="outline-primary" size="sm">Export</Button>
                    </ButtonGroup>
                </div>
            </div>

            <div className="table-settings mb-4">
                <Row className="justify-content-between align-items-center">
                    <Col xs={8} md={6} lg={3} xl={4}>
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                            <Form.Control type="text" placeholder="Search" />
                        </InputGroup>
                    </Col>
                    <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                                <span className="icon icon-sm icon-gray">
                                    <FontAwesomeIcon icon={faCog} />
                                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                                <Dropdown.Item className="d-flex fw-bold">
                                    10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                                </Dropdown.Item>
                                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            </div>

            <TransactionsTable />

            <hr />

            <div className="users-page">

                <h1>Users page</h1>
                {obj.isError && <div className="alert alert-danger" role="alert">{obj.isError}</div>}
                {obj.isPending && <div>Loading...</div>}
                {obj.data &&
                    <>
                        <Card border="light" className="table-wrapper table-responsive shadow-sm">
                            <Card.Body className="pt-0">

                                <button onClick={OnButtonClick}>Edit selected record</button>
                                <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>

                                    <AgGridReact
                                        className="user-table align-items-center"
                                        ref={gridRef}
                                        rowData={rowData}
                                        rowSelection="single">
                                        <AgGridColumn headerName="Group A">
                                            <AgGridColumn sortable={true} filter={true} field="FName" checkboxSelection={true}></AgGridColumn>
                                            <AgGridColumn sortable={true} filter={true} field="SName"></AgGridColumn>
                                        </AgGridColumn >
                                        <AgGridColumn headerName="Group B">
                                            <AgGridColumn sortable={true} filter={true} field="Email"></AgGridColumn>
                                            <AgGridColumn flex={1}
                                                field="Action"
                                                minWidth={175}
                                            ></AgGridColumn>
                                        </AgGridColumn >
                                    </AgGridReact>
                                </div >
                            </Card.Body>
                        </Card>
                    </>
                }
            </div>
        </>
    );
};

export default Transactions;