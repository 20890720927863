import React,{useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form,Alert, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { PasswordCheck, passRules} from "../../framework/utilities/utilities";
import { Link, useParams } from 'react-router-dom';
import { Routes } from "../../routes";
import { useForm } from "react-hook-form";

const ResetPassword = () => {
    const { token } = useParams();
    console.log(token );
    const { register, handleSubmit, setValue  } = useForm();
    const [successMessage,setSuccessMessage] = useState('');
    const [errMessage,setErrorMessage] = useState('');    
    const API_URL = process.env.REACT_APP_API_URL;
    setValue("email","");
    setValue("password","");
    setValue("confirmPassword","");
    const requestPass = (token,postedData) => {

        let formData = new FormData();
        formData.append("email", postedData.email);
        formData.append("token", token);
        formData.append("password", postedData.password);
        formData.append("password_confirmation", postedData.confirmPassword);
        
        fetch(API_URL + '/v1/user/password/reset', {
            method: 'POST',           
            body: formData
        })
        .then(response => response.json())
        .then(jsondata => {            
            if (jsondata.id){
                setSuccessMessage("Password updated successfully.");
            }else{
                setErrorMessage("Password could not be updated.");                                      
            }
           
            
        }).catch((err) => {
            console.log("Login error: " + err);
            setErrorMessage("Password could not be set.");
            
        });
        
        
    }

        const onSubmit = postedData => {
        fetch(API_URL + '/v1/user/password/find/'+token, {
            method: 'GET'                      
        })
        .then(response => response.json())
        .then(jsondata => {
            if (jsondata.message === "success"){
                requestPass(token,postedData);
            }else{
                setErrorMessage("Please check the URL.");
            }     
            
        }).catch((err) => {
            console.log("Login error: " + err);
            setErrorMessage("Please check the URL.");
        });
            
        if(!PasswordCheck(postedData.password))
        {
            setErrorMessage(passRules());
            return false;
        }
        else if(postedData.password !== postedData.confirmPassword)
        {
            setErrorMessage('Password and confirm password do not match');
            return false;
            
        }
    
    
    }
    return (
        <main>
            <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
                <Container className="p-6">
                    <Row className="justify-content-center">
                        <p className="text-center">
                            <Card.Link as={Link} to={Routes.Signin.path} className="text-gray-700">
                                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to sign in
                            </Card.Link>
                        </p>
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                <h3 className="mb-4">Reset password</h3>

                                {successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}            
                                {errMessage && <Alert variant="danger">{errMessage}</Alert>}
                                 <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <Form.Group id="email" className="mb-4">
                                                <Form.Label htmlFor="email" className="required">Your email</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faEnvelope} />
                                                    </InputGroup.Text>
                                                    <Form.Control autoFocus required {...register("email")} type="email" placeholder="example@company.com" />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <Form.Group id="password" className="mb-4">
                                                <Form.Label htmlFor="password" className="required">Your password</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faUnlockAlt} />
                                                    </InputGroup.Text>
                                                    <Form.Control required {...register("password")} type="password" placeholder="Password" />
                                                    </InputGroup>
                                                </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <Form.Group id="confirmPassword" className="mb-4">
                                                <Form.Label htmlFor="confirmPassword" className="required">Confirm password</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faUnlockAlt} />
                                                </InputGroup.Text>
                                                <Form.Control required {...register("confirmPassword")} type="password" placeholder="Confirm Password" />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} className="mb-3 password-rules-box"  >{passRules()}</Col>    
                                    </Row>
                                    <Button variant="primary" type="submit" className="mt-3 w-100">
                                        Reset password
                                    </Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};

export default ResetPassword;
