import UserRoleForm from './Form';
import { Col, Row } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { useForm } from "react-hook-form";

const UserRoleAdd = () => {

    const heading = "Add new user role";
    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm();
    setValue("RName", '');
    setValue("Status", 0);

    const showSave = true;
    const recStatus = 0;

    return ( 
        <>
            <ModuleBreadcrumb links={[{ label:"User roles", linkto: Routes.UserRole.path } , { label: heading } ]}  />
            <h4>{heading}</h4>          
            <Row>
                <Col xs={12}>
                    <UserRoleForm setValue={setValue} getValues={getValues} register={register} handleSubmit={handleSubmit} errors={errors} showSave={showSave} recStatus={recStatus} />
                </Col>
            </Row>       
        
        </> 
    );
}
 
export default UserRoleAdd;