import { Row , Col } from '@themesberg/react-bootstrap';


export default function ActivityLog(props = {}) {
   
    return (
        <Row>
            <Col>
                {props.ownerInfo.createdBy && `Created by ${props.ownerInfo.createdBy} ${props.ownerInfo.createdAt}`}
                <br/>
                {props.ownerInfo.updatedBy && `Updated by ${props.ownerInfo.updatedBy} ${props.ownerInfo.updatedAt}`}
                {props.moduleOtherInfo && <><br/>{props.moduleOtherInfo}</>}
            </Col>
        </Row>
    );
};
