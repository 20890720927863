import { useState } from "react";

const DynamicField = () => {
    const [inputList, setInputList] = useState([
        { firstName: "", lastName: "" },
        { firstName: "", lastName: "" }
    ]);

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { firstName: "", lastName: "" }]);
    };


    return (
        <>
            {inputList.map((x, i) => {
                return (
                    <div className="row  mb-3" key={i}>
                        <div className="col-sm-12 col-md-4">
                            <input
                                className="form-control"
                                name="firstName"
                                placeholder="Skill name"
                                value={x.firstName}
                                onChange={e => handleInputChange(e, i)}
                            />
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <input
                                className="form-control"
                                name="lastName"
                                placeholder="Skill percentage %"
                                value={x.lastName}
                                onChange={e => handleInputChange(e, i)}
                            />
                        </div>
                        <div className="col-sm-12 col-md-4">
                            {inputList.length !== 1 && <button className="btn btn-primary me-3" onClick={() => handleRemoveClick(i)}>Remove</button>}
                            {inputList.length - 1 === i && <button className="btn btn-primary" onClick={handleAddClick}>Add</button>}
                        </div>
                    </div>
                );
            })}
            <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div>
        </>
    );
}

export default DynamicField;