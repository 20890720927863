import RelationEditForm from './Form';
import { Col, Row, Alert } from '@themesberg/react-bootstrap';
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { Routes } from "../../routes";
import { useForm } from "react-hook-form";
import useFetch from "../../framework/hooks/useFetch";

const RelationAdd = () => {
    const heading="Add new relation";

    const { register, handleSubmit, setValue, getValues, errors } = useForm();   
    
    const API_URL = process.env.REACT_APP_API_URL;
    const { data, isPending, isError } = useFetch(API_URL + '/v1/relation/data/addnew', 'GET', true);

    let sessionUser = JSON.parse(localStorage.getItem("profile"));

    setValue("GID", '');
    setValue("UId",'');
    setValue("rUserId", '');
    setValue("relationType", 3);
    setValue("source", 'Entry by admin - ' + sessionUser.FName + ' ' + sessionUser.SName + '('+ sessionUser.Email +')');
    setValue("notes", '');
    setValue("paperType", 0);
    setValue("dateStartYear", null);
    setValue("dateStartMonth", null);
    setValue("dateEndYear", null);
    setValue("dateEndMonth", null);
    setValue("Status", 1); // by default we set state as Validated
    setValue("paperTypes", data.paperTypes);
    setValue("relationTypes", data.relationTypes);
    setValue("publicationId", 0);   
    setValue("allPublications", data.allPublications);
    setValue("oldPublicationName", '');
    setValue("usersDrp", data.usersDrp);
    setValue("authorName", "");
    setValue("relAuthorName", "");
    setValue("startDateMand",0);
    setValue("endDateMand",0);
    setValue("startDateLabel","");
    setValue("endDateLabel","");
    setValue("institutionDrp",data.institutionDrop);
    setValue("rInstitutionId" , 0);
    setValue("publicationDBLPUrl",'');
    setValue("DBLPImportedEntry",0);
    setValue("communityPaperId", 0);

    const showSave = true;
    const recStatus = 1;

    return ( 
        <>
            <ModuleBreadcrumb links={[{ label:"Relations", linkto: Routes.Relations.path } , { label: heading } ]}  />
            <Row>
                <h4>Add relation details</h4>
            </Row>
            <Row>
                <Col xs={12}>
                    {isPending && <div className="alert alert-info" role="alert">Fetching data. Please wait..</div>}                    
                    {isError && <Alert variant="danger">{isError}</Alert>} 
                    {data.paperTypes && <RelationEditForm   setValue={setValue} getValues={getValues} register={register} handleSubmit={handleSubmit} errors={errors}  showSave={showSave} recStatus={recStatus} />}
                </Col>
            </Row>       
        
        </> 
    );
}
 
export default RelationAdd;

