import InstitutionEditForm from './Form';
import { Col, Row } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import { useForm } from "react-hook-form";
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';

const InstitutionAdd = () => {

    const heading = "Add new institution";
    const { register, handleSubmit, setValue , getValues, formState: { errors }} = useForm();
    setValue("IName", '');
    setValue("Status", 0);

    const showSave = true;
    const recStatus = 1;
    return ( 
        <>
        <ModuleBreadcrumb links={[{ label:"Institutions", linkto: Routes.Institution.path } , { label: heading } ]}  />        
        <h4>{heading}</h4>
        <Row>  
            <Col xs={12}>
                <InstitutionEditForm setValue={setValue} getValues={getValues} register={register} handleSubmit={handleSubmit} errors={errors} showSave={showSave} recStatus={recStatus} />
            </Col>
        </Row>       
        
        </>
    );
}
 
export default InstitutionAdd;
