import './BgAnimation.scss';

const BgAnimation = ({ color1, color2, bgImg = "", animation }) => {

    return (
        <div className="bg-animation" style={{ "--color1": color2, "--color2": color1 }} >
            <div className="area" style={{ backgroundImage: `url(${bgImg})` }} >

                <ul className="circles" style={{ display: animation }}>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>

            </div >
        </div >
    );
}

export default BgAnimation;