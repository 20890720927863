import React,{useState} from "react";
import { Col, Row, Form, Alert,Card, Button } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import { useParams, useHistory  } from "react-router-dom";
//import { saveFormRecord } from "../../framework/utilities/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes, faEye , faLink} from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

const RegistrationForm = (props) => {

    const { id } = useParams();
    const register = props.register;
    const handleSubmit = props.handleSubmit;

    const getValues = props.getValues;
    const userMessage = props.userMessage;
    const adminCheck = props.adminCheck;
        
    const [successMessage,setSuccessMessage] = useState('');
    const [errMessage,setErrorMessage] = useState('');

    const history = useHistory();

    
    const onLinkToExisting  = (postedData, linkToUserId) => {

        let formData = new FormData();

        formData.append("GID", id);
        formData.append("FName", postedData.FName);
        formData.append("SName", postedData.SName);
        formData.append("Email", postedData.Email);
        formData.append("dblp_url", postedData.dblp_url);
        formData.append("pwd", getValues('pwd'));
        formData.append("Status", 1);
        formData.append("updateId", linkToUserId);

        setErrorMessage(''); 
        setSuccessMessage('');

        const API_URL = process.env.REACT_APP_API_URL;
        fetch(API_URL + '/v1/user-registration/data/save', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
            body: formData
        }).then(response => response.json())
        .then(jsondata => {           
            if ((jsondata.GID) && (jsondata.message === 'UPDATED')){
                setSuccessMessage('User updated successfully');
                setTimeout(function(){                    
                    history.push(Routes.userRegistration.path);
                },1000); 
            }else if ((jsondata.GID) && (jsondata.message === 'CREATED')){
                setSuccessMessage('User created successfully');
                setTimeout(function(){
                    history.push(Routes.userRegistration.path);
                },1500); 
            }else if (jsondata.message){
                setErrorMessage(jsondata.message); 
            }
        }).catch((err) => {
            console.log("Login error: " + err);
        });        

    }



    const onSubmit = postedData => {

        setErrorMessage(''); 
        setSuccessMessage('');

        let formData = new FormData();
        if(id !== undefined){
            formData.append("GID", id);
        }

        formData.append("FName", postedData.FName);
        formData.append("SName", postedData.SName);
        formData.append("Email", postedData.Email);
        formData.append("dblp_url", postedData.dblp_url);
        formData.append("pwd", getValues('pwd'));
        formData.append("optedAt", getValues('opted_at'));
        formData.append("Status", 1);
        
        const API_URL = process.env.REACT_APP_API_URL;
        fetch(API_URL + '/v1/user-registration/data/save', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
            body: formData
        }).then(response => response.json())
        .then(jsondata => {           
            if ((jsondata.GID) && (jsondata.message === 'UPDATED')){
                setSuccessMessage('User updated successfully');
                setTimeout(function(){                    
                    history.push(Routes.userRegistration.path);
                },1000); 
            }else if ((jsondata.GID) && (jsondata.message === 'CREATED')){
                setSuccessMessage('User created successfully');
                setTimeout(function(){
                    history.push(Routes.userRegistration.path);
                },1500); 
            }else if (jsondata.message){
                setErrorMessage(jsondata.message); 
            }
        }).catch((err) => {
            console.log("Login error: " + err);
        });

        
        
        
    }


    return (
            <>
                {successMessage && <Alert variant="success">{successMessage}</Alert>}            
                {errMessage && <Alert variant="danger">{errMessage}</Alert>}      
                <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col md={4} className="mb-3">
                            <Form.Group id="FName">
                                <Form.Label className="required">First name</Form.Label>
                                <Form.Control type="text" placeholder="Enter your first name" {...register("FName")} />
                            </Form.Group>
                        </Col>
                        <Col md={4} className="mb-3">
                            <Form.Group id="SName">
                                <Form.Label className="required">Last name</Form.Label>
                                <Form.Control  type="text" placeholder="Enter your last name" {...register("SName")} />
                            </Form.Group>
                        </Col> 
                        <Col md={4} className="mb-3">
                            <Form.Group id="Email">
                                <Form.Label className="required">Email</Form.Label>
                                <Form.Control  type="email" placeholder="example@gmail.com" {...register("Email")} />
                            </Form.Group>
                        </Col>
                    </Row> 
                    <Row>
                    <Col md={4} className="mb-3">
                        <Form.Group id="dblp_url">
                            <Form.Label>DBLP profile URL (e.g: https://dblp.org/pid/78/50)</Form.Label>
                            <Form.Control  type="text" placeholder=""  {...register("dblp_url")} />
                        </Form.Group>
                    </Col> 
                </Row>  
                
                <Card.Footer>
                    <Row>   
                        <Col>
                            
                 
                            { (adminCheck === 1) && 
                                <Row className="mt-3 pt-1"  >
                                    <Col className="callout callout-danger text-danger">{ userMessage[0]['message'] }</Col>
                                </Row>
                            }

                            {/*{ (adminCheck === 0) && userMessage && <h2 className="text-center"> OR </h2> }*/}
                            { (adminCheck === 0) && userMessage && (userMessage.length > 0) && 
                                <><p className="text-center">NOTE: Linking a user will result in overwriting the existing database user First name, Last name, Email and DBLP URL with the submitted data in this form.</p>
                                
                                {(userMessage.length > 1) && <p>The linking buttons are disabled since we cannot have duplicate emails / dblp URLs in users database.</p>}
                                </> 
                            }

                            {   
                                (adminCheck === 0) && userMessage && userMessage.map((a, i) => {
                                    return (
                                        <div key={i} className="callout callout-danger text-danger">
                                            <span>{a.message}</span>
                                            <br/>
                                            <a className="animate-hover  btn btn-sm btn-primary" href={`#/users/edit/${a.user_id}`}  rel="noreferrer" target="_blank"  >
                                                <FontAwesomeIcon icon={faEye} className="animate-left-3 me-3 ms-2" />
                                                View
                                            </a>
                                            <Button className="animate-hover btn btn-sm btn-addnew " name="user_id" value={a.user_id} onClick={  
                                                handleSubmit((values) => {                                     
                                                    onLinkToExisting(values,a.user_id);
                                                })
                                                }
                                                disabled = {(userMessage.length > 1)? true : false}
                                            >
                                                    <FontAwesomeIcon icon={faLink} className="animate-left-3 me-3 ms-2" />
                                                    Link to {a.user_name}
                                            </Button>

                                        </div>
                                        );
                                    
                                })
                                
                            }
                            
                            { ( (!adminCheck  && ((adminCheck === 0)  )) ) ? 
                
                                <div className="mt-3 d-flex justify-content-end">
                                    {/** We disable the add new when dblp url/email already matches an existing user */}
                                    {(getValues('hideCreateNew') === 0) && 
                                        <Button variant="primary" type="submit" className="animate-hover"  ><FontAwesomeIcon  className="animate-left-3 me-3 ms-2" icon={faSave} />Create new user</Button>
                                    }

                                    <Button as={Link} className="animate-hover btn btn-sm btn-secondary" to={Routes.userRegistration.path}>
                                        <FontAwesomeIcon icon={faTimes} className="animate-left-3 me-3 ms-2" />
                                        Cancel
                                    </Button>
                                </div>
                                :
                                <div className="mt-3 d-flex justify-content-end">
                                    <Button as={Link} className="animate-hover btn btn-sm btn-secondary" to={Routes.userRegistration.path}>
                                        <FontAwesomeIcon icon={faTimes} className="animate-left-3 me-3 ms-2" />
                                        Cancel
                                    </Button>
                                </div>
                    
                            }   

                        </Col>
                    </Row>  
                </Card.Footer>
            
                </Form>

            </>
      );
}
 
export default RegistrationForm;
