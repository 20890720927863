import RelationTypeForm from './Form';
import { Col, Row } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { useForm } from "react-hook-form";

const RelationTypeAdd = () => {

    const heading = "Add new relation type";
    const { register, handleSubmit, setValue, getValues} = useForm();    
    setValue("RType", '');
    setValue("Desc", '');
    setValue("ShowStartDate", 0);
    setValue("SDLabel", '');
    setValue("SDFormat", 0);
    setValue("SDMandatory", 0);
    setValue("ShowEndDate", 0);    
    setValue("EDLabel", '');    
    setValue("EDFormat", 0);
    setValue("EDMandatory", 0);    
    setValue("Status", 0);

    const showSave = true;
    const recStatus = 1;
    
    return ( 
            <>
                <ModuleBreadcrumb links={[{ label:"Relation types", linkto: Routes.RelationType.path } , { label: heading } ]}  />
                <h4>{heading}</h4>                
                <Row>
                    <Col xs={12}>
                        <RelationTypeForm id={0} setValue={setValue} register={register} handleSubmit={handleSubmit} getValues={getValues} showSave={showSave} recStatus={recStatus} />
                    </Col>
                </Row> 
            </> 
    );
}
 
export default RelationTypeAdd;
