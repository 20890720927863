export const InstitutionCheck = (param) => {    

    let status = false;
    if (param.length){
        param.map((x, i) => { 
            
            if(x.institutionId !== '')
            {
                if(x.startDate  === ''  ||  (x.current === 0 && x.endDate === ''))
                {
                    status = true;
                    return status;
                }
            }
            return status;
        });
       
    }
    return status;
  
}

export function statusPill(status){
    return (
        <>
        {(status === 1) && <span className="badge rounded-pill bg-success">Validated</span>}
        {(status === 0) && <span className="badge rounded-pill bg-danger">Inactive</span>}
        {(status === 2) && <span className="badge rounded-pill bg-danger">Set for deletion</span>}
        {(status === 3) && <span className="badge rounded-pill bg-danger">Pending</span>}
        {(status === 4) && <span className="badge rounded-pill bg-danger">Disputed</span>}
        
        </>
    )
}