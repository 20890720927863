import { Col, Row } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Routes } from "../../routes";
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';

const EmailTempList = () => {
    
    const heading = "Email templates";

    return ( 
        <>
        <ModuleBreadcrumb links={[ { label: heading } ]}  />
        <h4>{heading}</h4>
        <Row >
            <Col xs={12}>
                <Link to={Routes.EmailTemp.addpath} className="btn btn-sm btn-primary mb-2">Add new template</Link>
            </Col>
        </Row>
        
        <Row className="mt-3">
            <Col xs={12}>
                
                Grid is pending...

            </Col>
        </Row>       
        
        </> 
    );
}
 
export default EmailTempList;

