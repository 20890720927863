import React, { useState, useEffect, Fragment } from "react";
import { Col, Row, Form, Alert,  Button, Spinner } from '@themesberg/react-bootstrap';
import Select from 'react-select'
import DynamicInstitutions from './DynamicInstitutions';
import { Routes } from "../../routes";
import ModuleEditScreenButtons  from "../../project/components/ModuleEditScreenButtons";
import { useParams, useHistory , Link } from "react-router-dom";
import moment from "moment-timezone";
import { InstitutionCheck } from "./Users";
import { PasswordCheck, passRules } from "../../framework/utilities/utilities";
//import useFetch from "../../framework/hooks/useFetch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt,  faLock , faPaperPlane, faUser, faKey,  faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
//import { AgGridReact } from 'ag-grid-react';
import ConfirmDeleteModal from '../../project/components/ConfirmDeleteModal';
import { scrollToTop } from "../../framework/utilities/utilities";
import { faInfoCircle, faEye } from "@fortawesome/free-solid-svg-icons";
import { statusPill } from "./Users";
import ExpandableBlock from "../../framework/components/ExpandableBlock/ExpandableBlock";
import ConfirmModal from "./ConfirmModal";

const UserEditForm = (props) => {

    const { id } = useParams();
    const register = props.register;
    const handleSubmit = props.handleSubmit;
    const setValue = props.setValue;
    const getValues = props.getValues;
    //const errors = props.errors;
    
    const API_URL = process.env.REACT_APP_API_URL;

    ///////// Getting relations ///////////
    //const [gridApi, setGridApi] = useState(null);
    let [modalShow, setModalShow] = useState(false);
    let [delRecId, setDelRecId] = useState(0);
    let [delRecTitle, setDelRecTitle] = useState('');
    let [DelType, setDelType] = useState(0); // 0 others, 1 - pending relation delete
    const [startSave, setStartSave] = useState(false);
    const [showRoleField, setShowRoleField] = useState(getValues('UType'));
    const [inviteButtonClicked, setInviteButtonClicked] = useState(0);
    const pubTitleArr = [ { 3: "Paper name" , 6: "Award name" , 7: "Award name" }];
    const pubCoAuthorTitleArr = [ { 3: "Co-Authors" , 6: "Co-awardee" , 7: "Co-awardee" }];
    let [confirmModalShow, setConfirmModalShow] = useState(false);

    // this actually is of no use.. because when we select publictaion a new call is made. But could't see how to do away with this as we need to show empty grid at the start.
    //let relationsObj = useFetch(API_URL + '/v1/relation/data/list?Page_Limit=&Page=1&UserId='+ ((id > 0)? id : 0), 'POST');

    //let publicationObj = useFetch(API_URL + '/v1/relation/data/linkedPublications?UserId='+ ((id > 0)? id : 0), 'POST');
    
    //const [rowData,setRowData] = useState(relationsObj.data);

    //const gridRef = useRef(null);
    
   /* const columns =[
        {headerName:"Author",field:"AName",sortable:true,filter:true,flex: 1},    
        {headerName:"Related to",field:"RelAName",sortable:true,filter:true,flex: 1},    
        {headerName:"Type",field:"relationTypeName",sortable:true,filter:true,flex: 1}, 
        {headerName:"Paper type",field:"paperTypeName",sortable:true,filter:true,flex: 1}, 
        {headerName:"Status",field:"Status",sortable:true,filter:true,flex: 2, minWidth:100,maxWidth:110,cellRenderer: params => {
            return StatusInGrid({value:params.value, custom_status:[ {value:'1',label: 'Validated', className:'bg-success'}, {value:'3',label: 'Pending', className:'bg-danger'} , {value:'4',label: 'Disputed', className:'bg-danger'} ]});                    
            } 
        },
        {headerName:"",field:"GID",flex: 2,minWidth:50,maxWidth:50,
        cellRendererFramework:(params)=><div>   
            <span onClick={()=>editBtn(params)} title="Edit" className="grid-action-btns text-success link"><FontAwesomeIcon icon={faEdit} className="me-2" /></span>                     
        </div>},
        {headerName:"",field:"GID",flex: 2,minWidth:50,maxWidth:50,
        cellRendererFramework:(params)=><div>               
            <span onClick={()=> { deleteGridBtn(params);}}title="Delete" className="grid-action-btns text-danger link"><FontAwesomeIcon icon={faTrashAlt} className="me-2" /></span>  
        </div>}      
    ];
*/
    const handleModelClose = () => {
        setModalShow(false);
    }

    /*const editBtn=(params)=>{
        history.push(`${Routes.Relations.editpath.replace(':id',params.value)}`);
    }*/

    const deleteParentRelationBtn=(params)=>{              
        setDelRecId(params.value);
        setModalShow(true);
        setDelType(0);
        setDelRecTitle("'"+params.data.PName + " - "+ params.data.relationTypeName + "'");
    }

    const deletePendingRelationBtn=(params)=>{              
        setDelRecId(params.value);
        setDelType(1);
        setModalShow(true);
        setDelRecTitle("'"+params.data.RName + "'");
    }

    /*const deleteGridBtn=(params)=>{           
        setDelRecId(params.value);
        setModalShow(true);
        setDelType(0);
        setDelRecTitle("'"+params.data.AName + "' with '" + params.data.RelAName + "' as '"+ params.data.relationTypeName + "'");
    }*/

    const handleOnDeleteSubmitted = () => {
        let delUrl = '/v1/relation/data/delete/';
        if(DelType === 1)
        {
            delUrl = '/v1/relation_pending/data/delete/';
        }
        fetch(API_URL + delUrl + delRecId, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
        }).then(response => response.json())
        .then(jsondata => {           
            handleModelClose(false);      
            //const selectedData = gridApi.getSelectedRows();
            //gridApi.applyTransaction({ remove: selectedData });
            window.location.reload();    
           //return false;
        }).catch((err) => {
            console.log("Login error: " + err);
        });
    };
    
    /*const onGridReady = (params) => {
        setGridApi(params.api);        
    };*/


    //console.log(localStorage);
    ///////// Getting relations ///////////

    const ownerInfo = { 
        'createdBy' :  getValues('createdBy'),
        'createdAt' : getValues('createdAt'),
        'updatedBy' :  getValues('updatedBy'), 
        'updatedAt' : getValues('updatedAt') 
    }; 

    
    const [successMessage,setSuccessMessage] = useState('');
    const [errMessage,setErrorMessage] = useState('');
    const [UType, setUType] = useState(getValues('UType'));
    const [UTypeName, setUTypeName] = useState(getValues('UTypeName'));
   
    const history = useHistory();
    
    const [status, setStatus] = useState(props.recStatus);

    const [locked, setLockedStatus] = useState(props.lockStatus);

    const [userRoleId, setUserRoleId] = useState(props.userRoleId);
    const [userRoleName, setUserRoleName] = useState(props.userRoleName);
    const [dblpId, setdblpId] = useState(props.dblpId);

    const institutionData = props.institutionAddData;

    //console.log('institutionData form');
    //console.log(institutionData);

    const [inputList, setInputList] = useState([       
        { institutionId:"", institutionLabel: "",positionId:"", positionLabel: "", instOtherName: "", startDate: moment(new Date()).format("YYYY-MM-DD")  , formatedStartDt:  new Date() , endDate: moment(new Date()).format("YYYY-MM-DD") , formatedEndDt:  new Date() , current: 0, position: 0,  startDateLabel: "", endDateLabel: "" },        
    ]);

    const getDblpId = (value) => {
        
        
        if(value.includes("dblp.org") && value.includes(".htm"))
        {
            const first_split = value.split('.org/pid/')[1];
            var second_split = first_split.split('.htm')[0];
            second_split = second_split.replace('.html','');
            second_split = second_split.replace('.htm','');
            setdblpId(second_split);
        }else if(value.includes("dblp.org") && !value.includes(".html"))
        {

            const first_split = value.split('.org/pid/')[1];
            console.log(first_split);
            setdblpId(first_split);
        }
        else
        {
            setdblpId('');
        }
    };

   
    const moduleOtherInfoSet = () => {
        return (
            <>
                <p><strong><FontAwesomeIcon icon={faKey} className="me-3 ms-2" />Password set:</strong> {(getValues('pwdSet') === 'Y') ? 'Yes' : 'No'}<br/>
                <strong><FontAwesomeIcon icon={faUser} className="me-3 ms-2" /> Fake user:</strong> {(getValues('fakeUser') === 'Y') ? 'Yes' : 'No'}<br/>
                <strong><FontAwesomeIcon icon={faPaperPlane} className="me-3 ms-2" />Invite sent:</strong> {(getValues('inviteSent') === 'Y') ? 'Yes' : 'No'} 
                { ((getValues('inviteSentAt')) && (getValues('inviteSent') === 'Y'))  ? "  "+getValues('inviteSentAt') : '' }<br/>
                <strong><FontAwesomeIcon icon={faLock} className="me-3 ms-2" /> Locked account:</strong> {(getValues('locked') === 'Y') ? 'Yes' : 'No'}<br/>
                </p>
            </>
        );

    }
    
    const onSubmit = postedData => {
        setErrorMessage('');
        setStartSave(true);

        //console.log(JSON.stringify(inputList));
        //return false;
        let formData = new FormData();
        if(id !== undefined){
            formData.append("GID", id);
            
            if(InstitutionCheck(inputList))
            {
                setStartSave(false);
                scrollToTop();
                setErrorMessage('Please ensure all required fields of the Institution affiliations is filled up.');
                return false;  
            } 
           
        }
        else if(id === undefined)
        {
            if(!PasswordCheck(postedData.pwd))
            {
                setStartSave(false);                
                setErrorMessage(passRules());
                scrollToTop();
                return false;
            }
            else if(postedData.pwd !== postedData.confirm_pwd)
            {
                setStartSave(false);
                setErrorMessage('Password and confirm password do not match');
                scrollToTop();
                return false;
            }
            formData.append("pwd", postedData.pwd);
            
        }
        
       
        formData.append("FName", postedData.FName);
        formData.append("Email", postedData.Email);
        formData.append("SName", postedData.SName);
        formData.append("secEmail", ((postedData.secEmail) ? postedData.secEmail : ''));

        formData.append("oName1", ((postedData.oName1) ? postedData.oName1 : ''));
        formData.append("oName2", ( (postedData.oName2) ? postedData.oName2 : '' ));
        formData.append("dblp_url", ((postedData.dblp_url) ? postedData.dblp_url : ''));

        formData.append("UType", postedData.UType);
        formData.append("uRole", postedData.uRole);
        formData.append("source_type", 2);
    
        formData.append("dblpId", ((dblpId) ? dblpId : ''));
        formData.append("Status", status);
        formData.append("locked", locked);

        formData.append("institutionDetails", JSON.stringify(inputList));
       
        //console.log(formData);
        
        fetch(API_URL + '/v1/user/data/save', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
            body: formData
        })
        .then(response => response.json())
        .then(jsondata => {
            //console.log("success");
           // console.log(jsondata);
           setStartSave(false);
            if ((jsondata.GID) && (jsondata.message === 'UPDATED')){
                scrollToTop();
                setSuccessMessage("Record updated successfully.");
                setTimeout(function(){      
                    window.location.reload();       
                    //history.push(Routes.Users.path);  
                },1000); 
            }else if ((jsondata.GID) && (jsondata.message === 'CREATED')){
                scrollToTop();
                setSuccessMessage("Record created successfully.");
                setTimeout(function(){
                    history.push(Routes.Users.editpath.replace(':id',jsondata.GID));
                },1000);                
            }else if (jsondata.errors){
                scrollToTop();
                setErrorMessage(jsondata.message + "\r\n" + JSON.stringify(jsondata.errors));                       
            }                   
        }).catch((err) => {
            scrollToTop();
            setStartSave(false);
            console.log("Login error: " + err);
        });
       
    }

    const [selectUserRoleOptions,setSelectUserRoleOptions] = useState([]);
    
    useEffect(()=>{
        const tmp = [];
        const API_URL = process.env.REACT_APP_API_URL;
        fetch(API_URL + '/v1/userroles/data/list/userRoleDrp', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            }
        }).then(response => response.json())
        .then(jsondata => {
    
            //console.log("response",jsondata);
                Object.keys(jsondata).forEach(key => {
                    tmp.push({
                        value: key,
                        label: jsondata[key]
                    });
                });
                setSelectUserRoleOptions(tmp);
            }).catch((err) => {
                console.log("Login error: " + err);
        });
    },[]);


    
    

    


    /*function fillData(btnId, gridId,publicationId){
       
        if (btnId){
            // remove classes of all buttons
            var els = document.getElementsByClassName("btn-outline-sidetabs");
            for(var i = 0; i < els.length; i++)
            {
                els[i].classList.remove('btn-outline-sidetabs-active');
            }            
            document.getElementById(btnId).classList.add('btn-outline-sidetabs-active');
        }


        fetch(API_URL + '/v1/relation/data/list?Page_Limit=&Page=1&UserId='+ ((id > 0)? id : 0)+"&forcePubCheck=1&PubId="+((publicationId > 0) ? publicationId : 0), {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            }
        }).then(response => response.json())
        .then(jsondata => {
            setRowData(jsondata.data);                         
            }).catch((err) => {
                console.log("Login error: " + err);
        });

        
    }*/

    const onSendInvite = () => {
        let formData = new FormData();
        setSuccessMessage('');
        setErrorMessage('');
        setInviteButtonClicked(3); // sending

        if(id !== undefined){
            formData.append("UID", id);

            fetch(API_URL + '/v1/user/send_invitemail_singleuser', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("token"),
                },
                body: formData
            }).then(response => response.json())
            .then(jsondata => {
                if (jsondata.message){
                    setSuccessMessage(jsondata.message);
                    setInviteButtonClicked(1); // sent
                }else{
                    setErrorMessage("Could not send the invite. Please try again later.");
                    setInviteButtonClicked(2); // failed
                }
                scrollToTop();
            }).catch((err) => {
                console.log("Login error: " + err);
                setErrorMessage("Could not send the invite. Please try again later.");
                setInviteButtonClicked(2); // failed
                scrollToTop();
            });

        }
        
    }

    const editLink = "/relation/edit";
    const editLinkPendingEmployment = "/pending-relations/modify";

  //  const coAutherRelationTypes = [3,6,7];

    const [listError, setListError] = useState();
    //const [listReady, setListReady] = useState(false);
    const [relationTypesData, setRelationTypesData] = useState([]);
    //const [relationInstitutionData, setRelationInstitutionData] = useState([]);
   // const [totalRelations, setTotalRelations] = useState(0);
        
    useEffect(()=>{
        const API_URL = process.env.REACT_APP_API_URL;
        const coAutherRelationTypes = [3,6,7];

        const tmp = [];
        // lets fetch the relations
        let formData = new FormData();

        // we will only send the selected rules to api
        formData.append("userId", id);
        formData.append("pageName", 'relationListing');

        fetch(API_URL + '/v1/relations/data/listRelation', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
            body: formData
        }).then(response => response.json())
        .then(jsondata => {
            //console.log(jsondata);
            /*if (jsondata === 'Unauthorized'){
                Logout();
            }*/
            //console.log("jsondata",jsondata);
            // converting relation types json to array format
            if (jsondata.errors){
                setListError(jsondata.errors);
            }else{
               // console.log(jsondata);
                //setTotalRelations(jsondata.tot_relations);
                //if (jsondata.institution_list) setRelationInstitutionData(jsondata.institution_list);
                Object.keys(jsondata.relation_types).forEach(key => {
                    // for co-authors we need to loop one more level
                    if (coAutherRelationTypes.includes(Number(key))) {                    
                        const tmp_rels = [];  
                        Object.keys(jsondata.relations[key]).forEach(key2 => {
                            tmp_rels.push({
                                value: key2,
                                publication: jsondata.relations[key][key2]
                            });
                            //console.log(tmp_rels[0]['publication']['publication_years_coauthors']);
                        });
    
                        tmp.push({
                            value: key,
                            relation_type_name: jsondata.relation_types[key].name,
                            relation_type_description: jsondata.relation_types[key].description,     
                            show_start_date_label:jsondata.relation_types[key].show_start_date_label,
                            show_end_date_label:jsondata.relation_types[key].show_end_date_label,                       
                            relations: tmp_rels
                        });
    
    
                    }else{
                        tmp.push({
                            value: key,
                            relation_type_name: jsondata.relation_types[key].name,
                            relation_type_description: jsondata.relation_types[key].description,
                            relations: jsondata.relations[key],
                            show_start_date:jsondata.relation_types[key].show_start_date,
                            show_end_date:jsondata.relation_types[key].show_end_date,
                            show_start_date_label:jsondata.relation_types[key].show_start_date_label,
                            show_end_date_label:jsondata.relation_types[key].show_end_date_label,
                            start_date_format:jsondata.relation_types[key].start_date_format,
                            end_date_format:jsondata.relation_types[key].end_date_format
                        });
                    }
                    
                }); 
                
                //console.log(tmp);
                setRelationTypesData(tmp);
                //setListReady(true);
            }
            
           
            
            
        }).catch((err) => {
            console.log("Login error: ");
            console.log(err);
            //Logout();
            
        });

    },[id]);

    const handleOnOKSubmitted = () => {
        setConfirmModalShow(false);
        onSendInvite();

    }
    //const defaultUserRole = 2;
    return( 
            <>
            <ConfirmModal confirmModalShow={confirmModalShow} handleConfirmModelClose={() => { setConfirmModalShow(false);}} handleOnOKSubmitted={handleOnOKSubmitted} message="Would you like to send the invite email now?" />
             <ConfirmDeleteModal modalShow={modalShow} handleModelClose={() => { setModalShow(false);}} moduleName="relation" delRecTitle={delRecTitle} handleOnDeleteSubmitted={handleOnDeleteSubmitted} />
             {successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}   
             {errMessage && <Alert variant="danger">{errMessage}</Alert>} 
            <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col md={4} className="mb-3">
                    <Form.Group id="FName">
                        <Form.Label className="required">First name</Form.Label>
                        <Form.Control type="text" placeholder="Enter your first name" {...register("FName")} required/>
                    </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                    <Form.Group id="SName">
                        <Form.Label className="required">Last name</Form.Label>
                        <Form.Control  type="text" placeholder="Enter your last name" {...register("SName")} required/>
                    </Form.Group>
                </Col> 

                <Col md={4} className="mb-3">
                    <Form.Group id="oName1">
                        <Form.Label >Other name 1</Form.Label>
                        <Form.Control  type="text" placeholder="" {...register("oName1")} />
                    </Form.Group>
                </Col>
            </Row> 

            <Row>
                <Col md={4} className="mb-3">
                    <Form.Group id="oName2">
                        <Form.Label>Other name 2</Form.Label>
                        <Form.Control type="text" placeholder=" " {...register("oName2")} />
                    </Form.Group>
                </Col> 
                <Col md={4} className="mb-3">
                    <Form.Group id="Email">
                        <Form.Label className="required">Primary email</Form.Label>
                        <Form.Control  type="email" placeholder="example@gmail.com" {...register("Email")} required/>
                    </Form.Group>
                </Col> 
                <Col md={4} className="mb-3">
                    <Form.Group id="secEmail">
                        <Form.Label>Secondary email</Form.Label>
                        <Form.Control type="email" placeholder="example@gmail.com" {...register("secEmail")} />
                    </Form.Group>
                </Col>                           
            </Row>

            <Row>
                <Col md={4} className="mb-3">
                    <Form.Group id="dblp_url">
                        <Form.Label>DBLP profile URL</Form.Label>
                        <Form.Control  type="text" placeholder="e.g: https://dblp.org/pid/78/50" {...register("dblp_url")} onChange={event => {
                                getDblpId(event.target.value);
                                }} />
                    </Form.Group>
                </Col> 

                <Col md={4} className="mb-3">
                    <Form.Group id="dblpId">
                        <Form.Label>DBLP ID </Form.Label>
                        <Form.Control  type="text" placeholder=" "  value={dblpId} readOnly = {true} />
                    </Form.Group>
                </Col> 

            </Row>

            <Row>
                

                <Col md={4} className="mb-3">
                    <Form.Group id="type">
                        <Form.Label className="required">User type</Form.Label>
                        <div className="col-sm-10">
                        {
                            (id !== undefined)?

                                <Select options={getValues('allTypes')} 
                                    value={ {value: UType, label:UTypeName } }
                                    onChange={(event) => { 
                                        if (event.value === 0){
                                            setValue("uRole",3);
                                            setUserRoleName("User");
                                        }
                                        setShowRoleField(event.value);
                                        setValue("UType",event.value);
                                        setUType(event.value);
                                        setUTypeName(event.label);
                                }}      />
                            :
                                <Select options={getValues('allTypes')} 
                                    defaultValue={ getValues('allTypes')[UType]  } 
                                    onChange={(event) => { 
                                            if (event.value === 0){
                                                setValue("uRole",3);
                                                setUserRoleName("User");
                                            }
                                            setShowRoleField(event.value);
                                            setValue("UType",event.value);
                                            setUType(event.value)
                                }}      />
                        }
                            
                        </div>  
                    </Form.Group>
                </Col>

                { (showRoleField > 0) &&                     
                <Col md={4} className="mb-3" >
                    <Form.Group id="role">
                        <Form.Label className="required">Admin user role</Form.Label>
                        <div className="col-sm-10">
                            
                        {
                            (id !== undefined)?
                                <Select name="uRole" options={selectUserRoleOptions} value={{value: userRoleId || props.userRoleId, label: userRoleName || props.userRoleName}} onChange={event => {
                                    setUserRoleId(event.value);
                                    setUserRoleName(event.label);
                                    setValue("uRole",event.value);
                                    }}   />
                        :
                            <Select name="uRole" options={selectUserRoleOptions} defaultValue={ {value: 1 , label: "User" } } onChange={event => {
                                setUserRoleId(event.value);
                                setUserRoleName(event.label);
                                setValue("uRole",event.value);
                                }}     />
                        }


                        </div>                        
                    </Form.Group>
                </Col>
                } 
                

            </Row>

            { (id === undefined) &&                  
                <Row >
                    <Col md={4} className="mb-3" >
                        <Row>
                            <Col className="mb-3" >
                                <Form.Group id="pwd">
                                    <Form.Label className="required">Password</Form.Label>
                                    <Form.Control name="pwd" type="password"  {...register("pwd" ) } required/>
                                </Form.Group>
                            </Col>                            
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group id="confirm_pwd">
                                    <Form.Label className="required">Confirm password</Form.Label>
                                    <Form.Control name="confirm_pwd" type="password"  {...register("confirm_pwd") } required/>
                                </Form.Group>
                            </Col>                            
                        </Row>

                    </Col>
                    <Col md={4} className="mb-3 password-rules-box" style= { { marginLeft: 10} } >{passRules()}</Col>
                </Row >
            }
        
            { (id !== undefined) && 
                 <>
                <h5 className="mt-4">Relations</h5>
                
                   
                        {listError && (id !== undefined)  && <Alert variant="danger" className="mt-2">{listError}</Alert>}
                        <div className="wrapperExpandable mt-3">
                        
                            {relationTypesData && relationTypesData.map((row, index) => (
                                <ExpandableBlock key={`collap-types-${index}-${row.value}`} title={<h5 className="mb-0">{row.relation_type_name}</h5>}>
                                    
                                    
                                        { (row.relation_type_description !== '') && <p><FontAwesomeIcon icon={faInfoCircle} className="animate-left-3 me-3 ms-2" />{row.relation_type_description}</p>}
                                        
                                        {/* For Co-authors of paper, umbrealla funding and co-awardee */}
                                        { ( (Number(row.value) === 3) || (Number(row.value) === 6) ||  (Number(row.value) === 7) ) &&
                                            <>
                                                <div key={`table-family-${index}-${row.value}`} className="grid-table">
                                                    <div className="row row-header g-0">
                                                        <div className="col col-12 col-md-4 d-none d-md-block">
                                                            <div className="contWrap"> 
                                                                {pubTitleArr.map((rel_row, rel_index) => {
                                                                    return(<React.Fragment key={`pubTitleArr-${rel_index}`}>{rel_row[row.value]}</React.Fragment>)
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="col col-12 col-md-2 d-none d-md-block">
                                                            <div className="contWrap">{row.show_end_date_label}</div>
                                                        </div>
                                                        <div className="col col-12 col-md-2 d-none d-md-block">
                                                            <div className="contWrap">Conflict reason</div>
                                                        </div>
                                                        <div className="col col-12 col-md-4 d-none d-md-block">
                                                            <div className="contWrap">                                                                
                                                                {pubCoAuthorTitleArr.map((rel_row, rel_index) => {
                                                                    return(<React.Fragment key={`pubCoAuthorTitleArr-${rel_index}`}>{rel_row[row.value]}</React.Fragment>)
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row-body">
                                                        {row.relations && row.relations.map((rel_row, rel_index) => (
                                                            <div className="row g-0" key={`family-${rel_index}`}>
                                                                <div className="col col-4 col-md-6 d-block d-md-none">
                                                                    <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>
                                                                        {pubTitleArr.map((rel_row, rel_index) => {
                                                                            return(<React.Fragment key={`pubTitleArr-${rel_index}`}>{rel_row[row.value]}</React.Fragment>)
                                                                        })}
                                                                    </div>
                                                                </div>
                                                                <div className="col col-8 col-md-4">
                                                                    {rel_row.publication.publication_name}
                                                                </div>
                                                     
                                                                { Object.keys(rel_row.publication.publication_years).map((yearKey,i,arr) => {
                                                                return (
                                                                    <React.Fragment key={`pub-row-${rel_index}-${i}`} >
                                                                        {(i > 0) &&
                                                                             <div className="col col-8 col-md-4">ssss</div>
                                                                        }
                                                                        <div className="col col-4 col-md-2 d-block d-md-none">
                                                                            <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>
                                                                                {row.show_end_date_label}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col col-8 col-md-2">
                                                                            {rel_row.publication.publication_years[yearKey]['date_end_year']}
                                                                        </div>

                                                                        <div className="col col-4 col-md-2 d-block d-md-none">
                                                                            <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>
                                                                                Conflict reason
                                                                            </div>
                                                                        </div>
                                                                        <div className="col col-8 col-md-2">
                                                                            {row.relation_type_name}<br/>
                                                                            {statusPill(rel_row.publication.publication_years[yearKey]['status'])}
                                                                        </div>

                                                                        <div className="col col-4 col-md-2 d-block d-md-none">
                                                                            <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>
                                                                                {pubCoAuthorTitleArr.map((rel_row, rel_index) => {
                                                                                    return(<React.Fragment key={`pubCoAuthorTitleArr-${rel_index}`}>{rel_row[row.value]}</React.Fragment>)
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col col-8 col-md-4">
                                                                            <>
                                                                            {
                                                                            rel_row.publication.publication_years[yearKey]['co_author_names'].map((authorKey,j) => {
                                                                                return(
                                                                                    <Fragment key={`author-list-${yearKey}${i}${j}`}>{authorKey}<br/></Fragment>
                                                                                )
                                                                            })
                                                                            }
                                                                            <br/>
                                                                            <Button variant="primary" as={Link} className="animate-hover btn btn-primary btn-primary-small-rounded"  to={`${editLink}/${rel_row.publication.publication_years[yearKey]['parent_id']}`} >
                                                                            <FontAwesomeIcon icon={faEye} className="animate-left-3 me-3 ms-2" /> View
                                                                            </Button>
                                                                            <Button className="animate-hover btn btn-delete btn-delete-small-rounded"  onClick={ () => deleteParentRelationBtn({ value: rel_row.publication.publication_years[yearKey]['parent_id'] , data: {PName: rel_row.publication.publication_name , relationTypeName: row.relation_type_name } } )}  >
                                                                            <FontAwesomeIcon icon={faTrashAlt} className="animate-left-3 me-3 ms-2" /> Delete
                                                                            </Button>

                                                                            </>
                                                                        </div>

                                                                    </React.Fragment>
                                                                )})}
                                                                
                                                            </div>
                                                        ))}
                                                        
                                                    </div>
                                                </div>

                                            </>
                                                       
                                        }

                                        { ((Number(row.value) === 1) || (Number(row.value) === 2) || (Number(row.value) === 4) || (Number(row.value) === 5) )  && row.relations && (row.relations.length > 0) &&   
                                            <>
                                                {/* For Family members, Direct acedemic, For Ongoing collaboration */}
                                                <div key={`table-family-${index}-${row.value}`} className="grid-table">
                                                    <div className="row row-header g-0">
                                                        <div className="col col-12 col-md-3 d-none d-md-block">
                                                            <div className="contWrap">Person name</div>
                                                        </div>
                                                        {(Number(row.show_start_date) === 1) && <div className="col col-12 col-md-2 d-none d-md-block">
                                                            <div className="contWrap">{row.show_start_date_label}</div>
                                                        </div>}
                                                        {(Number(row.show_end_date) === 1) && <div className="col col-12 col-md-2 d-none d-md-block">
                                                            <div className="contWrap">{row.show_end_date_label}</div>
                                                        </div>}
                                                        <div className="col col-12 col-md-2 d-none d-md-block">
                                                            <div className="contWrap">Status</div>
                                                        </div>
                                                        <div className="col col-12 col-md-3 d-none d-md-block">
                                                            <div className="contWrap">Action</div>
                                                        </div>
                                                    </div>

                                                    <div className="row-body">                                                        
                                                        {row.relations && row.relations.map((rel_row, rel_index) => (
                                                            <div className="row g-0" key={`family-${rel_index}`}>
                                                                <div className="col col-4 col-md-3 d-block d-md-none">
                                                                    <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>Person name</div>
                                                                </div>
                                                                <div className="col col-8 col-md-3">
                                                                    {rel_row.display_coauthor_name}<br/><small className="text-muted">{ (rel_row.co_author_status === 2 ) ? "(Deleted user)" : ""}</small>
                                                                </div>
                                                    
                                                                {(Number(row.show_start_date) === 1) && <>
                                                                <div className="col col-4 col-md-2 d-block d-md-none">
                                                                    <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>{row.show_start_date_label}</div>
                                                                </div>
                                                                <div className="col col-8 col-md-2">
                                                                    { ((Number(row.start_date_format) === 2) ? `${rel_row.start_date_month}/${rel_row.start_date}` : rel_row.start_date)}
                                                                </div></>}

                                                                {(Number(row.show_end_date) === 1) && <><div className="col col-4 col-md-2 d-block d-md-none">
                                                                    <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>{row.show_end_date_label}</div>
                                                                </div>
                                                                <div className="col col-8 col-md-2">
                                                                    { ((Number(row.end_date_format) === 2) ? `${rel_row.end_date_month}/${rel_row.end_date}` : rel_row.end_date)}
                                                                </div></>}

                                                                <div className="col col-4 col-md-2 d-block d-md-none">
                                                                    <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>Status</div>
                                                                </div>
                                                                <div className="col col-8 col-md-2">
                                                                    {statusPill(rel_row.status)}
                                                                </div>

                                                                <div className="col col-4 col-md-3 d-block d-md-none">
                                                                    <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>Action</div>
                                                                </div>
                                                                <div className="col col-8 col-md-3">
                                                                    {(rel_row.co_author_status !== 2 ) &&
                                                                    <>
                                                                    <Button variant="primary" as={Link} className="animate-hover btn btn-primary btn-primary-small-rounded"  to={`${editLink}/${rel_row.modify_id}`} >
                                                                        <FontAwesomeIcon icon={faEye} className="animate-left-3 me-3 ms-2" /> View
                                                                    </Button>
                                                                    
                                                                    <Button className="animate-hover btn btn-delete btn-delete-small-rounded"  onClick={ () => deleteParentRelationBtn({ value: rel_row.modify_id , data: {PName: rel_row.display_coauthor_name , relationTypeName: row.relation_type_name } } )}  >
                                                                        <FontAwesomeIcon icon={faTrashAlt} className="animate-left-3 me-3 ms-2" /> Delete
                                                                    </Button>
                                                                    </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))}
                                                        
                                                    </div>

                                                </div>
                                                
                                                
                                            </>

                                        }

                                        { (Number(row.value) === 8) && row.relations && (row.relations.length > 0) && 
                                            <div key={`table-family-${index}-${row.value}`} className="grid-table">
                                                <div className="row row-header g-0">
                                                    <div className="col col-12 col-md-4 d-none d-md-block">
                                                        <div className="contWrap">Institution name</div>
                                                    </div>
                                                    {(Number(row.show_start_date) === 1) && <div className="col col-12 col-md-2 d-none d-md-block">
                                                        <div className="contWrap">{row.show_start_date_label}</div>
                                                    </div>}
                                                    {(Number(row.show_end_date) === 1) && <div className="col col-12 col-md-2 d-none d-md-block">
                                                        <div className="contWrap">{row.show_end_date_label}</div>
                                                    </div>}
                                                    <div className="col col-12 col-md-2 d-none d-md-block">
                                                        <div className="contWrap">Status</div>
                                                    </div>
                                                    <div className="col col-12 col-md-4 d-none d-md-block">
                                                        <div className="contWrap">Action</div>
                                                    </div>
                                                </div>

                                                <div className="row-body">
                                                    {row.relations && row.relations.map((rel_row, rel_index) => (
                                                        <div className="row g-0" key={`inst-row-${rel_index}`}>
                                                            <div className="col col-4 col-md-5 d-block d-md-none">
                                                                <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>Institution name</div>
                                                            </div>
                                                            <div className="col col-8 col-md-5">
                                                                {rel_row.institution_name}
                                                            </div>

                                                            {(Number(row.show_start_date) === 1) && <>
                                                            <div className="col col-4 col-md-2 d-block d-md-none">
                                                                <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>{row.show_start_date_label}</div>
                                                            </div>
                                                            <div className="col col-8 col-md-2">
                                                                { ((Number(row.start_date_format) === 2) ? `${rel_row.start_date_month}/${rel_row.start_date}` : rel_row.start_date)}
                                                            </div></>}

                                                            {(Number(row.show_end_date) === 1) && <><div className="col col-4 col-md-2 d-block d-md-none">
                                                                <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>{row.show_end_date_label}</div>
                                                            </div>
                                                            <div className="col col-8 col-md-2">
                                                                { ((Number(row.end_date_format) === 2) ? `${rel_row.end_date_month}/${rel_row.end_date}` : rel_row.end_date)}
                                                            </div></>}

                                                            <div className="col col-4 col-md-2 d-block d-md-none">
                                                                <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>Status</div>
                                                            </div>
                                                            <div className="col col-8 col-md-2">
                                                                {statusPill(rel_row.status)}
                                                            </div>

                                                            <div className="col col-4 col-md-3 d-block d-md-none">
                                                                <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>Action</div>
                                                            </div>
                                                            <div className="col col-8 col-md-3">
                                                                <Button variant="primary" as={Link} className="animate-hover btn btn-primary btn-primary-small-rounded"  to={`${editLinkPendingEmployment}/${rel_row.modify_id}`} >
                                                                    <FontAwesomeIcon icon={faEye} className="animate-left-3 me-3 ms-2" /> View
                                                                </Button>
                                                                <Button className="animate-hover btn btn-delete btn-delete-small-rounded"  onClick={ () => deletePendingRelationBtn({ value: rel_row.modify_id , data: {RName: rel_row.institution_name } } )}  >
                                                                    <FontAwesomeIcon icon={faTrashAlt} className="animate-left-3 me-3 ms-2" /> Delete
                                                                </Button>

                                                            </div>
                                                        </div>
                                                    ))}
                                                    
                                                </div>
                                            </div>
                                        }

                                    </ExpandableBlock>
                            ))}

                            <ExpandableBlock title={<h5 className="mb-0">Institutional affliations</h5>} >
                                
                                <Row>
                                    <Col md={12} className="mt-3 mb-3">
                                        <p>Please list all institutional affiliations in the past 5 years.</p>
                                        <p className="text-danger">If the institution you want to link is not present in the list, type the new name in the box and click on Create option in the drill down list. Please list all possible names by which this institution might be referred to in a comma-separated list. E.g., “Advanced Micro Devices, AMD”. The new institution will be sent for approval to the administator.</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <DynamicInstitutions inputList={inputList} setInputList={setInputList} institutionData={institutionData}/> 
                                    </Col>
                                </Row>
                            </ExpandableBlock>
                        
                        </div>

                    
                    
                    {/*<Tab eventKey="pubView" title="By publication">
                        <>
                            <Row>
                                <Col xs={12} >
                                    <Button as={Link} variant="primary" className="animate-hover" to={Routes.Relations.addpath}>
                                        <FontAwesomeIcon icon={faPlus} className="animate-left-3 me-3 ms-2" />
                                        Add new relation
                                    </Button> 
                                </Col>
                            </Row> 
                            {publicationObj.data && !publicationObj.isError && 
                                <Row className="mt-3">
                                    <Col>All the relations which do not have a publication selected will be listed under "Others". Select a publication on left to see all relations.</Col>
                                </Row>
                            }
                            <Row className="mt-3">
                                {publicationObj.data && !publicationObj.isError &&
                                    <Col lg={2} style={{maxHeight: "400px", overflowY : "auto", overflowX : "hidden", borderRight:'1px solid #dde2eb'}}>
                                        {publicationObj.isPending && <div>Loading...</div>}
                                        {publicationObj.data && publicationObj.data.map(el => {
                                                return (                                                       
                                                    <Button key={`btn-${el.id}`} id={`btn-${el.id}`} className="d-flex justify-content-between align-items-center btn-outline-sidetabs" onClick={() => { fillData(`btn-${el.id}`,`publication-grid-${el.id}`,el.id) }} >
                                                        {el.name}
                                                    </Button>
                                                );
                                            })
                                        }
                                        
                                    </Col>
                                }
                                <Col lg={10}>                                            
                                    {relationsObj.data &&
                                        <>                                                    
                                            <div className="ag-theme-alpine mt-2" style={{ height: 400, width: '100%' }}>
                                                <AgGridReact
                                                    className="user-table align-items-center"
                                                    ref={gridRef}
                                                    rowData={rowData}
                                                    columnDefs={columns}
                                                    pagination={true}                                                    
                                                    rowSelection={'single'}
                                                    >
                                                </AgGridReact>
                                            </div >                                                        
                                        </>
                                    }
                                </Col>
                            </Row>
                            
                            <div id="relationGrid">
                            <Row className="mt-3"  >
                                <Col xs={12} >                                            
                                        
                                    
                                </Col>
                            </Row>
                            </div>
                        </>
                                </Tab>*/}
                                  
               
                    
                    

                </>
             }

            
            

            <Row className="mt-4">
				<Col md={2}>
                    <Form.Group>
						<Form.Label className="form-check-label required"  >
							Status
						</Form.Label>
						<div className="form-check mt-0">
							<input className="form-check-input" type="radio" name="status"  id="gridRadios1" value="1"
                            checked={Number(status)===1?true:false}
                            onChange={(e) => {setStatus(e.target.value);setValue("status",1);}}
                            required    
							></input>
							<Form.Label className="form-check-label"   >
								Active
							</Form.Label>
						</div>
						<div className="form-check">
						   <input className="form-check-input" type="radio" name="status" id="gridRadios2" value="0" required 
                           checked={Number(status)===0?true:false}
                           onChange={(e) => {setStatus(e.target.value);setValue("status", 0)}}
						   ></input>
						    <Form.Label className="form-check-label" >
                             Inactive
						    </Form.Label>
						</div>
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group>
                        <Form.Label className="form-check-label required"  >
                            Locked account
                        </Form.Label>
                        <div className="form-check mt-0">
                            <input className="form-check-input" type="radio" name="locked"  id="gridRadios3" value="1"
                            checked={Number(locked)===1?true:false}
                            onChange={(e) => {setLockedStatus(e.target.value);setValue("locked",1);}}
                            required    
                            ></input>
                            <Form.Label className="form-check-label"   >
                                Yes
                            </Form.Label>
                        </div>
                        <div className="form-check">
                        <input className="form-check-input" type="radio" name="locked" id="gridRadios4" value="0" required 
                        checked={Number(locked)===0?true:false}
                        onChange={(e) => {setLockedStatus(e.target.value);setValue("locked", 0)}}
                        ></input>
                            <Form.Label className="form-check-label" >
                            No
                            </Form.Label>
                        </div>
                    </Form.Group>
                </Col>
			</Row>

             
            { (id !== undefined) && 
             <><hr />
            <Row className="mb-5 mt-5">
                <Col>
                    <p>Click this button below if you wish to resend the invite email.</p>
                    {(inviteButtonClicked === 0) &&
                    <Button className="animate-hover btn-addnew" onClick={() => {
                        setConfirmModalShow(true);
                    }}>
                        <FontAwesomeIcon icon={faPaperPlane} className="animate-left-3 me-3 ms-2" />
                        Send invite mail
                    </Button>}
                    {(inviteButtonClicked === 1) && <Button className="animate-hover btn-addnew" >
                        <FontAwesomeIcon icon={faCheck} className="animate-left-3 me-3 ms-2" />
                        Invite sent
                    </Button>}
                    {(inviteButtonClicked === 2) && <Button className="animate-hover btn-delete" >
                        <FontAwesomeIcon icon={faTimes} className="animate-left-3 me-3 ms-2" />
                        Invite sending failed
                    </Button>}
                    {(inviteButtonClicked === 3) && <Button className="animate-hover btn-addnew" onClick={onSendInvite}>
                        <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{marginRight:10}}
                        />
                        Sending invite.. please wait...
                    </Button>}                    
                </Col>
            </Row></>
            }
            {
                (id !== undefined) ?
                    
                    <ModuleEditScreenButtons cancelPath={Routes.Users.path} addnewPath={Routes.Users.addpath} showSave={props.showSave} ownerInfo={ownerInfo} moduleOtherInfo={moduleOtherInfoSet()} startSave={startSave}  /> 
                   
                : 
                    <ModuleEditScreenButtons cancelPath={Routes.Users.path} showSave={props.showSave} startSave={startSave}  /> 
            }
            </Form>
</>
    );
}
export default UserEditForm;
