import { Col, Row,Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { Link } from 'react-router-dom';
import { Routes } from "../../routes";

const CommunityPaper = () => {

    const heading = "Community paper list";
   // const [key, setKey] = useState('home');

    return ( 
    <>
        <ModuleBreadcrumb links={[{ label: heading }]}  />
        <h4>{heading}</h4>
        <Row className="mt-2">
            <Col xs={12}>
                <Button as={Link} variant="primary" className="animate-hover" to={Routes.ConflictExport.path}>
                    <FontAwesomeIcon icon={faChevronLeft} className="animate-left-3 me-3 ms-2" />
                    Back to conflict export
                </Button>                
            </Col>
        </Row>
        <Row className="mt-4">
            <Col xs={12}>
                <p>This interface will list all papers tagged as 'Possibly a community paper' or 'Not a research paper'.</p>
                <p>The admin or PC Chair needs to make a per-paper decision as to whether or not the paper is, indeed, a community paper for the purposes of that particular conference.</p>
                <p>Any paper in this list does not constitute a conflict and will be ignored in conflict checking for that particular conference. You will be given a checkbox next to each paper to tick if you want them treated as conflicts</p>
                <h2>Question???</h2>
                <p>Do we need to store this selection made by the chair in db, so that if they generate the conflict list again for same conference we remember the selection made by the chair? This selection as informed will not be carried over to another conference.</p>
            </Col>
        </Row>

        <Row className="mt-4">
            <Col xs={12}>
                <p style={{color:'red'}}>Listing to be worked on....</p>
            </Col>
        </Row>   
        

    </> );
}


export default CommunityPaper;