import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { Routes } from "../routes";
import { Container, Row, Col } from '@themesberg/react-bootstrap';

// pages
// import Presentation from "./Presentation";
import Upgrade from "./Upgrade";
import DashboardOverview from "./dashboard/DashboardOverview";
import Transactions from "./Transactions";
import Settings from "./Settings";
import BootstrapTables from "./tables/BootstrapTables";
import Signin from "./userAdmission/Signin";
import Signup from "./userAdmission/Signup";
import GuestForgotPassword from "./userAdmission/ForgotPassword";
import GuestResetPassword from "./userAdmission/ResetPassword";
import Lock from "./userAdmission/Lock";
import NotFoundPage from "./userAdmission/NotFound";
import ServerError from "./userAdmission/ServerError";

import DemoUser from "./demoUsers/List";
import DemoUserAdd from "./demoUsers/Add";
import DemoUserEdit from "./demoUsers/Edit";


// components
import Sidebar from "../project/components/Sidebar";
import Navbar from "../project/components/Navbar";
import Footer from "../project/components/Footer";
import Preloader from "../framework/components/Preloader";

// users module
import UserAdd from "./users/Add";
import UserEdit from "./users/Edit";
import UserList from "./users/List";
import ImportUsers from "./users/ImportUsers";
import InviteSendList from "./users/InviteSendList";

//New Admin module-conferences
import ConferenceAdd from "./conferences/Add";
import ConferenceEdit from "./conferences/Edit";
import ConferenceList from "./conferences/List";

// Institution module
import InstitutionAdd from "./institution/Add";
import InstitutionEdit from "./institution/Edit";
import InstitutionList from "./institution/List";

// Relation module
import RelationAdd from "./relation/Add";
import RelationEdit from "./relation/Edit";
import RelationList from "./relation/List";
import ImportRelation from "./relation/ImportRelation";
import RelationPendingEdit from "./relation/RelationPendingEdit";

// Relation type module
import RelationTypeAdd from "./relationType/Add";
import RelationTypeEdit from "./relationType/Edit";
import RelationTypeList from "./relationType/List";

// Email module
import EmailTempList from "./emailTemp/List";
import EmailTempAdd from "./emailTemp/Add";
import EmailTempEdit from "./emailTemp/Edit";

// User Role module
import UserRoleList from "./userRole/List";
import UserRoleAdd from "./userRole/Add";
import UserRoleEdit from "./userRole/Edit";

// Conflict Export, Community Paper
import CommunityPaperList from "./communityPaper/List";
import ConflictExport from "./conflictExport/List";

//Password Reset
import PasswordReset from "./passwordReset/Form";

//user registrations
import userRegistration from "./userRegistration/List";
import userRegistrationEdit from "./userRegistration/Edit";


import { RefreshToken } from "../framework/utilities/utilities";



const RouteWithLoader = ({ component: Component, ...rest }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Route {...rest} render={props => (<> <Preloader show={loaded ? false : true} /> <Component {...props} /> </>)} />
    );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);
    }, []);

    const localStorageIsSettingsVisible = () => {
        return localStorage.getItem('settingsVisible') === 'false' ? false : true
    }

    const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

    const toggleSettings = () => {
        setShowSettings(!showSettings);
        localStorage.setItem('settingsVisible', !showSettings);
    }

    return (
        <Route {...rest} render={props => (
            <>
                <Preloader show={loaded ? false : true} />
                <Sidebar />

                <main className="content d-flex flex-column h-100">
                    <div className="flex-shrink-0">
                        <Navbar />
                        <Component {...props} />
                    </div>
                    <div className="mt-auto">
                        <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
                    </div>
                </main>
            </>
        )}
        />
    );
};

const HomePage = () => {

    // lets check if the token is still valid
    const API_URL = process.env.REACT_APP_API_URL;
    const [showLogoutmessage, setShowLogoutmessage] = useState(false);

    if (localStorage.getItem("token")){
        fetch(API_URL + '/v1/user/profile', {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
        })
        .then(response => { return response.json(); })
        .then(jsondata => { 
           // console.log("inside homepage.js",jsondata);
            if (jsondata !== 'Unauthorized')  localStorage.setItem("profile", JSON.stringify(jsondata));
            setShowLogoutmessage(false);
        }).catch((err) => {
            //console.log("Login error: " + err);
            //console.log("Need to logout");
            // we need to clean up the local storage
            localStorage.clear();
            setShowLogoutmessage(true);
        });
    }


    const history = useHistory();

    //console.log(localStorage.getItem("authResponse"));

    //console.log(localStorage.getItem("expires_in"));
    const refreshToken = localStorage.getItem("expires_in");

    //useEffect(() => {
    if (refreshToken) {
        RefreshToken(refreshToken);
    }
    //}, []);

    let sessionUser = JSON.parse(localStorage.getItem("profile"));
   // console.log(sessionUser);
    if ((sessionUser) && (!sessionUser.FName)){
        // console.log("comes inside")
        localStorage.clear();
    }


    const [isLoggedIn] = useState(localStorage.getItem("token"));
    const [forSignedOutLinks] = useState(isLoggedIn === "undefined" || isLoggedIn === null ? true : false);

    if (forSignedOutLinks) {
        if ( (history.location.pathname.includes("reset-password/")) || (history.location.pathname.includes("forgot-password/")) ) {
            // do nothing
        }else{
            history.push("/sign-in");
        }
        
    } else {
       // history.push("/");
    }

    return (
        <>
            {/* Logged out links */}
            {forSignedOutLinks &&
                <>
                    <Switch>
                        <RouteWithLoader exact path={Routes.DashboardOverview.path} component={Signin} />
                        <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
                        <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
                        <RouteWithLoader exact path={Routes.GuestForgotPassword.path} component={GuestForgotPassword} />
                        <RouteWithLoader exact path={Routes.GuestResetPassword.path} component={GuestResetPassword} />
                        <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
                        <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />
                        <Redirect to={Routes.NotFound.path} />
                    </Switch>
                </>
            }

            {/* logged in links */}
            {!forSignedOutLinks &&
                <>
                    { showLogoutmessage && <Container><Row><Col className="text-danger text-center">Something is not right with current session. Please refresh page and try again.<br/> If you still experience issues logout and login again.</Col></Row></Container>}
                    <Switch>
                        <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
                        <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
                        <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

                        {/* pages */}
                        <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
                        <RouteWithSidebar exact path={Routes.Upgrade.path} component={Upgrade} />
                        <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
                        <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
                        <RouteWithSidebar exact path={Routes.BootstrapTables.path} component={BootstrapTables} />

                        {/* users */}
                        <RouteWithSidebar exact path={Routes.Users.path} component={UserList} />
                        <RouteWithSidebar exact path={Routes.Users.addpath} component={UserAdd} />
                        <RouteWithSidebar exact path={Routes.Users.editpath} component={UserEdit} />
                        <RouteWithSidebar exact path={Routes.Users.importpath} component={ImportUsers} />

                        {/* New admin-conferences */}
                        <RouteWithSidebar exact path={Routes.Conferences.path} component={ConferenceList} />
                        <RouteWithSidebar exact path={Routes.Conferences.addpath} component={ConferenceAdd} />
                        <RouteWithSidebar exact path={Routes.Conferences.editpath} component={ConferenceEdit} />
                        

                        
                        <RouteWithSidebar exact path={Routes.DemoUsers.path} component={DemoUser} />
                        <RouteWithSidebar exact path={Routes.DemoUsers.addpath} component={DemoUserAdd} />
                        <RouteWithSidebar exact path={Routes.DemoUsers.editpath} component={DemoUserEdit} />

                        {/* institution */}
                        <RouteWithSidebar exact path={Routes.Institution.addpath} component={InstitutionAdd} />
                        <RouteWithSidebar exact path={Routes.Institution.editpath} component={InstitutionEdit} />
                        <RouteWithSidebar exact path={Routes.Institution.path} component={InstitutionList} />

                         {/* relation */}
                        <RouteWithSidebar exact path={Routes.Relations.path} component={RelationList} />
                        <RouteWithSidebar exact path={Routes.Relations.addpath} component={RelationAdd} />
                        <RouteWithSidebar exact path={Routes.Relations.editpath} component={RelationEdit} />
                        <RouteWithSidebar exact path={Routes.Relations.importpath} component={ImportRelation} />
                        {/* relation type */}
                        <RouteWithSidebar exact path={Routes.RelationType.path} component={RelationTypeList} />
                        <RouteWithSidebar exact path={Routes.RelationType.addpath} component={RelationTypeAdd} />
                        <RouteWithSidebar exact path={Routes.RelationType.editpath} component={RelationTypeEdit} />
                        <RouteWithSidebar exact path={Routes.Relations.pendingRelationEdit} component={RelationPendingEdit} />
                        
                        {/* Email temp */}
                        <RouteWithSidebar exact path={Routes.EmailTemp.path} component={EmailTempList} />
                        <RouteWithSidebar exact path={Routes.EmailTemp.addpath} component={EmailTempAdd} />
                        <RouteWithSidebar exact path={Routes.EmailTemp.editpath} component={EmailTempEdit} />
                        
                        {/* User roles */}
                        <RouteWithSidebar exact path={Routes.UserRole.path} component={UserRoleList} />
                        <RouteWithSidebar exact path={Routes.UserRole.addpath} component={UserRoleAdd} />
                        <RouteWithSidebar exact path={Routes.UserRole.editpath} component={UserRoleEdit} />

                        {/* Conflict export, Community papers */}
                        <RouteWithSidebar exact path={Routes.CommunityPapers.path} component={CommunityPaperList} />
                        <RouteWithSidebar exact path={Routes.ConflictExport.path} component={ConflictExport} />

                        {/* password */}
                        <RouteWithSidebar exact path={Routes.PasswordReset.path} component={PasswordReset} />
                       
                        {/* User registrations */}
                        <RouteWithSidebar exact path={Routes.userRegistration.path} component={userRegistration} />
                        <RouteWithSidebar exact path={Routes.userRegistration.editpath} component={userRegistrationEdit} />

                        {/* Seding invite to users */}
                        <RouteWithSidebar exact path={Routes.userInviteSend.path} component={InviteSendList} />
                        


                        <Redirect to={Routes.NotFound.path} />
                    </Switch>
                </>
            }
        </>
    )
}

export default HomePage;
