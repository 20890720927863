import { useParams } from "react-router-dom";
import useFetch from "../../framework/hooks/useFetch";

import { useForm } from "react-hook-form";
import { fetch_API } from "../../framework/utilities/fetch_API";



const EditUser = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const { id } = useParams();
    const { register, handleSubmit, setValue } = useForm();

    /* Fetching data and setting the values to the form elements */
    const { data, isPending, isError } = useFetch(API_URL + '/v1/user/data/view/' + id, 'GET', true);
    setValue("GID", data.GID);
    setValue("firstName", data.FName);
    setValue("lastName", data.SName);
    setValue("email", data.Email);

    const onSubmit = data => {
        let formData = new FormData();
        formData.append("GID", data.GID);
        formData.append("FName", data.firstName);
        formData.append("SName", data.lastName);
        formData.append("Email", data.email);

        fetch_API(API_URL + '/v1/user/data/save', 'POST', formData).then(function (res) {
            console.log(res);
        }).catch((err) => {
            console.log("Login error: " + err);
        });
    };

    return (
        <>
            <h1>Edit User {id}</h1> <br />
            {isPending && <p className="text-danger text-center">Loading...</p>}
            {isError && <p className="text-danger text-center">Error</p>}
            <form onSubmit={handleSubmit(onSubmit)}>
                <input {...register("firstName")} /><br />
                <input {...register("lastName")} /><br />
                <input {...register("email")} /><br />

                <input type="submit" />
            </form>
        </>
    );
}

export default EditUser;