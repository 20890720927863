import { useState, useEffect } from "react";
import { useDropzone } from 'react-dropzone';
import DatePicker from "react-datepicker";
import Select from 'react-select'
import DynamicField from './DynamicFields';
import "react-datepicker/dist/react-datepicker.css";

const AddUser = () => {
    const API_URL = process.env.REACT_APP_API_URL;


    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [userType, setUserType] = useState('');
    const [startDate, setStartDate] = useState(new Date());


    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    };

    const thumb = {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
    };

    const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    };

    const img = {
        display: 'block',
        width: 'auto',
        height: '100%'
    };

    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setFiles(
                acceptedFiles.map(file => Object.assign(
                    file, { preview: URL.createObjectURL(file), base64Data: file.base64Data }
                ))
            );
        },
        getFilesFromEvent: event => myCustomFileGetter(event)
    });

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    alt=""
                />
            </div>
        </div>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);


    /*const convertBlob = () => {
        setImages65([]);

        files.forEach(function (key) {
            console.log(1, key)
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImages65(images65 => [...images65, reader.result]);
            }, false);
            reader.readAsDataURL(key);
        })

        console.log(files[0].base64Data);
        console.log(files[0].base64Data);
    }*/

    console.log(files);
    // console.log(images65);

    const handleSubmit = (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append("FName", firstName);
        formData.append("SName", lastName);
        formData.append("Email", email);
        formData.append("UType", userType);

        console.log(formData);

        fetch(API_URL + '/v1/user/data/save', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
            body: formData
        })
            .then(response => response.json())
            .then(jsondata => {
                console.log("success");
                console.log(jsondata);
                jsondata.GID === undefined ? alert(jsondata.message) : alert("Success");
            }).catch((err) => {
                console.log("Login error: " + err);
            });
    }

    const selectOptions = [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
    ]


    return (
        <>
            <h1>Add User</h1> <br />
            <form onSubmit={handleSubmit}>
                <div className="row mb-3">
                    <label htmlFor="inputFName" className="col-sm-2 col-form-label">FName</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputFName" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    </div>
                </div>

                <div className="row mb-3">
                    <label htmlFor="inputSName" className="col-sm-2 col-form-label">SName</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputSName" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    </div>
                </div>

                <div className="row mb-3">
                    <label htmlFor="inputEmail" className="col-sm-2 col-form-label">Email</label>
                    <div className="col-sm-10">
                        <input type="email" className="form-control" id="inputEmail" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </div>

                <div className="row mb-3">
                    <label htmlFor="inputUserType" className="col-sm-2 col-form-label">DOB</label>
                    <div className="col-sm-10">
                        <DatePicker
                            className="form-control"
                            isClearable
                            closeOnScroll={true}
                            placeholderText="Enter your data of birth."
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                        />
                    </div>
                </div>

                <div className="row mb-3">
                    <label htmlFor="inputUserType" className="col-sm-2 col-form-label">User Type</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputUserType" value={userType} onChange={(e) => setUserType(e.target.value)} />
                    </div>
                </div>

                <div className="row mb-3">
                    <label htmlFor="inputUserType" className="col-sm-2 col-form-label">Gender</label>
                    <div className="col-sm-10">
                        <Select options={selectOptions} />
                    </div>
                </div>

                <div className="row mb-3">
                    <label htmlFor="inputUserType" className="col-sm-2 col-form-label">Skills</label>
                    <div className="col-sm-10">
                        <DynamicField />
                    </div>
                </div>

                <div className="row mb-3">
                    <label htmlFor="inputUserType" className="col-sm-2 col-form-label">Avatar</label>
                    <div className="col-sm-10">

                        <section className="dropZoneContainer">
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop some files here, or click to select files</p>
                            </div>
                            <aside style={thumbsContainer}>
                                {thumbs}
                            </aside>
                        </section>
                        {/*<button type="button" className="btn btn-primary" onClick={() => convertBlob()}>Upload</button>*/}
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-sm-2">&nbsp;</div>
                    <div className="col-sm-10"><button type="submit" className="btn btn-primary">Add user</button></div>
                </div>
            </form>
        </>
    );
}

async function myCustomFileGetter(event) {
    const files = [];
    const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;

    for (var i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);

        const reader = new FileReader();
        reader.addEventListener("load", () => {

            let formData = new FormData();
            formData.append("FileData", reader.result);
            formData.append("FileName", file.name);

            fetch(process.env.REACT_APP_API_URL + '/v1/user/data/save/avatar', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("token"),
                },
                body: formData
            })
                .then(response => response.json())
                .then(jsondata => {
                    console.log("success");
                    console.log(jsondata);
                    Object.defineProperty(file, {
                        FileID: {
                            value: jsondata.GID
                        },
                        FileURL: {
                            value: jsondata.URL
                        }
                    });
                }).catch((err) => {
                    console.log("Login error: " + err);
                });

            Object.defineProperty(file, 'base64Data', {
                value: reader.result
            });

        }, false);
        reader.readAsDataURL(file);

        files.push(file);
    }

    return files;
}

export default AddUser;