import React,{useState} from "react";
import { Col, Row, Form, Alert } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import ModuleEditScreenButtons  from "../../project/components/ModuleEditScreenButtons";
import { useParams, useHistory  } from "react-router-dom";
import { saveFormRecord } from "../../framework/utilities/utilities";

const UserRoleForm = (props) => {

    const { id } = useParams();
    const register = props.register;
    const handleSubmit = props.handleSubmit;
    const setValue = props.setValue;
    const getValues = props.getValues;
    const errors = props.errors;
    
    const [successMessage,setSuccessMessage] = useState('');
    const [errMessage,setErrorMessage] = useState('');
    const [startSave,setStartSave] = useState(false);
    const [showSave,SetShowSave] = useState(props.showSave);

    const [status, setStatus] = useState(props.recStatus);
      
    const history = useHistory();

    const ownerInfo = { 
        'createdBy' :  getValues('createdBy'),
        'createdAt' : getValues('createdAt'),
        'updatedBy' :  getValues('updatedBy'), 
        'updatedAt' : getValues('updatedAt') 
    }; 

    const onSubmit = postedData => {
        setStartSave(true);
        let formData = new FormData();
        if(id !== undefined){
            formData.append("GID", id);
        }
        formData.append("RName", postedData.RName);
        formData.append("Status", status);

        saveFormRecord({'formData': formData, 'url': '/v1/userroles/data/save'}).then((json_response) => {
            if ((json_response.type === "success_update") || (json_response.type === "success_create")) {
                
                setSuccessMessage(json_response.message);
                setStartSave(false); 
                if (json_response.type === "success_create"){
                    SetShowSave(false);
                    setTimeout(function(){
                        history.push(Routes.UserRole.editpath.replace(':id',json_response.GID));
                    },1000); 
                }else{
                    setTimeout(function(){                    
                        history.push(Routes.UserRole.path);
                    },1000); 
                }
            }           
            if (json_response.type === "error") setErrorMessage(json_response.message);    
            setStartSave(false);         
        });
        
        return false;
        
    }


    return (
            <>
                {successMessage && <Alert variant="success">{successMessage}</Alert>}            
                {errMessage && <Alert variant="danger">{errMessage}</Alert>}      
                <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col sm={12} className="mb-3">
                        <Form.Group id="RName">
                            <Form.Label className="required">Role name</Form.Label>
                            <Form.Control name="RName"  type="text" placeholder=" " {...register('RName',{ required: true})} className={errors?.IName?.type === "required" && "is-invalid"}  />     
                            {errors?.RName?.type === "required" && <p className="is-invalid" >Please enter role name.</p>}   
                        </Form.Group>
                    </Col>
                </Row> 
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label className="form-check-label required"  >
                                Status
                            </Form.Label>
                            <div className="form-check mt-0">
                                <input className="form-check-input" type="radio" name="status"  id="gridRadios1" value="1"
                                checked={Number(status)===1?true:false}
                                onChange={(e) => {setStatus(e.target.value);setValue("status",1);}}
                                required    
                                ></input>
                                <Form.Label className="form-check-label"   >
                                    Active
                                </Form.Label>
                            </div>
                            <div className="form-check">
                            <input className="form-check-input" type="radio" name="status" id="gridRadios2" value="0" required 
                            checked={Number(status)===0?true:false}
                            onChange={(e) => {setStatus(e.target.value);setValue("status", 0)}}
                            ></input>
                                <Form.Label className="form-check-label" >
                                    Inactive
                                </Form.Label>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                {
                    (id !== undefined) ?
                        <ModuleEditScreenButtons cancelPath={Routes.UserRole.path} addnewPath={Routes.UserRole.addpath} showSave={showSave} startSave={startSave} ownerInfo={ownerInfo} /> 
                    : 
                        <ModuleEditScreenButtons cancelPath={Routes.UserRole.path} showSave={showSave} startSave={startSave} /> 
                }                
                </Form>

            </>
      );
}
 
export default UserRoleForm;
