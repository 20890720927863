import { Col, Row,  Form,   Alert} from '@themesberg/react-bootstrap';
import ModuleEditScreenButtons  from "../../project/components/ModuleEditScreenButtons";
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { PasswordCheck, passRules } from "../../framework/utilities/utilities";
import { Routes } from "../../routes";
import { useForm } from "react-hook-form";
import React,{useState} from "react";

const PasswordResetForm = (props) => {
    const heading = "Password Reset";
    const { register, handleSubmit, setValue  } = useForm();
    const [startSave,setStartSave] = useState(false);
    const [successMessage,setSuccessMessage] = useState('');
    const [errMessage,setErrorMessage] = useState('');
    const API_URL = process.env.REACT_APP_API_URL;
    setValue("password","");
    setValue("confirmPassword","");

    const onSubmit = postedData => {
        setErrorMessage('');
        setStartSave(true);
        let formData = new FormData();
        let sessionUser = JSON.parse(localStorage.getItem("profile"));
        if(!PasswordCheck(postedData.password))
            {
                setStartSave(false);                
                setErrorMessage(passRules());
                return false;
            }
            else if(postedData.password !== postedData.confirmPassword)
            {
                setStartSave(false);
                setErrorMessage('Password and confirm password do not match');
                return false;
                
            }
            
        formData.append("email", sessionUser.Email);
        formData.append("password", postedData.password);
        formData.append("confirmPassword", postedData.confirmPassword);
        
        fetch(API_URL + '/v1/user/loggedin-password-reset', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
            body: formData
        })
            .then(response => response.json())
            .then(jsondata => {
                if (jsondata.id){
                    setSuccessMessage("Password updated successfully.");
                }else{
                    setErrorMessage("Password could not be updated.");                                      
                }                
                setStartSave(false); 
               
            }).catch((err) => {
                console.log("Login error: " + err);
                setErrorMessage("Password could not be updated.");
                setStartSave(false); 
            });
            
    
    }

    return (
        <>
            <ModuleBreadcrumb links={[{ label:heading } ]}  />
            {successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}            
            {errMessage && <Alert variant="danger">{errMessage}</Alert>} 
            
            <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
               
                <Row>
                    <Col md={6} className="mb-3" >
                        <Row>   
                            <Col md={10} className="mb-3">
                                <Form.Group id="password">
                                    <Form.Label className="required">New Password</Form.Label>
                                    
                                    <Form.Control required  {...register("password")} name="password" type="password" placeholder="" />
                                   
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={10} className="mb-3">
                                <Form.Group id="confirmPassword ">
                                    <Form.Label className="required">Confirm password</Form.Label>
                                    <Form.Control required  {...register("confirmPassword")} name="confirmPassword" type="password" placeholder="" />
                                    
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={5} className="mb-3 password-rules-box" style= { {marginLeft: 10} } >{passRules()}</Col>
                </Row>      
                      <ModuleEditScreenButtons  showSave={true}  startSave={startSave} cancelPath={Routes.DashboardOverview.path} />
                    
            </Form>
                
        </>
     );
}
 
export default PasswordResetForm;