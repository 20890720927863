import UserEditForm from './Form';
import { Col, Row,Alert } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import useFetch from "../../framework/hooks/useFetch";
import moment from "moment-timezone";
const UserEdit = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const { id } = useParams();
    const { register, handleSubmit, setValue, getValues} = useForm();

    /** fetching the user roles to list in dropdown */
   

    /* Fetching data and setting the values to the form elements */
    const { data, isPending, isError } = useFetch(API_URL + '/v1/user/data/view/' + id, 'GET', true);

    //console.log(data);

   // console.log('institutionData');
    setValue("GID", data.GID);
    setValue("FName", data.FName);
    setValue("SName", data.SName);
    setValue("Email", data.Email);
    setValue("secEmail", data.secEmail);
    setValue("oName1", data.oName1);
    setValue("oName2", data.oName2);
    setValue("dblp_url", data.dblp_url);
    setValue("uRole", data.uRole);
    setValue("UType", data.UType);
    setValue("UTypeName", data.UTypeName);

    setValue("dblpId", data.dblpId);
    setValue("Status", data.Status);
    setValue("createdAt", data.createdAt);
    setValue("createdBy", data.createdBy);
    setValue("updatedAt", data.updatedAt);
    setValue("updatedBy", data.updatedBy);
    setValue("allTypes", data.allTypes);
    setValue("pwdSet", data.pwdSet);
    setValue("fakeUser", data.fakeUser);
    setValue("inviteSent", data.inviteSent);
    setValue("inviteSentAt", data.inviteSentAt);
    setValue("inviteSentBy", data.inviteSentBy);
    setValue("optedIn", data.optedIn);
    setValue("optedAt", data.optedAt);

    //console.log("Status edit "+ data.Status);

    const institutionData = data.institutionData || [];

    const institutionAddData = [];

    //console.log(institutionData);
    //return false;

    Object.keys(institutionData).forEach(key => {
        institutionAddData.push({
            id: institutionData[key]['id'],
            user_id: institutionData[key]['user_id'],
            institutionId: institutionData[key]['institutionId'],
            institutionLabel: institutionData[key]['institutionName'],
            source: institutionData[key]['source'],
            startDate: moment(institutionData[key]['startDate']).format("YYYY-MM-DD"),
            startDateLabel: moment(institutionData[key]['startDate']).toDate(),
            current: institutionData[key]['current'],
            position: institutionData[key]['position'],
            endDate: institutionData[key]['endDate'] ? moment(institutionData[key]['endDate']).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"),
            endDateLabel: institutionData[key]['endDate'] ? moment(institutionData[key]['endDate']).toDate() :  new Date(),
            positionId: institutionData[key]['positionId'],
            positionLabel: institutionData[key]['positionLabel'],
            institutionStatus: institutionData[key]['instStatus']

        });
    });



    if(!institutionAddData.length){
        institutionAddData.push(
            { id: "",
            user_id: "",
            institutionId: "",
            institutionLabel: "",
            source: "",
            startDate: "",
            startDateLabel: "",
            current: 0,
            position: 0,
            endDate: "",
            endDateLabel: "",
            positionId: "",
            positionLabel: "",
            institutionStatus: ""
         }
        );
    }

    //console.log('institutionAddData1');
    //console.log(institutionAddData);

    const showSave = ((data.GID)? true : false );
   
    const heading = "Edit user";
    return ( 
        <>
            <ModuleBreadcrumb links={[{ label:"Users", linkto: Routes.Users.path } , { label: heading } ]}  />
            <h4>{heading}</h4>            
            <Row>
                <Col xs={12}>                    
                    {isPending && <div className="alert alert-info" role="alert">Fetching data. Please wait..</div>}                    
                    {isError && <Alert variant="danger">{isError}</Alert>} 
                    { data.GID && <UserEditForm setValue={setValue} getValues={getValues} register={register} handleSubmit={handleSubmit} showSave={showSave} userRoleName={data.uRoleName} userRoleId={data.uRole} UType={data.UType} recStatus={data.Status} institutionAddData={institutionAddData} dblpId={data.dblpId} lockStatus={data.locked}/> }
                </Col>
            </Row>       
        
        </> 
    );
}
 
export default UserEdit;

