import { Col, Row, Button } from '@themesberg/react-bootstrap';
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
//import { Link, useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faEdit, faTrashAlt, faPlus, faFileImport } from '@fortawesome/free-solid-svg-icons';
import { faPlus, faFileImport } from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../../routes";
//import useFetch from "../../framework/hooks/useFetch";
//import { AgGridReact } from 'ag-grid-react';
//import { StatusInGrid } from "../../framework/utilities/utilities";
//import { useRef,useState } from 'react';
//import { useState } from 'react';
//import ConfirmDeleteModal from '../../project/components/ConfirmDeleteModal';
import RelationsRequestsAdminView from '../relationNewRequests/RelationsRequestsAdminView';

const RelationList = () => {

    //const history = useHistory();
    const heading = "Relations";
    //let [modalShow, setModalShow] = useState(false);
   // let [delRecId, setDelRecId] = useState(0);
   // let [delRecTitle, setDelRecTitle] = useState('');
   // const [gridApi, setGridApi] = useState(null);

    /*const deleteBtn=(params)=>{
        console.log(params);
        setDelRecId(params.value);
        setModalShow(true);
        setDelRecTitle("'"+params.data.AName + "' with '" + params.data.RelAName + "' and other co-authors as '"+ params.data.relationTypeName + "'");
    }


    const API_URL = process.env.REACT_APP_API_URL;
    let obj = useFetch(API_URL + '/v1/relation/data/list?Page_Limit=100&Page=1', 'POST');
    const rowData = obj.data.data;
    const gridRef = useRef(null);
       

    const editBtn=(params)=>{
        history.push(`${Routes.Relations.editpath.replace(':id',params.value)}`);
    }
    
    const columns =[
               
        {headerName:"Author",field:"AName",sortable:true,filter:true,flex: 1},    
        {headerName:"Related to",field:"RelAName",sortable:true,filter:true,flex: 1},    
        {headerName:"Type",field:"relationTypeName",sortable:true,filter:true,flex: 1}, 
        {headerName:"Paper type",field:"paperTypeName",sortable:true,filter:true,flex: 1}, 
        {headerName:"Publication",field:"publicationName",sortable:true,filter:true,flex: 1}, 
        {headerName:"Status",field:"Status",sortable:true,filter:true,flex: 2, minWidth:120,maxWidth:120,cellRenderer: params => {
            return StatusInGrid({value:params.value, custom_status:[ {value:'1',label: 'Validated', className:'bg-success'}, {value:'3',label: 'Pending', className:'bg-danger'} , {value:'4',label: 'Disputed', className:'bg-danger'} ]});                    
            } 
        },
        {headerName:"",field:"GID",flex: 2,minWidth:50,maxWidth:50,
        cellRendererFramework:(params)=><div>   
            <span onClick={()=>editBtn(params)} title="Edit" className="grid-action-btns text-success link"><FontAwesomeIcon icon={faEdit} className="me-2" /></span>                     
        </div>},
        {headerName:"",field:"GID",flex: 2,minWidth:50,maxWidth:50,
        cellRendererFramework:(params)=><div>               
            <span onClick={()=> { deleteBtn(params);}}title="Delete" className="grid-action-btns text-danger link"><FontAwesomeIcon icon={faTrashAlt} className="me-2" /></span>  
        </div>}      
    ];
    


    const handleModelClose = () => {
        setModalShow(false);
    }

    const handleOnDeleteSubmitted = () => {
        
        fetch(API_URL + '/v1/relation/data/delete/'+ delRecId, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
        }).then(response => response.json())
        .then(jsondata => {           
            handleModelClose(false);      
            const selectedData = gridApi.getSelectedRows();
            gridApi.applyTransaction({ remove: selectedData });
                
        }).catch((err) => {
            console.log("Login error: " + err);
        });

        
        
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
        
    };
*/
    return ( 
        <>
            {/*<ConfirmDeleteModal modalShow={modalShow} handleModelClose={() => { setModalShow(false);}} moduleName="relation" delRecTitle={delRecTitle} handleOnDeleteSubmitted={handleOnDeleteSubmitted} />*/}
            <ModuleBreadcrumb links={[{ label: heading }]}  />     
            <h4>{heading}</h4>
            <Row className="mb-4">
                <Col xs={12}>
                    <Button as={Link} variant="primary" className="animate-hover" to={Routes.Relations.addpath}>
                        <FontAwesomeIcon icon={faPlus} className="animate-left-3 me-3 ms-2" />
                        Add new relation
                    </Button>
                    <Button as={Link} className="animate-hover btn-info" to={Routes.Relations.importpath}>
                        <FontAwesomeIcon icon={faFileImport} className="animate-left-3 me-3 ms-2" />
                        Import relations
                    </Button> 

                </Col>
            </Row>
            <RelationsRequestsAdminView />
            {/*<Row className="mt-3">
                <Col xs={12} >
                    <div className="relations-page">
                        {obj.isError && <div className="alert alert-danger" role="alert">{obj.isError}</div>}
                        {obj.isPending && <div>Loading...</div>}
                        {obj.data &&
                            <>
                                <div>Listing latest 100 relations. To check for each user please goto the edit screen for each user.</div>
                                <div className="ag-theme-alpine mt-2" style={{ height: 600, width: '100%' }}>
                                    <AgGridReact
                                        className="user-table align-items-center"
                                        ref={gridRef}
                                        rowData={rowData}
                                        columnDefs={columns}
                                        pagination={true}
                                        onGridReady={onGridReady}
                                        rowSelection={'single'}
                                        >
                                    </AgGridReact>
                                </div >                                
                            </>
                        }
                    </div>
                </Col>
            </Row>*/}
        </> 
  );
}
 
export default RelationList;
