import React, {useState} from "react";
import { Col, Row, Form, Alert } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import { useParams, useHistory } from "react-router-dom";
import ModuleEditScreenButtons  from "../../project/components/ModuleEditScreenButtons";


const ConferenceForm = (props) => {
    const { id } = useParams();
    const setValue = props.setValue;
    const handleSubmit = props.handleSubmit;
    const register = props.register;
    const getValues = props.getValues;
    const [showSave,SetShowSave] = useState(props.showSave);
    const [startSave,setStartSave] = useState(false);
    const [successMessage,setSuccessMessage] = useState('');
    const [errMessage,setErrorMessage] = useState('');
    const [status, setStatus] = useState(props.recStatus);
    const API_URL = process.env.REACT_APP_API_URL;
    const history = useHistory();

    const ownerInfo = { 
        'createdBy' :  getValues('createdBy'),
        'createdAt' : getValues('createdAt'),
        'updatedBy' :  getValues('updatedBy'), 
        'updatedAt' : getValues('updatedAt') 
    }; 

    const onSubmit = postedData => {
        setStartSave(true);

        let formData = new FormData();
        if(id !== undefined){
            formData.append("GID", id);
        }
        formData.append("CName", postedData.CName);
        formData.append("Status", status);

        fetch(API_URL + '/v1/conference/data/save', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
            body: formData
        })
        .then(response => response.json())
        .then(jsondata => {
            //console.log("success");
            //console.log(jsondata);
            if ((jsondata.GID) && (jsondata.message === 'UPDATED')){
                setSuccessMessage("Record updated successfully.");
                setTimeout(function(){                    
                    history.push(Routes.Conferences.path);
                },1000); 
            }else if ((jsondata.GID) && (jsondata.message === 'CREATED')){
                SetShowSave(false);
                setSuccessMessage("Record created successfully.");
                setTimeout(function(){                    
                    history.push(Routes.Conferences.path);
                },1000); 
                              
            }else if (jsondata.errors){
                setErrorMessage(jsondata.message + "\r\n" + JSON.stringify(jsondata.errors));                                      
            }                   
            setStartSave(false); 
        }).catch((err) => {
            console.log("Login error: " + err);
            setErrorMessage("Record could not be saved.");
            setStartSave(false); 
        });
        
        
        
    }

    


    return ( 

    <>
        {successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}            
        {errMessage && <Alert variant="danger">{errMessage}</Alert>}

         <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col sm={8} className="mb-3">
                        <Form.Group id="CName">
                            <Form.Label className="required">Conference name</Form.Label>
                            <Form.Control required  {...register("CName")} name="CName"  type="text" placeholder=" "  className />     
                               
                        </Form.Group>
                    </Col>
                </Row> 
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label className="form-check-label required"  >
                                Status
                            </Form.Label>
                            <div className="form-check mt-0">
                                <input className="form-check-input" type="radio" name="status"  id="gridRadios1" value="1"
                                   checked={Number(status)===1?true:false}
                                   onChange={(e) => {setStatus(e.target.value);setValue("status",1);}}
                                   required    
        
                                ></input>
                                <Form.Label className="form-check-label"   >
                                    Active
                                </Form.Label>
                            </div>
                            <div className="form-check">
                            <input className="form-check-input" type="radio" name="status" id="gridRadios2" value="0" required 
                                checked={Number(status)===0?true:false}
                                onChange={(e) => {setStatus(e.target.value);setValue("status", 0)}}
     
                            ></input>
                                <Form.Label className="form-check-label" >
                                    Inactive
                                </Form.Label>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                {
                    (id !== undefined) ?
                        <ModuleEditScreenButtons cancelPath={Routes.Conferences.path} addnewPath={Routes.Conferences.addpath} showSave={showSave} startSave={startSave}  ownerInfo={ownerInfo} /> 
                    : 
                        <ModuleEditScreenButtons cancelPath={Routes.Conferences.path} showSave={showSave} startSave={startSave} /> 
                }                
                    
                        
                             
                </Form>


    </>

    


     );
}
 
export default ConferenceForm;