import RegistrationForm from './Form';
import { Col, Row,Alert } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import useFetch from "../../framework/hooks/useFetch";

const UserRoleEdit = () => {
    const heading = "New user registration";
    
    const API_URL = process.env.REACT_APP_API_URL;
    const { id } = useParams();
    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm();

    /* Fetching data and setting the values to the form elements */
    const { data, isPending, isError } = useFetch(API_URL + '/v1/user-registration/data/view/' + id, 'GET', true);


    setValue("GID", data.GID);
    setValue("FName", data.FName);
    setValue("SName", data.SName);
    setValue("Email", data.Email);
    setValue("dblp_url", data.dblp_url);
    setValue("pwd", data.dblp_url);
    setValue("createdAt", data.createdAt);
    setValue("pwd", data.pwd);
    setValue("hideCreateNew", data.hideCreateNew);



    const showSave = ((data.GID)? true : false );

    return ( 
        <>
            <ModuleBreadcrumb links={[{ label:"User registrations", linkto: Routes.userRegistration.path } , { label: heading } ]}  />
            <h4>{heading}</h4>          
            <Row>
                <Col xs={12}>
                    {isPending && <div className="alert alert-info" role="alert">Fetching data. Please wait..</div>}                    
                    {isError && <Alert variant="danger">{isError}</Alert>} 
                    {data.GID && <RegistrationForm setValue={setValue} getValues={getValues} register={register} handleSubmit={handleSubmit} errors={errors} recordStatus={data.Status} showSave={showSave} recStatus={data.Status} userMessage = {data.userMessage} adminCheck = {data.adminCheck}  />}
                </Col>
            </Row>       
        
        </> 
    );
}
 
export default UserRoleEdit;

