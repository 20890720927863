import { Button } from '@themesberg/react-bootstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import {  statusPill } from "./relations";
import ConfirmDeleteModal from '../../project/components/ConfirmDeleteModal';
import { useState } from "react";

const DynamicCoauthor = (props) => {
    
    const getValues = props.getValues;
    const setInputList = props.setInputList;
    const inputList = props.inputList;
    const userActionRowData = props.userActionRowData;

    let [modalShow, setModalShow] = useState(false);
    let [listIndex, setIndex] = useState('');
    let [delRecTitle, setDelRecTitle] = useState('');
    

    const handleCoAuthorChange = (author, index, vname) => {
        const list = [...inputList];
        if (author){
            list[index][vname] = author.value;
            list[index]['coauthor_id'] = author.value;
            list[index]['coauthor_name'] = author.label;
        }else{
            list[index][vname] = "";
            list[index]['coauthor_id'] = "";
            list[index]['coauthor_name'] = "";
        }
        setInputList(list);
        //console.log(inputList);
    }

    const deleteBtn=(e, params, index)=>{

        setModalShow(true);
        setDelRecTitle(params.coauthor_name);
        setIndex(index);
    }

     // handle click event of the Remove button
     const handleRemoveClick = (index) => {
        

        const list = [...inputList];
        list[listIndex]['status'] = 2;
        setInputList(list);
        setModalShow(false);

       /* const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);*/
    };

    const deleteRowBtn = (index) => {
    
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);

    };

   
    return (
        <>
            <ConfirmDeleteModal  modalShow={modalShow} handleModelClose={() => { setModalShow(false);}} moduleName="co author relation"  delRecTitle={delRecTitle}  handleOnDeleteSubmitted={handleRemoveClick} />

            {
            inputList.map((x, i) => {
                return (
                        
                        <tr className="mb-3"  key={i + "row"}>
                            <th scope="row">{i+2}</th>
                            <td><Select  
                                    name="CoAuthorDrp"
                                    key={`CoAuthordrop-${i}`} 
                                    options={getValues('usersDrp')}  
                                    value={{value: x.coauthor_name, label: x.coauthor_name}}
                                    closeOnScroll={true}
                                    onChange={author => { 
                                        if (author === null){
                                        }
                                        handleCoAuthorChange(author,i,'CoAuthorDrp');
                                    }}
                                    isDisabled={(x.status === 2) ? true : false } 
                                /></td>
                                <td valign="middle" align="center">
                                {((x.status !== 1) && userActionRowData && userActionRowData[x.coauthor_id] && (userActionRowData[x.coauthor_id] === "ACCEPTED")) ?
                                    <span className="badge rounded-pill bg-success">Accepted</span>
                                    :
                                    <>
                                      {((x.status !== 1) && userActionRowData && userActionRowData[x.coauthor_id] && (userActionRowData[x.coauthor_id] === "REJECTED")) ?
                                        <span className="badge rounded-pill bg-danger">Rejected</span>
                                        :
                                        <>
                                            { (x.status === 4) ?
                                                <span className="badge rounded-pill bg-danger">Pending</span>
                                                :
                                                statusPill(x.status)
                                            }
                                        </>
                                        
                                        }
                                    </>
                                    
                                }
                               
                            </td>

                            <td align="center"> 
                                { (x.relationId !== x.relationParentId) &&  (x.relationId !== '' ) &&
                                    <>
                                    {/** we have commented this because we now let the admin make the changes */}
                                    <Button className="animate-hover btn btn-sm btn-delete btn-small-rounded" name="status" onClick={e => deleteBtn(e, x, i)} ><FontAwesomeIcon icon={faTrashAlt} className="animate-left-3 me-3 ms-2" />Remove</Button>
                                    </>
                                }  
                                { (x.relationId === '' ) &&
                                    <Button className="animate-hover btn btn-sm btn-delete btn-small-rounded" name="status" onClick={e => deleteRowBtn(i)} ><FontAwesomeIcon icon={faTrashAlt} className="animate-left-3 me-3 ms-2" />Remove</Button>
                                }                                 
                            </td>
                        </tr>
                        
                    
                );
               
            })}
            
        </>
    );
}

export default DynamicCoauthor;