import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faKey} from "@fortawesome/free-solid-svg-icons";
import { faUserCircle} from "@fortawesome/free-regular-svg-icons";
import { Nav, Image, Navbar, Dropdown, Container } from '@themesberg/react-bootstrap';
import Logout from "../../framework/utilities/Logout";
import Profile3 from "../../assets/img/team/default.png";
import { Routes } from "../../routes";
import { useHistory } from 'react-router-dom';

let sessionUser = JSON.parse(localStorage.getItem("profile"));

const NavbarComponent = (props) => {
    
    const API_URL = process.env.REACT_APP_API_URL;

    const history = useHistory();
    const handleRoute = () =>{
        history.push(Routes.PasswordReset.path);
    }
    const handleProfileEdit = () => {

        fetch(API_URL + '/v1/user/data/get_admin' , {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            }        
        })
        .then(response => response.json())
        .then(jsondata => {      
            if (jsondata){
                const url = Routes.Users.editpath.replace(':id',jsondata);
                history.push(url);
            }           
        }).catch((err) => {
            console.log("Login error: " + err);
           
        });

    }

    return (
        <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
            <Container fluid className="px-0">
                <div className="d-flex justify-content-between w-100">
                    <div className="d-flex align-items-center"></div>
                    <Nav className="align-items-center">

                        <Dropdown as={Nav.Item}>
                            <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                                <div className="media d-flex align-items-center">
                                    <Image src={Profile3} className="user-avatar md-avatar rounded-circle" />
                                    <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                                    {sessionUser && <span className="mb-0 font-small fw-bold">{ sessionUser.FName } { sessionUser.SName }</span>}
                                    </div>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                                <Dropdown.Item className="fw-bold" onClick={handleProfileEdit}>
                                    <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
                                </Dropdown.Item>
                                
                                <Dropdown.Divider />

                                <Dropdown.Item className="fw-bold" onClick={handleRoute}>
                                    <FontAwesomeIcon icon={faKey} className="me-2" /> Password Reset
                                </Dropdown.Item>

                                <Dropdown.Divider />

                                <Dropdown.Item className="fw-bold" onClick={Logout}>
                                    <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                </div>
            </Container>
        </Navbar>
    );
};

export default NavbarComponent;