
export const Routes = {
    // pages
    /*Presentation: { path: "/" },
    DashboardOverview: { path: "/dashboard/overview" },*/
    DashboardOverview: { path: "/" },
    Transactions: { path: "/transactions" },
    Settings: { path: "/settings" },
    Upgrade: { path: "/upgrade" },
    BootstrapTables: { path: "/tables/bootstrap-tables" },
    DemoUsers: {
        path: "/demo-users",
        addpath: '/add-demo-user',
        editpath: '/edit-demo-user/:id',
    },
    
    Billing: { path: "/billing" },
    Invoice: { path: "/invoice" },
    Signin: { path: "/sign-in" },
    Signup: { path: "/sign-up" },
    GuestForgotPassword: { path: "/forgot-password" },
    GuestResetPassword: {
         path: "/reset-password/:token",
         },
    Lock: { path: "/lock" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },

    // docs
    DocsOverview: { path: "/documentation/overview" },
    DocsDownload: { path: "/documentation/download" },
    DocsQuickStart: { path: "/documentation/quick-start" },
    DocsLicense: { path: "/documentation/license" },
    DocsFolderStructure: { path: "/documentation/folder-structure" },
    DocsBuild: { path: "/documentation/build-tools" },
    DocsChangelog: { path: "/documentation/changelog" },

    // components
    Accordions: { path: "/components/accordions" },
    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tables: { path: "/components/tables" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets" },

    // users
    Users:{
        path: "/users/list",
        addpath: '/users/add',
        editpath: '/users/edit/:id',
        importpath: '/users/import'
    },
    //conferences
    Conferences:{
        path: "/conferences/list",
        addpath:'/conferences/add',
        editpath:'/conferences/edit/:id',
    },


    // Institution
    Institution:{
        path: "/institution/list",
        addpath: '/institution/add',
        editpath: '/institution/edit/:id',
    },

    // Relations
    Relations:{
        path: "/relation/list",
        addpath: '/relation/add',
        editpath: '/relation/edit/:id',
        importpath: '/relation/import',
        pendingRelationEdit:"/pending-relations/modify/:id",
    },


    // Relation Type
    RelationType: {
        path: "/settings/relation-type/list",
        addpath: '/settings/relation-type/add',
        editpath: '/settings/relation-type/edit/:id',
    },
    
    EmailTemp: {
        path: "/emailtemp/list",
        addpath: '/emailtemp/add',
        editpath: '/emailtemp/edit/:id',
    },

    UserRole: {
        path: "/settings/user-role/list",
        addpath: '/settings/user-role/add',
        editpath: '/settings/user-role/edit/:id',
    },

    CommunityPapers: {
        path: "/community-papers/list",        
    },

    ConflictExport: {
        path: "/exports/conflict-export/list",        
    },

    // logged in user rest pass feature
    PasswordReset:{
        path: "/user/password-reset",
    },
    
    userRegistration:{
        path: "/users/new-registrations",
        editpath: '/users/new-registrations/edit/:id',
    },

    userInviteSend:{
        path: "/users/send-invites",       
    }

};