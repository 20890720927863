import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { Col, Row,  Alert } from '@themesberg/react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import useFetch from "../../framework/hooks/useFetch";
import { statusPill } from "./relations";
import RelationAddForm from './Form';

const RelationPendingEdit = () => {
    
    const heading = "Modify relation";
    const { id } = useParams();

    const { register, handleSubmit, setValue, getValues ,formState: { errors } } = useForm();

    /* Fetching data and setting the values to the form elements */
    const API_URL = process.env.REACT_APP_API_URL;
    const { data, isPending, isError } = useFetch(API_URL + '/v1/relation/data/pending_employment_view/' + id, 'GET', true);

   //console.log(data.Status);

    // set the values fetched from database
    setValue("GID", data.GID);
    setValue("UId", data.UId);
    setValue("rUserId", data.rUserId);   
    setValue("rUserName", data.coAuthorName);   
    setValue("relationType", data.relationType);
    setValue("source", data.source);
    setValue("notes", data.notes);
    setValue("paperType", data.paperType);
    setValue("dateStartYear", data.dateStartYear);
    setValue("dateStartMonth", data.dateStartMonth);
    setValue("dateEndYear", data.dateEndYear);
    setValue("dateEndMonth", data.dateEndMonth);
    setValue("startDateMand",0);
    setValue("endDateMand",0);
    setValue("startDateLabel","");
    setValue("endDateLabel","");
    setValue("Status", data.Status);
    setValue("allowModify", ((data.allowModify === 1) ? true : false));
    setValue("sourceUserIsLoggedInUser",data.sourceUserIsLoggedInUser);
    //setValue("relationTypes", data.relationTypes);
    setValue("publicationId", data.publicationId);   
    setValue("publicationName", data.publicationName);
    setValue("usersDrp", data.usersDrp);
    setValue("coAuthId", '');
    setValue("coAuthLabel", '');
    setValue("relUserIsLoggedInUser", data.relUserIsLoggedInUser);
    setValue("modificationsComment","");
    setValue("rejectionComment","");
    setValue("coAuthorStatus",data.coauthor_status);
    setValue("institutionDrp",data.institutionDrop);
    setValue("rInstitutionId", data.institutionId);
    setValue("rInstitutionName", data.institutionName);
    setValue("authorName", data.authorName);

    //const coauthorData = data.coAuthors || [];

  

    const coauthorAddData = [];

   
    // relation types dropdown setup
    let relationDrpTypes = [];
    let relationTypeName = '';
    if (data.relationTypes){
        Object.keys(data.relationTypes).forEach(key => {
            if (Number(data.relationType) === data.relationTypes[key].id){
                relationTypeName = data.relationTypes[key].type_name;
            }
            relationDrpTypes.push({
                value: data.relationTypes[key].id,
                label: data.relationTypes[key].type_name
            });
        });
    }
    


    let pubDrp = [];
    if (data.allPublications){
        pubDrp = data.allPublications;
    }
   
    let paperTypeDrp = [];
   
    if (data.paperTypes){
        Object.keys(data.paperTypes).forEach(key => {
            if (Number(key) === data.paperType){
                setValue("paperTypeName",data.paperTypes[key]);
            }
            paperTypeDrp.push({
                value: key,
                label: data.paperTypes[key]
            });
        });
    }
    setValue("paperTypes", paperTypeDrp);
    setValue("relationTypes", data.relationTypes);
    setValue("relationDrpTypes", relationDrpTypes);
    setValue("relationTypeName", relationTypeName);
    setValue("allPublications", pubDrp);

    //let showSave = ((data.GID && (data.Status !== 3))? true : false );
    const showSave = true;

    return (
        <>
            <ModuleBreadcrumb links={[
                { label: heading } ]}  />
            <Row className="mt-4">
                <Col lg={9} className="mb-3">
                    <h4>Relation - {relationTypeName}</h4>
                    {statusPill(data.Status)}
                </Col>
                
                
            </Row>
            {isPending && <div className="alert alert-info" role="alert">Fetching data. Please wait..</div>}                    
            {isError && <Alert variant="danger">{isError}</Alert>} 
            {data.GID && 
                <RelationAddForm id={id} setValue={setValue} getValues={getValues} register={register} handleSubmit={handleSubmit} errors={errors}  showSave={showSave} coauthorData={coauthorAddData}  recStatus={data.Status} />
            }
        </>
    );
}
export default RelationPendingEdit;