import ConferenceForm from './Form';
import { Col, Row,Alert } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import useFetch from "../../framework/hooks/useFetch";

const ConferenceEdit = () => {
    const heading = "Edit conference";
    
    const API_URL = process.env.REACT_APP_API_URL;
    const { id } = useParams();
    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm();

    /* Fetching data and setting the values to the form elements */
    const { data, isPending, isError } = useFetch(API_URL + '/v1/conference/data/view/' + id, 'GET', true);

    setValue("GID", data.GID);
    setValue("CName", data.CName);
    setValue("Status", data.Status);
    setValue("createdBy", data.createdBy);
    setValue("createdAt", data.createdAt);
    setValue("updatedBy", data.updatedBy);
    setValue("updatedAt", data.updatedAt);

    const showSave = ((data.GID)? true : false );

    return ( 
        <>
            <ModuleBreadcrumb links={[{ label:"Conference", linkto: Routes.Conferences.path } , { label: heading } ]}  />
            <h4>{heading}</h4>        
            <Row className="mt-4">
                <Col xs={12}>
                    {isPending && <div className="alert alert-info" role="alert">Fetching data. Please wait..</div>}                    
                    {isError && <Alert variant="danger">{isError}</Alert>} 
                    {data.GID && <ConferenceForm id={data.GID} setValue={setValue} getValues={getValues} register={register} handleSubmit={handleSubmit} errors={errors} recordStatus={data.Status} showSave={showSave} recStatus={data.Status} />}
                </Col>
            </Row>
        </> 
    );
}
 
export default ConferenceEdit;