
export async function fetch_API(URL, method, formData) {
    const response = await fetch(URL, {
        method: method,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem("token"),
        },
        body: formData
    });

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    const fetchResponse = await response.json();
    return fetchResponse;
}


/*
export async function fetch_API(URL, method, formData) {
    let res;
    let responseData = {};
    let isPending = true;
    let isError = false;
    res = await fetch(URL, {
        method: method,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem("token"),
        },
        body: formData
    }).then(response => response.json())
        .then(jsondata => {
            console.log("success");
            console.log(jsondata);
            responseData = jsondata;
            isPending = false;
        }).catch((err) => {
            console.log("Login error: " + err);
            isError = err;
        });

    return { res, responseData, isPending, isError }
}*/