import { useState, useEffect  } from "react";
import { Col, Row, Form, Card, Button } from '@themesberg/react-bootstrap';
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import CreatableSelect from 'react-select/creatable';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const DynamicInstitutions = (props) => {
    
   
    
    
    const inputList = props.inputList;
    const setInputList = props.setInputList;
    const [isLoadingInstitutionOptions, setIsLoadingInstitutionOptions] = useState(true); 
    const [selectInstitutionOptions, setSelectInstitutionOptions] = useState([]); 

    useEffect(() => {
        const tmp = [];
        const API_URL = process.env.REACT_APP_API_URL;
        fetch(API_URL + '/v1/institution/data/list/InstitutionDrplist', {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            }
        }).then(response => response.json())
        .then(jsondata => {
    
            //console.log("response",jsondata);
                Object.keys(jsondata).forEach(key => {
                    tmp.push({
                        value: key,
                        label: jsondata[key]
                    });
                });
                setIsLoadingInstitutionOptions(false);   
                setSelectInstitutionOptions(tmp);
            }).catch((err) => {
                console.log("Dropdown fetching error: " + err);
        });
    },[]);
    

    const institutionData = props.institutionData;
   
    useEffect(() => {

        setInputList(institutionData);

      }, [institutionData, setInputList]);
      

      //console.log(inputList.length);
   /* const selectPositionTypeOptions = [
        { value: 1, label: 'Full time' },
        { value: 2, label: 'Consultant' },
        { value: 3, label: 'Intern' }
        
    ];*/

    // handle input change
   /* const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };*/

    const handleCheckChange = (e, index) => {
        const name = e.target.name;
        const list = [...inputList];
        list[index][name] = (e.target.checked)? 1: 0;
        setInputList(list);
    };

    const handlePositionChange = (e, index) => {
        const name = e.target.name;
        const list = [...inputList];
        list[index][name] = (e.target.checked)? 1: 0;
        setInputList(list);
    };

    // handle input change
    const handleInstitutionChange = (e, index,vname) => {
       // console.log("index,vname",index,vname, e.value, e.label);
        const list = [...inputList];
        list[index][vname] = e.value;
        list[index]['institutionLabel'] = e.label;
        setInputList(list);        
    };

    /*const handlePositionChange = (e, index,vname) => {
       // console.log("index,vname",index,vname, e.value, e.label);
        const list = [...inputList];
        list[index][vname] = e.value;
        list[index]['positionLabel'] = e.label;
        setInputList(list);        
    };*/


    // handle date change
    const handleDateChange = (date, index,vname) => {
        //if (!date) return;
        const list = [...inputList];
        // alert(date);
        //alert(moment(date).format("YYYY-MM-DD"))
        if (date){
            list[index][vname] =  moment(date).format("YYYY-MM-DD");
            if (vname === "endDate") list[index]['endDateLabel'] = new Date(moment(date).format("YYYY"), (moment(date).format("MM")-1    ), moment(date).format("DD"));
            if (vname === "startDate") list[index]['startDateLabel'] = new Date(moment(date).format("YYYY"), (moment(date).format("MM")-1), moment(date).format("DD"));
            
        }else{
            list[index][vname] = "";
            if (vname === "endDate") list[index]['endDateLabel'] = "";
            if (vname === "startDate") list[index]['startDateLabel'] = "";
        }       
        setInputList(list);
        
    };

    


    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
            if(inputList.length >= 50)
            {
                alert('Max 50 affiliation can be added.');
                return false;
            }
            else
            {
                setInputList([...inputList, { institutionId:"", institutionLabel: "",positionId:"", positionLabel: "", instOtherName: "", startDate: ''  , startDateLabel: "", formatedStartDt:  new Date() , endDate: '' , endDateLabel: "", formatedEndDt:  new Date() , current: 0, position: 0 } ]); 
            }
            
           
    };

    //console.log(inputList);
    
    return (
        <>
            {
            inputList.map((x, i) => {
                return (
                    
                        <div className="mb-3" key={i + "row"} >
                            <Card>
                                <Card.Body>
                                { x.institutionStatus === 3 &&  
                                <Row>
                                    <Col sm={12} className="mb-3">
                                        <p className="text-danger">This institution you linked is not in our approved list and hence is sent for approval.</p>
                                    </Col>
                                </Row>
                                }
                                <Row className="mb-2 mt-2">
                                    <Col lg={6} sm={12} className="mb-3">
                                        
                                        <Form.Label className="d-sm-block required" >Institution</Form.Label>
                                        <CreatableSelect 
                                            name="institutionId" 
                                            options={selectInstitutionOptions}  
                                            key={i + "select"}                                 
                                            value={{value: x.institutionId, label: x.institutionLabel}}
                                            onChange={e => {
                                                handleInstitutionChange(e,i,'institutionId');
                                                }
                                            }  
                                            isLoading={isLoadingInstitutionOptions}   
                                        />
                                        
                                    </Col>
                                    
                                    <Col lg={2} sm={12} className="mb-3">
                                        <Form.Label >&nbsp;</Form.Label>
                                         <Form.Group id="inst">
                                            <div className="form-check">
                                                <Form.Label className="form-check-label" htmlFor="same-address">Intern</Form.Label>
                                                <input type="checkbox" name="position" key={i + '_position_check'} className="form-check-input" value={x.position} checked={x.position ? 1: 0} onChange={e => handlePositionChange(e, i)} />
                                            </div>
                                        </Form.Group>                                        
                                    </Col>

                                    <Col lg={2} sm={12} className="mb-3">   
                                        <Form.Label>&nbsp;</Form.Label>                         
                                        <Form.Group >
                                            <div className="form-check mt-2">
                                                <Form.Label className="form-check-label" htmlFor="same-address">Current affiliation</Form.Label>
                                                <input type="checkbox" name="current" key={i + '_current_check'} className="form-check-input" value={x.current} checked={x.current ? 1: 0} onChange={e => handleCheckChange(e, i)} />
                                            </div>
                                        </Form.Group>
                                    </Col>  

                                
                                </Row>
                                <Row>
                                    <Col md={3} sm={12} className="mb-3">
                                    <Form.Label className="required">Start date</Form.Label>
                                            <Form.Group id="startDate">
                                            <DatePicker
                                                className="form-control"
                                                name="startDate"
                                                key={i + "date"}
                                                isClearable
                                                closeOnScroll={true}
                                                placeholderText="mm/yyyy"
                                                selected={x.startDateLabel}
                                                dateFormat="MM/yyyy"
                                                showMonthYearPicker   
                                                autoComplete="off"
                                                onChange={date => {
                                                    handleDateChange(date,i,'startDate');                                        
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    
                                    <Col md={4} sm={12} className="mb-3">
                                    { (Number(x.current) !== 1  ) &&
                                        <>
                                        <Form.Label className="required">End date</Form.Label>
                                            <Form.Group id="endDate">
                                                <DatePicker
                                                    className="form-control"
                                                    name="endDate"
                                                    isClearable
                                                    closeOnScroll={true}
                                                    placeholderText="mm/yyyy"
                                                    selected={x.endDateLabel }
                                                    dateFormat="MM/yyyy"
                                                    autoComplete="off"
                                                    showMonthYearPicker   
                                                    onChange={date => {
                                                        handleDateChange(date,i,'endDate');                                        
                                                    }}
                                                />
                                            </Form.Group>
                                        </>
                                        }
                                    </Col>
                                </Row>    

                                <Row>   
                                    <Col md={8} sm={12} className="mb-3 mt-4">
                                        { (inputList.length - 1) === i && 
                                            <Button className="animate-hover btn btn-sm btn-addnew" onClick={()=>handleAddClick()}  >
                                                <FontAwesomeIcon icon={faPlus} className="animate-left-3 me-3 ms-2" />
                                                Add another affiliation
                                            </Button>
                                            
                                        }
                                        {inputList.length !== 1 && 
                                            <Button onClick={()=>handleRemoveClick(i)}  className="animate-hover btn btn-sm btn-delete" ><FontAwesomeIcon icon={faTrashAlt} className="animate-left-3 me-3 ms-2" />Remove affiliation</Button>
                                        }
                                    </Col>
                                </Row>  
                                </Card.Body>
                            </Card>
                        </div>
                    
                );
            })}
           
        </>
    );
}

export default DynamicInstitutions;