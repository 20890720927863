import { useState, useEffect } from "react";
import { Col, Row, Button } from '@themesberg/react-bootstrap';
import { StatusInGrid } from "../../framework/utilities/utilities";
import { AgGridReact } from 'ag-grid-react';
import { useHistory } from 'react-router-dom';
import { Routes } from "../../routes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit,faEyeSlash, faEye, faArrowLeft  } from '@fortawesome/free-solid-svg-icons';

const RelationsRequestsAdminView = (props) => {

       
    const history = useHistory();
    
    const [gridStatus, changeGridStatus] = useState(0);                 //3=Pending, 4=Admin Responded	
    const [rowData, setRowData] = useState(0);
    const [titleMessage, setTitleMessage] = useState('');
    const [commentsTotal, setCommentsTotal] = useState(0);
    const [hiddenCommentsTotal, setHiddenCommentsTotal] = useState(0);

    const [error, setError] = useState('');

    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(()=>{
        const API_URL = process.env.REACT_APP_API_URL;
        let formData = new FormData();
        formData.append("gridStatus", gridStatus); 
        if (gridStatus !== 4){
            setTitleMessage('Comments');
        }
        fetch(API_URL + '/v1/relation_modify_request_list', { 
            method: 'POST', 
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),                    
            },
            body: formData
        })
        .then(response => response.json())
        .then(jsondata => {  
            //rowData = jsondata.data;
            setRowData(jsondata.data);  
            
            if (jsondata.total_records > 0) setCommentsTotal(jsondata.total_records);     
            if (jsondata.total_records_hidden > 0) setHiddenCommentsTotal(jsondata.total_records_hidden);
        }).catch((err) => {  
            setError("Error fetching data.");              
            console.log("Login error: " + err);
        });

    },[gridStatus]); 

    
    
    
    const editBtn=(params)=>{
        if (params.data.relationTypeId === 8){
            history.push(`${Routes.Relations.pendingRelationEdit.replace(':id',params.value)}`);
        }else{
            history.push(`${Routes.Relations.editpath.replace(':id',params.value)}`);
        }
        
    }

    const updateStatus=()=>{
        if (gridStatus === 0)
            changeGridStatus(1);
        else
            changeGridStatus(0);
        setTitleMessage('Hidden comments');
    }

    const hideComment = (params) => {
        
        if (params && params.data && params.data.GID){
            // we make a request to hide the comment
            let formData = new FormData();
            formData.append("GID", params.data.GID); 
           
            if(gridStatus)
            {
                formData.append("Status", 3); 
            }
            else
            {
                formData.append("Status", 4); 
            }

            fetch(API_URL + '/v1/relation_modify_request_update_status', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("token"),
                },
                body: formData
            })
            .then(response => response.json())
            .then(jsondata => {
                if ((jsondata.GID) && (jsondata.message === 'UPDATED')){
                    window.location.reload();
                }
            }).catch((err) => {
                console.log("Login error: " + err);                       
            });
        }
        
    }

    const columns =[               
        {headerName:"Relation type",field:"relationType",sortable:true,filter:true,flex: 1},            
        {headerName:"Relation Comment",field:"Comments",sortable:true,filter:true,flex: 1},            
        {headerName:"Submitted by",field:"submittedBy",sortable:true,filter:true,flex: 1},            
        {headerName:"Submitted on",field:"createdAt",sortable:true,filter:true,flex: 1},            
        {headerName:"Status",field:"Status",sortable:true,filter:true,flex: 2, minWidth:120,maxWidth:120,cellRenderer: params => {
            return StatusInGrid({value:params.value, custom_status:[ {value:'3',label: 'Pending', className:'bg-danger'}, { value:'4',label: 'Hidden', className:'bg-secondary' } ]});                    
            } 
        },
        {headerName:"",field:"RelationId",flex: 2,minWidth:50,maxWidth:50,
        cellRendererFramework:(params)=><div>   
            <span onClick={()=>editBtn(params)} title="Edit" className="grid-action-btns text-success link"><FontAwesomeIcon icon={faEdit} className="me-2" /></span>                     
        </div>},  
        {headerName:"",field:"RelationId",flex: 2,minWidth:50,maxWidth:50,
        cellRendererFramework:(params)=><div> 
            {  
                (params.data.Status === 3) ?
                <span onClick={()=>hideComment(params)} title="Click to hide comment" className="grid-action-btns text-secondary link"><FontAwesomeIcon icon={faEye} className="me-2" /></span>                               
                :
                <span onClick={()=>hideComment(params)} title="Click to unhide comment" className="grid-action-btns text-muted link"><FontAwesomeIcon icon={faEyeSlash} className="me-2" /></span>
            }
        </div>},  

    ];

    const gridHeight = (props.gridHeight) ? props.gridHeight : "60vh";
    return (
        <>
            {(hiddenCommentsTotal > 0) && 
            <Row className="mt-4">
                <Col>
                    {!(gridStatus) ?            
                        <Button type="button" className="animate-hover btn btn-sm" onClick={updateStatus}>
                            <FontAwesomeIcon icon={faEyeSlash} className="me-2" />
                            Show hidden comments
                        </Button>               
                        :
                        <Button type="button" className="animate-hover btn btn-sm btn-secondary" onClick={updateStatus}>
                            <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                            Back
                        </Button>
                    }
                </Col>
            </Row>
            }

            { (commentsTotal > 0) &&
                <>
                <h5 className="mt-3">{ titleMessage }</h5>
                <Row className="mt-3">
                    <Col xs={12} >
                        <div className="relations-page">
                            {(error !== '') && <div className="alert alert-danger" role="alert">{error}</div>}
                            { (error === '') && (!rowData)  && <div>Loading...</div>} 

                            { rowData &&
                                <>
                                    <div className="ag-theme-alpine mt-2" style={{ height: gridHeight, width: '100%' }}>
                                        <AgGridReact
                                            className="user-table align-items-center"
                                            rowData={rowData}
                                            columnDefs={columns}
                                            pagination={true}
                                            rowSelection={'single'}
                                            >
                                        </AgGridReact>
                                    </div >                           
                                </>
                            }
                        </div>
                    </Col>
                </Row>
                </>
                
                
            }

            { ( (commentsTotal === 0) && (hiddenCommentsTotal === 0) ) && <p className="mt-2">No new relation notifications.</p>}
            
        </>
    )
}

export default RelationsRequestsAdminView;