import RelationEditForm from './Form';
import { Col, Row,Alert  } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import useFetch from "../../framework/hooks/useFetch";
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';

const RelationEdit = () => {

   const heading = "Edit relation";

   const API_URL = process.env.REACT_APP_API_URL;
   const { id } = useParams();
   const { register, handleSubmit, setValue, getValues ,formState: { errors } } = useForm();

   /* Fetching data and setting the values to the form elements */
   const { data, isPending, isError } = useFetch(API_URL + '/v1/relation/data/view/' + id, 'GET', true);

   // set the values fetched from database
   setValue("GID", data.GID);
   setValue("UId", data.UId);
   setValue("rUserId", data.rUserId);   
   setValue("relationType", data.relationType);
   setValue("source", data.source);
   setValue("notes", data.notes);
   setValue("paperType", data.paperType);
   setValue("dateStartYear", data.dateStartYear);
   setValue("dateStartMonth", data.dateStartMonth);
   setValue("dateEndYear", data.dateEndYear);
   setValue("dateEndMonth", data.dateEndMonth);
   setValue("Status", data.Status);
   setValue("paperTypes", data.paperTypes);
   setValue("relationTypes", data.relationTypes);
   setValue("createdBy", data.createdBy);
   setValue("createdAt", data.createdAt);
   setValue("updatedBy", data.updatedBy);
   setValue("updatedAt", data.updatedAt);
   setValue("publicationId", data.publicationId);   
   setValue("publicationName", data.publicationName);
   setValue("oldPublicationName", data.publicationName);
   setValue("allPublications", data.allPublications);
   setValue("usersDrp", data.usersDrp);
   setValue("authorName", data.authorName);
   setValue("relAuthorName", data.relAuthorName);
   setValue("startDateMand",0);
   setValue("endDateMand",0);
   setValue("startDateLabel","");
   setValue("endDateLabel","");
   setValue("publicationDBLPUrl",data.publicationDBLPUrl);
   setValue("DBLPImportedEntry",data.DBLPImportedEntry);
   setValue("communityPaperId", data.communityPaper);
   
   //console.log(data);
   
   const showSave = ((data.GID)? true : false );
   
   const coauthorData = data.coAuthors || [];
   const coauthorAddData = [];

   Object.keys(coauthorData).forEach(key => {
      coauthorAddData.push({
            relationId: coauthorData[key]['relation_id'],
            relationParentId: coauthorData[key]['relation_parent_id'],
            coauthor_id: coauthorData[key]['coauthor_id'],
            coauthor_name: coauthorData[key]['coauthor_name'],
            alow_modify: coauthorData[key]['allowModify'],
            status: coauthorData[key]['status']
      });
   });


   return ( 
            <>
            <ModuleBreadcrumb links={[{ label:"Relations", linkto: Routes.Relations.path } , { label: heading } ]}  />
            <h4>{heading}</h4>   
            <Row>
               <Col xs={12}>
               {isPending && <div className="alert alert-info" role="alert">Fetching data. Please wait..</div>}                    
               {isError && <Alert variant="danger">{isError}</Alert>} 
               {data.GID && <RelationEditForm setValue={setValue} getValues={getValues} register={register} handleSubmit={handleSubmit} errors={errors} recordStatus={data.Status} showSave={showSave} recStatus={data.Status} coauthorData={coauthorAddData} />}
               </Col>
            </Row>
            </>
     );
}
 
export default RelationEdit;