
import React from "react";
import { Card, Col, Row , Button } from '@themesberg/react-bootstrap';
import RelationsRequestsAdminView from '../relationNewRequests/RelationsRequestsAdminView';
import useFetch from "../../framework/hooks/useFetch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Routes } from "../../routes";

let sessionUser = JSON.parse(localStorage.getItem("profile"));

const DashboardOverview = () => {

    
    const API_URL = process.env.REACT_APP_API_URL;    
    let obj = useFetch(API_URL + '/v1/institution/data/list?Page_Limit=10000&Page=1&OnlyInvalidInst=1', 'POST');
    const pendingInstrowData = obj.data;

    
    let objNewReg = useFetch(API_URL + '/v1/user-registration/data/list?Page_Limit=&Page=1', 'POST');
    const userNewReg = objNewReg.data;
    
    return (
        <>
            <Row className="justify-content-start  py-4">
                <Col xs={12} sm={6} xl={4} className="mb-4">
                    {sessionUser && <h2>Hi, { sessionUser.FName }</h2>}
                </Col>
            </Row>
            <Card className="mb-3">                
                <Card.Body><RelationsRequestsAdminView gridHeight={"30vh"} /></Card.Body>
            </Card>
            
            {pendingInstrowData && (pendingInstrowData.total_records > 0) && <>
                <Card className="mb-3">
                    <Card.Header><h5>Pending institutions</h5></Card.Header>
                    <Card.Body> There are {pendingInstrowData.total_records} new Institutions added. Please review them.<br/>
                        <Button as={Link} className="animate-hover btn-info mt-3" to={Routes.Institution.path}>
                            <FontAwesomeIcon icon={faUniversity} className="animate-left-3 me-3 ms-2" />
                            View institutions
                        </Button> 
                    </Card.Body>
                </Card>               
            </>}

            {userNewReg && (userNewReg.total_records > 0) && <>
                <Card className="mb-3">
                    <Card.Header><h5>New registrations</h5></Card.Header>
                    <Card.Body> There are {userNewReg.total_records} new user registrations received. Please review them.<br/>
                        <Button as={Link} className="animate-hover btn-info mt-3" to={Routes.userRegistration.path}>
                            <FontAwesomeIcon icon={faUserPlus} className="animate-left-3 me-3 ms-2" />
                            View new registrations
                        </Button> 
                    </Card.Body>
                </Card>
               
            </>}
        </>
    );
};

export default DashboardOverview;